import { useCallback, useEffect, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useCaptchaValidation, useNavigation } from 'hooks';
import { useLabels } from 'config/translation';
import {
  Container,
  Title,
  Alert,
  SuccessModal,
  SubTitle,
  Paragraph,
  Button,
  RegistrationForm,
  RegistrationRequest,
} from 'components';
import { ContentType } from 'shared';
import { developersPortalClient, storageClient } from 'http-clients';
import { FormValidationException } from 'common/exceptions';

const Registration: React.FC = () => {
  const { getLabel } = useLabels();
  const { navigate, goBack } = useNavigation();
  const signup = useMutation(developersPortalClient.signup);
  const fileUploader = useMutation(storageClient.upload);
  const captchaValidation = useCaptchaValidation();

  useEffect(() => {
    if (signup.isError) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [signup.isError]);

  const handleSubmit = useCallback(
    async (request: RegistrationRequest) => {
      const { captchaCode } = await captchaValidation.validate();

      const { uploadUrl } = await signup.mutateAsync({
        name: request.name!,
        email: request.email!,
        password: request.password!,
        phone: request.phone!,
        uplandUsername: request.uplandUsername,
        bio: request.bio,
        profileUrl: request.profileUrl,
        profileFileContentLength: request.image?.size,
        captchaCode,
      });

      fileUploader.mutateAsync({
        uploadUrl,
        file: request.image!,
        contentType: ContentType.IMAGE_PNG,
      });
    },
    [captchaValidation, fileUploader, signup]
  );

  const mustShowError = useMemo(
    () =>
      !!signup.isError && !(signup.error instanceof FormValidationException),
    [signup.isError, signup.error]
  );

  return (
    <>
      <Container size="md">
        <Title>{getLabel('registration.title')}</Title>

        {mustShowError && (
          <Alert type="danger">{`${(signup.error as Error).message}`}</Alert>
        )}

        <RegistrationForm onSubmit={handleSubmit} onCancel={goBack} />
      </Container>

      <SuccessModal isVisible={signup.isSuccess}>
        <SubTitle>{getLabel('registration.successModal.title')}</SubTitle>
        <Paragraph>{getLabel('registration.successModal.content')}</Paragraph>
        {fileUploader.isError === true && (
          <Alert type="danger">{getLabel('upload.errorSendingFile')}</Alert>
        )}
        <Button
          label={getLabel('general.continue')}
          variant="primary"
          onClick={() => navigate('login')}
          wAuto={true}
        />
      </SuccessModal>
    </>
  );
};

export { Registration };
