import { useCallback, useState } from 'react';

import {
  Button,
  Modal,
  ResetPasswordForm,
  ResetPasswordFormRequest,
  SubTitle,
  SuccessModal,
  VerificationCodeForm,
  VerificationCodeFormRequest,
  RequestResetPasswordModal,
} from 'components';
import { useLabels } from 'config';
import { useMutation } from 'react-query';
import { DevelopersPortal, developersPortalClient } from 'http-clients';
import { HttpStatus } from 'shared';

export type ResetPasswordModalProps = {
  isVisible: boolean;
  onCancel: () => void;
};

export enum ResetPasswordSteps {
  REQUEST_RESET_PASSWORD = 0,
  VERIFY_CODE = 1,
  SEND_NEW_PASSWORD = 2,
  COMPLETED = 3,
}

export type StateParams = {
  token?: string;
  email?: string;
  verificationCode?: string;
};

export const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  isVisible,
  onCancel,
}) => {
  const [step, setStep] = useState(ResetPasswordSteps.REQUEST_RESET_PASSWORD);
  const [state, setState] = useState<StateParams>({});
  const { getLabel } = useLabels();

  const resendPasswordReset = useMutation(
    developersPortalClient.resendResetPassword
  );
  const checkPasswordResetVcp = useMutation(
    developersPortalClient.checkVcpResetPassword
  );
  const resetPassword = useMutation(developersPortalClient.resetPassword);

  const nextStep = useCallback(() => setStep(step + 1), [step]);

  const handleCancelButton = useCallback(() => {
    onCancel();
    setState({});
    setStep(ResetPasswordSteps.REQUEST_RESET_PASSWORD);
    resendPasswordReset.reset();
    checkPasswordResetVcp.reset();
    resetPassword.reset();
  }, [checkPasswordResetVcp, onCancel, resendPasswordReset, resetPassword]);

  const handleRequestResetPwdSubmit = useCallback(
    (token: string, email: string) => {
      setState({ token, email });
      nextStep();
    },
    [nextStep]
  );

  const handleResendResetPwdSubmit = useCallback(
    () => resendPasswordReset.mutateAsync(state.token!),
    [resendPasswordReset, state]
  );

  const handleCheckResetPwdVcpSubmit = useCallback(
    async (props: VerificationCodeFormRequest) => {
      try {
        await checkPasswordResetVcp.mutateAsync({
          token: state.token!,
          verificationCode: props.verificationCode!,
        });

        nextStep();
      } catch (error: unknown) {
        const isInvalidCode =
          error instanceof DevelopersPortal.ServerError &&
          (error as DevelopersPortal.ServerError).statusCode ===
            HttpStatus.FORBIDDEN;

        if (isInvalidCode) {
          throw error;
        }
      }

      setState({ ...state, verificationCode: props.verificationCode });
    },
    [checkPasswordResetVcp, state, nextStep]
  );

  const handleResetPassword = useCallback(
    async (values: ResetPasswordFormRequest) => {
      await resetPassword.mutateAsync({
        newPassword: values.newPassword!,
        verificationCode: state.verificationCode!,
        token: state.token!,
      });

      nextStep();
    },
    [resetPassword, state, nextStep]
  );

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <RequestResetPasswordModal
        isVisible={step === ResetPasswordSteps.REQUEST_RESET_PASSWORD}
        onSubmit={handleRequestResetPwdSubmit}
        onCancel={handleCancelButton}
      />

      <Modal size="sm" isVisible={step === ResetPasswordSteps.VERIFY_CODE}>
        <SubTitle>{getLabel('forgotPassword.resetPassword')}</SubTitle>
        <VerificationCodeForm
          email={state.email!}
          onResend={handleResendResetPwdSubmit}
          onSubmit={handleCheckResetPwdVcpSubmit}
          onCancel={handleCancelButton}
        />
      </Modal>

      <Modal
        size="sm"
        isVisible={step === ResetPasswordSteps.SEND_NEW_PASSWORD}
      >
        <ResetPasswordForm
          onSubmit={handleResetPassword}
          onCancel={handleCancelButton}
        />
      </Modal>

      <SuccessModal isVisible={step === ResetPasswordSteps.COMPLETED}>
        <SubTitle>{getLabel('forgotPassword.successModal')}</SubTitle>
        <Button
          label={getLabel('general.continue')}
          variant="primary"
          onClick={handleCancelButton}
          wAuto={true}
        />
      </SuccessModal>
    </>
  );
};
