import { Trans } from 'react-i18next';
import { useState, useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { useLabels } from 'config';
import { DevelopersPortal } from 'http-clients';
import { VerificationCodeFormRequest } from './VerificationCodeForm.dto';
import {
  Button,
  InputVerificationCode,
  ResendVerificationCodeButton,
} from 'components';
import { useVerificationCodeFormSchema } from './VerificationCodeForm.validation';
import { FormButtonActions, FormRow } from '../Common.style';
import {
  InfoWrapper,
  Description,
  ContactUsContainer,
  ContactUsLink,
} from './VerificationCodeForm.style';
import { LINK_CONTACT } from 'config/constants';

export type VerificationCodeFormProps = {
  email: string;
  onResend: () => Promise<DevelopersPortal.GenerateVerificationCodeResponse>;
  onSubmit: (values: VerificationCodeFormRequest) => Promise<void>;
  onCancel: () => void;
};

export const VerificationCodeForm: React.FC<VerificationCodeFormProps> = ({
  onResend,
  onSubmit,
  onCancel,
  ...props
}) => {
  const { getLabel } = useLabels();
  const [isResendLoading, setResendLoading] = useState(false);
  const [isResendDisabled, setResendDisabled] = useState(false);
  const schemaValidation = useVerificationCodeFormSchema();

  const handleSubmit = async (
    request: VerificationCodeFormRequest,
    formikHelpers: FormikHelpers<VerificationCodeFormRequest>
  ) => {
    try {
      formikHelpers.setSubmitting(true);

      await onSubmit(request);
    } catch (error) {
      if (error instanceof DevelopersPortal.ServerError) {
        const devError: DevelopersPortal.ServerError = error;
        formikHelpers.setFieldError('verificationCode', devError.message);
      }
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const handleResendButton = useCallback(async () => {
    try {
      setResendLoading(true);

      const result = await onResend();

      if (!!result.availableAfter) {
        const timeInMiliseconds = result.availableAfter * 1000;
        setResendDisabled(true);
        setTimeout(() => setResendDisabled(false), timeInMiliseconds);
      }
    } finally {
      setResendLoading(false);
    }
  }, [onResend]);

  return (
    <Formik
      initialValues={{}}
      isInitialValid={false}
      validationSchema={schemaValidation}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <InfoWrapper>
            <Description>
              <Trans
                i18nKey="verificationCodeForm.description"
                values={{ email: props.email }}
                components={[<b />]}
              />
            </Description>
            <div>
              <ResendVerificationCodeButton
                label={getLabel(
                  isResendDisabled
                    ? 'verificationCodeForm.btnSent'
                    : 'verificationCodeForm.btnResend'
                )}
                isLoading={isResendLoading}
                disabled={isResendDisabled}
                onClick={handleResendButton}
              />
            </div>
          </InfoWrapper>
          <FormRow size={50} mb={2}>
            <InputVerificationCode name="verificationCode" />
          </FormRow>
          <FormButtonActions>
            <Button
              disabled={!isValid}
              loading={isSubmitting}
              type="submit"
              label={getLabel('verificationCodeForm.btnVerifyCode')}
            />
            <Button
              variant="secondary"
              type="button"
              label={getLabel('general.cancel')}
              onClick={onCancel}
            />
          </FormButtonActions>

          <ContactUsContainer>
            <Trans
              i18nKey="verificationCodeForm.contactUs"
              components={[
                <b />,
                <br />,
                <ContactUsLink
                  href={LINK_CONTACT}
                  target="_blank"
                  rel="noreferrer"
                />,
              ]}
            />
          </ContactUsContainer>
        </Form>
      )}
    </Formik>
  );
};
