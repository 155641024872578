import { useField } from 'formik';
import PhoneInput from 'react-phone-number-input';

import { CleanedInput, FakeInput } from './PhoneNumber.style';
import { Wrapper, StyledErrorMessage } from '../Common.style';

import 'react-phone-number-input/style.css';

export type PhoneNumberProps = {
  name: string;
  placeholder?: string;
};

export const PhoneNumber: React.FC<PhoneNumberProps> = ({ ...props }) => {
  const [, meta, helper] = useField(props.name);

  const hasError = !!meta.error && !!meta.touched;

  return (
    <Wrapper error={hasError}>
      <FakeInput error={hasError}>
        <PhoneInput
          value={meta.value}
          onChange={value => {
            helper.setValue(value);
            helper.setTouched(true);
          }}
          onBlur={() => {
            helper.setTouched(true);
          }}
          inputComponent={CleanedInput}
          placeholder={props.placeholder}
        />
      </FakeInput>
      {!!hasError && <StyledErrorMessage>{meta.error}</StyledErrorMessage>}
    </Wrapper>
  );
};
