import { AppNotFoundContainer } from './AppList.style';
import { SubTitle } from 'components';
import { useLabels } from 'config';
import { useAuthentication } from 'hooks';

import miles from 'assets/images/miles.png';

export const AppNotFound: React.FC = () => {
  const { getLabel } = useLabels();
  const { isAbleToCreateApps } = useAuthentication();

  return (
    <AppNotFoundContainer>
      <img width={300} src={miles} alt={getLabel('apps.notFoundMessage')} />
      <SubTitle>
        {getLabel(
          isAbleToCreateApps
            ? 'apps.notFoundMessage'
            : 'apps.userIsNotAbleToCreateApps'
        )}
      </SubTitle>
    </AppNotFoundContainer>
  );
};
