import styled from 'styled-components';

import { StyledInput } from '../Common.style';

export const StyledInputVerificationCode = styled(StyledInput)`
  font-size: 1.5rem;
  letter-spacing: 1rem;
  font-weight: ${props => props.theme.typography.fontWeight.bold};
  height: 50px;
  width: 240px;
  text-transform: uppercase;
`;
