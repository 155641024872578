import styled, { DefaultTheme } from 'styled-components';

import { Variant } from 'shared';
import { ButtonSize } from './Button';

type StyledButtonProps = {
  variant: Variant;
  size: ButtonSize;
  width?: string;
  disabled?: boolean;
  uppercase?: boolean;
  wAuto?: boolean;
};

const getBackgroundColor = (
  theme: DefaultTheme,
  { variant, disabled }: StyledButtonProps
) => {
  if (variant === 'link') {
    return 'transparent';
  }

  if (variant === 'primary') {
    return !disabled
      ? theme.palette.primary.main
      : theme.palette.common.gray.veryLight;
  }

  if (variant === 'light') {
    return !disabled
      ? theme.palette.common.gray.midGray
      : theme.palette.common.gray.standard;
  }

  return !disabled
    ? theme.palette.secondary.main
    : theme.palette.common.gray.veryLight;
};

const getFontColor = (
  theme: DefaultTheme,
  { variant, disabled }: StyledButtonProps
) => {
  if (variant === 'link') {
    return !disabled
      ? theme.palette.primary.main
      : `${theme.palette.primary.main}90`;
  }

  if (variant === 'primary') {
    return !disabled
      ? theme.palette.primary.contrastText
      : theme.palette.common.gray.midGray;
  }

  if (variant === 'light') {
    return !disabled
      ? theme.palette.common.black
      : theme.palette.common.gray.standard;
  }

  return !disabled
    ? theme.palette.secondary.contrastText
    : theme.palette.common.gray.midGray;
};

const buttonStyles = {
  padding: {
    sm: '0 20px',
    md: '0 12px',
  },
  fontSize: {
    sm: '0.725rem',
    md: '0.9rem',
  },
  height: {
    sm: '38px',
    md: '48px',
  },
};

export const StyledButton = styled.button<StyledButtonProps>`
  background-color: ${props => getBackgroundColor(props.theme, props)};
  color: ${props => getFontColor(props.theme, props)};
  font-family: ${props => props.theme.typography.fontFamily};
  font-weight: ${props => props.theme.typography.fontWeight.bold};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'capitalize')};
  border: 0;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  letter-spacing: ${props => (props.variant === 'link' ? '.5px' : '1.5px')};
  height: ${props => buttonStyles.height[props.size]};
  font-size: ${props => buttonStyles.fontSize[props.size]};
  border-radius: 36px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: ${props => buttonStyles.padding[props.size]};
  text-align: center;
  width: ${props => props.width};

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    width: ${props => (props.wAuto ? 'auto' : props.width)};
    min-width: ${props => (props.wAuto ? '250px' : 'auto')};
  }
`;
