import styled from 'styled-components';

export const CallToActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    flex-direction: row;
    grid-gap: 2rem;
  }
`;
