import { useMemo } from 'react';

import {
  Link,
  Menu,
  MenuItem,
  NavBar,
  MobileMenuButton,
  Wrapper,
} from './Header.style';
import { useLabels } from 'config';
import {
  LINK_ABOUT_US,
  LINK_API_DOCS,
  LINK_FIRST_STEPS,
} from 'config/constants';
import { Container, DropdownButton } from 'components';
import { AvatarButton } from './AvatarButton';
import { useAuthentication, useNavigation, useVisibilityControl } from 'hooks';

import { UplandBrandImage } from 'assets/images';
import { ChangePasswordModal } from 'components/Modal/ChangePasswordModal';
import { ShowSandboxCredentialsModal } from 'components/Modal/ShowSandboxCredentialsModal';

export const Header: React.FC = () => {
  const { visible, ref, toggle } =
    useVisibilityControl<HTMLUListElement>(false);
  const changePasswordModalControl = useVisibilityControl(false);
  const sandboxCredentialsModalControl = useVisibilityControl(false);
  const { getLabel } = useLabels();
  const { authUser, logout, isAbleToSeeSandboxCredentials } =
    useAuthentication();
  const { navigate } = useNavigation();

  const menuItems = [
    { id: 'about-us', label: getLabel('menu.aboutUs'), link: LINK_ABOUT_US },
    {
      id: 'first-steps',
      label: getLabel('menu.firstSteps'),
      link: LINK_FIRST_STEPS,
    },
    { id: 'api-docs', label: getLabel('menu.apiDocs'), link: LINK_API_DOCS },
  ];

  const subMenuItems = useMemo(() => {
    const items = [
      {
        label: getLabel('menu.editProfile'),
        onClick: () => navigate('edit-profile'),
        visible: true,
      },
      {
        label: getLabel('menu.changePassword'),
        onClick: changePasswordModalControl.toggle,
        visible: true,
      },
      {
        label: getLabel('menu.openSandboxCredentialsModal'),
        onClick: sandboxCredentialsModalControl.toggle,
        visible: isAbleToSeeSandboxCredentials,
      },
      { label: getLabel('menu.logout'), onClick: logout, visible: true },
    ];

    return items.filter(({ visible }) => visible);
  }, [
    changePasswordModalControl,
    sandboxCredentialsModalControl,
    isAbleToSeeSandboxCredentials,
    getLabel,
    logout,
    navigate,
  ]);

  return (
    <>
      <NavBar>
        <Container size="lg">
          <Wrapper>
            <a href="/">
              <UplandBrandImage width={225} height={49} />
            </a>

            <Menu opened={visible} ref={ref}>
              {menuItems.map(item => (
                <MenuItem key={item.id}>
                  <Link href={item.link} target="_blank" rel="noreferrer">
                    {item.label}
                  </Link>
                </MenuItem>
              ))}

              {!!authUser && (
                <DropdownButton
                  options={subMenuItems}
                  buttonComponent={(onClick: () => void) => (
                    <AvatarButton
                      username={authUser.name}
                      profilePicture={authUser.profilePictureUrl}
                      onClick={onClick}
                    />
                  )}
                />
              )}
            </Menu>

            <MobileMenuButton type="button" opened={visible} onClick={toggle} />
          </Wrapper>
        </Container>
      </NavBar>

      <ChangePasswordModal
        isVisible={changePasswordModalControl.visible}
        onCancel={changePasswordModalControl.toggle}
      />

      <ShowSandboxCredentialsModal
        isVisible={sandboxCredentialsModalControl.visible}
        onClose={sandboxCredentialsModalControl.toggle}
      />
    </>
  );
};
