import styled from 'styled-components';

export const StyledModal = styled.div`
  position: relative;
  height: 500px;
  max-height: calc(50vh * 0.8);
  z-index: 1;
  width: 100%;
`;

export const BottomBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

export const StyledInput = styled.input.attrs({ type: 'file' })`
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
`;

export const StyledUploadButton = styled.label<{ error?: boolean }>`
  background-color: ${props => props.theme.palette.common.gray.veryLight};
  color: ${props => props.theme.palette.common.gray.standard};
  border-radius: 20px;
  width: 182px;
  height: 182px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: ${props => props.theme.typography.fontWeight.semiBold};
  font-size: 0.825rem;
  overflow: hidden;
  position: relative;

  > img {
    width: 100%;
  }

  background-color: ${props =>
    !!props.error
      ? props.theme.palette.common.red.light
      : props.theme.palette.common.gray.veryLight};

  &:hover {
    box-shadow: 0 0 0 2px ${props => props.theme.palette.primary.main};
  }
`;

export const RemoveImageButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0.85rem;
  border: none;
  padding: 0.5rem;
  background: ${props => props.theme.palette.common.black}99;
  color: ${props => props.theme.palette.common.white};
  text-align: center;
  cursor: pointer;
`;

export const RotateImageButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${props => props.theme.palette.common.black}99;
  color: ${props => props.theme.palette.common.white};
  border: none;
  border-radius: 40%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CircleIcon = styled.span`
  background-color: ${props => props.theme.palette.common.gray.standard};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;
