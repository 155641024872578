import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigation } from 'hooks';
import { Maybe, RegistrationStatus } from 'shared';
import { developersPortalClient } from 'http-clients/developers-portal';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  SHOW_SANDBOX_CREDENTIALS_MODAL,
} from 'config/constants';
import { AuthContextType, AuthenticatedUser } from './useAuthentication.types';
import { useQueryClient } from 'react-query';

export const getAccessToken = () =>
  sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

export const resetAcessToken = () =>
  sessionStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);

const AuthContext = React.createContext<AuthContextType>({
  authUser: null,
  isLogged: false,
  isAbleToCreateApps: false,
  isAbleToSeeSandboxCredentials: false,
  login: async () => {},
  logout: () => {},
  reloadAuthUserData: () => {},
});

export const AuthenticatedUserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { navigate } = useNavigation();
  const [authUser, setAuthUser] = useState<Maybe<AuthenticatedUser>>();
  const queryClient = useQueryClient();

  const login = async (accessToken: string) => {
    sessionStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);

    const authUser = await developersPortalClient.getAuthenticatedUser();
    setAuthUser(authUser);
  };

  const reloadAuthUserData = async () =>
    setAuthUser(await developersPortalClient.getAuthenticatedUser());

  const logout = () => {
    resetAcessToken();
    setAuthUser(null);
    navigate('login');
    queryClient.clear();
  };

  useEffect(() => {
    const accessToken = getAccessToken();

    if (!!accessToken) {
      login(accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAbleToCreateApps = useMemo(
    () =>
      !!authUser &&
      [
        RegistrationStatus.ACTIVE,
        RegistrationStatus.UPLAND_ACCOUNT_CONFIRMED,
      ].includes(authUser?.status),
    [authUser]
  );

  const isAbleToSeeSandboxCredentials = useMemo(
    () =>
      SHOW_SANDBOX_CREDENTIALS_MODAL &&
      !!authUser &&
      [
        RegistrationStatus.AWAITING_UPLAND_ACCOUNT_CONFIRMATION,
        RegistrationStatus.UPLAND_ACCOUNT_CONFIRMED,
        RegistrationStatus.ACTIVE,
      ].includes(authUser?.status),
    [authUser]
  );

  const isLogged = useMemo(
    () => !!getAccessToken(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authUser]
  );

  return (
    <AuthContext.Provider
      value={{
        authUser,
        isLogged,
        isAbleToCreateApps,
        isAbleToSeeSandboxCredentials,
        login,
        logout,
        reloadAuthUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthentication = () => useContext(AuthContext);
