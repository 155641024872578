import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { useUniqueId } from 'hooks';

import { TooltipIconContainer, TooltipWrapper } from './TooltipIcon.style';

export interface TooltipIconProps {
  hint: string;
}

export const TooltipIcon = ({ hint }: TooltipIconProps) => {
  const tooltipId = useUniqueId('tooltip');

  return (
    <TooltipIconContainer data-tooltip-id={tooltipId}>
      <FontAwesomeIcon icon={faInfoCircle} />
      <Tooltip
        closeOnEsc
        closeOnResize
        closeOnScroll
        content={hint}
        id={tooltipId}
        wrapper={TooltipWrapper}
      />
    </TooltipIconContainer>
  );
};
