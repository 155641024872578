import styled, { DefaultTheme } from 'styled-components';

import { AlertType } from './Alert';

export type StyledAlertProps = {
  type: AlertType;
};

const getBackgroundColor = (
  theme: DefaultTheme,
  { type }: StyledAlertProps
) => {
  if (type === 'danger') return theme.palette.common.red.light;
  if (type === 'success') return theme.palette.common.green.light;
  if (type === 'warning') return theme.palette.common.yellow.light;
};

const getFontColor = (theme: DefaultTheme, { type }: StyledAlertProps) => {
  if (type === 'danger') return theme.palette.common.red.default;
  if (type === 'success') return theme.palette.common.green.default;
  if (type === 'warning') return theme.palette.common.yellow.default;
};

export const StyledAlert = styled.div<StyledAlertProps>`
  background-color: ${props => getBackgroundColor(props.theme, props)};
  color: ${props => getFontColor(props.theme, props)};
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  border: 1px solid ${props => getFontColor(props.theme, props)}20;
`;
