import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { StyledDatePicker } from './DateRangePicker.style';

export type DateRangePickerParams = {
  startDate: Date;
  endDate: Date;
};

export type TempDateRange = {
  startDate: Date | null;
  endDate: Date | null;
};

export type DateRangePickerProps = {
  name: string;
};

type DateRange = [Date | null, Date | null];

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ name }) => {
  const [props, , helper] = useField<DateRangePickerParams>(name);

  const [tempDate, setTempDate] = useState<TempDateRange>();

  useEffect(() => {
    if (!tempDate && props.value) {
      setTempDate({
        startDate: props.value.startDate,
        endDate: props.value.endDate,
      });
    }
  }, [tempDate, props.value]);

  return (
    <StyledDatePicker
      className="date-range-picker"
      selectsRange={true}
      startDate={tempDate?.startDate}
      endDate={tempDate?.endDate}
      onCalendarClose={() => {
        if (!tempDate?.startDate || !tempDate?.endDate) {
          setTempDate(props.value);
        }
      }}
      onChange={updatedDates => {
        const [startDate, endDate] = updatedDates as DateRange;
        setTempDate({ startDate, endDate });
        if (startDate && endDate) {
          helper.setValue({ startDate, endDate });
        }
      }}
      withPortal
    />
  );
};
