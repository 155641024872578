import { ReactNode } from 'react';

import { StyledAlert } from './Alert.style';

export type AlertType = 'danger' | 'success' | 'warning';

export type AlertProps = {
  children: ReactNode;
  type: AlertType;
};

export const Alert: React.FC<AlertProps> = ({ children, type }) => (
  <StyledAlert type={type}>{children}</StyledAlert>
);
