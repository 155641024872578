import * as dateFns from 'date-fns';

export class DateBuilder {
  private date: Date;

  constructor(date: Date | string = new Date()) {
    this.date = new Date(date);
  }

  public toString(format = 'yyyy-MM-dd') {
    return dateFns.format(this.date, format);
  }

  public addMonths(value: number) {
    this.date = dateFns.addMonths(this.date, value);

    return this;
  }

  public addDays(value: number) {
    this.date = dateFns.addDays(this.date, value);

    return this;
  }

  public toDate() {
    return this.date;
  }
}

export const formatDate = (date: Date, format: string) =>
  dateFns.format(date, format);
