import { object, SchemaOf } from 'yup';
import { useLabels } from 'config';
import { password, samePassword } from 'shared/yup-extensions';
import { ChangePasswordFormRequest } from './ChangePasswordForm.dto';

export const useChangePasswordFormRequestSchema = () => {
  const { getLabel } = useLabels();

  const changePasswordFormRequestSchema: SchemaOf<ChangePasswordFormRequest> =
    object({
      currentPassword: password().label(
        getLabel('changePasswordModal.formLabels.currentPassword')
      ),
      newPassword: password().label(
        getLabel('changePasswordModal.formLabels.newPassword')
      ),
      passwordConfirmation: samePassword(
        'newPassword',
        getLabel('general.passwordShouldBeTheSame')
      ).label(getLabel('changePasswordModal.formLabels.passwordConfirmation')),
    });

  return changePasswordFormRequestSchema;
};
