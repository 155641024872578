import { Button, Container } from 'components';
import { useLabels } from 'config';
import { useNavigation } from 'hooks';
import { Oops, OopsParagraph, Wrapper } from './NotFound.style';

export const NotFound: React.FC = () => {
  const { getLabel } = useLabels();
  const { navigate } = useNavigation();

  return (
    <Container size="md">
      <Wrapper>
        <Oops>Oops...</Oops>

        <OopsParagraph>{getLabel('notFound.title')}</OopsParagraph>

        <Button
          onClick={() => navigate('login')}
          label={getLabel('general.login')}
          wAuto={true}
        />
      </Wrapper>
    </Container>
  );
};
