import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  padding: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    padding-bottom: 6rem;
  }
`;

export const JsonViewer = styled.div`
  font-size: 0.8rem;

  word-break: break-all;
`;
