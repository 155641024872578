import React, {
  createContext,
  ReactNode,
  useContext,
  useCallback,
  useState,
} from 'react';
import { useMutation } from 'react-query';

import {
  Button,
  Modal,
  Paragraph,
  SubTitle,
  SuccessModal,
  VerificationCodeForm,
  VerificationCodeFormRequest,
  UplandUsernameForm,
  UplandUsernameFormOnSubmitProps,
} from 'components';
import {
  UplandAccountVerificationSteps,
  UplandAccountVerificationType,
} from './useUplandAccountVerification.types';
import { developersPortalClient } from 'http-clients';
import { useLabels } from 'config';

const UplandAccountVerificationContext =
  createContext<UplandAccountVerificationType>({
    startVerificationFlow: () => {},
    verified: false,
  });

export const UplandAccountVerificationProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { getLabel } = useLabels();
  const [tempUplandUsername, setTempUplandUsername] = useState('');
  const [verified, setAsVerified] = useState(false);
  const [step, setStep] = useState(UplandAccountVerificationSteps.HIDDEN);

  const nextStep = useCallback(() => setStep(step + 1), [step]);

  const startVerificationFlow = useCallback((uplandUsername: string) => {
    setStep(UplandAccountVerificationSteps.VERIFY_USERNAME);
    setTempUplandUsername(uplandUsername);
  }, []);

  const cancelVerificationFlow = useCallback(() => {
    setStep(UplandAccountVerificationSteps.HIDDEN);
    setTempUplandUsername('');
  }, []);

  const completeFlow = useCallback(() => {
    setAsVerified(true);
    setStep(UplandAccountVerificationSteps.HIDDEN);
  }, []);

  const verifyUplandUsername = useMutation(
    developersPortalClient.verifyUplandUsername
  );
  const verifyCode = useMutation(
    developersPortalClient.verifyCodeToConfirmUplandUsername
  );

  const handleUplandUsernameFormSubmit = useCallback(
    async ({ values }: UplandUsernameFormOnSubmitProps) => {
      const response = await verifyUplandUsername.mutateAsync({
        uplandUsername: values.uplandUsername!,
      });

      setTempUplandUsername(values.uplandUsername!);
      nextStep();

      return response;
    },
    [nextStep, verifyUplandUsername]
  );

  const handleResendGenerateCodeSubmit = useCallback(
    () =>
      verifyUplandUsername.mutateAsync({
        uplandUsername: tempUplandUsername,
      }),
    [tempUplandUsername, verifyUplandUsername]
  );

  const handleConfirmationFormSubmit = useCallback(
    async (props: VerificationCodeFormRequest) => {
      const response = await verifyCode.mutateAsync({
        verificationCode: props.verificationCode!,
      });

      nextStep();

      return response;
    },
    [nextStep, verifyCode]
  );

  return (
    <UplandAccountVerificationContext.Provider
      value={{
        startVerificationFlow,
        verified,
      }}
    >
      {children}

      <Modal
        size="sm"
        isVisible={step === UplandAccountVerificationSteps.VERIFY_USERNAME}
      >
        <UplandUsernameForm
          initialValues={{ uplandUsername: tempUplandUsername }}
          onSubmit={handleUplandUsernameFormSubmit}
          onCancel={cancelVerificationFlow}
        />
      </Modal>

      <Modal
        size="sm"
        isVisible={step === UplandAccountVerificationSteps.VERIFY_CODE}
      >
        <VerificationCodeForm
          email={getLabel('validateUplandAccountFlow.uplandAccountDescription')}
          onResend={handleResendGenerateCodeSubmit}
          onSubmit={handleConfirmationFormSubmit}
          onCancel={cancelVerificationFlow}
        />
      </Modal>

      <SuccessModal
        isVisible={step === UplandAccountVerificationSteps.VERIFIED}
      >
        <SubTitle>
          {getLabel('validateUplandAccountFlow.successModal.title')}
        </SubTitle>
        <Paragraph>
          {getLabel('validateUplandAccountFlow.successModal.content')}
        </Paragraph>

        <Button
          label={getLabel('general.continue')}
          variant="primary"
          onClick={completeFlow}
          wAuto={true}
        />
      </SuccessModal>
    </UplandAccountVerificationContext.Provider>
  );
};

export const useUplandAccountVerification = () =>
  useContext(UplandAccountVerificationContext);
