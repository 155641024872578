import styled from 'styled-components';

export const StyledAppIdentityBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 1rem;
`;

export const StyledAppName = styled.div`
  font-weight: ${props => props.theme.typography.fontWeight.bold};
  color: ${props => props.theme.palette.common.gray.standard};
`;
