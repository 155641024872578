import { useAuthentication } from 'hooks';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import {
  Login,
  Registration,
  Dashboard,
  CreateNewApp,
  ValidateAccount,
  EditProfile,
  EditApp,
} from 'scenes';
import { AppWebhooksHistory } from 'scenes/AppWebhooksHistory/AppWebhooksHistory';

export type RouteName =
  | 'login'
  | 'signup'
  | 'dashboard'
  | 'create-new-app'
  | 'validate-account'
  | 'edit-profile'
  | 'edit-app'
  | 'webhook-calls-history';

type Route = {
  name: RouteName;
  path: string;
  scene: React.ReactNode;
  isPrivate: boolean;
};

export const routes: Route[] = [
  {
    name: 'login',
    path: '/',
    scene: <Login />,
    isPrivate: false,
  },
  {
    name: 'signup',
    path: '/signup',
    scene: <Registration />,
    isPrivate: false,
  },
  {
    name: 'validate-account',
    path: '/validate-account/:token',
    scene: <ValidateAccount />,
    isPrivate: false,
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    scene: <Dashboard />,
    isPrivate: true,
  },
  {
    name: 'create-new-app',
    path: '/create-new-app',
    scene: <CreateNewApp />,
    isPrivate: true,
  },
  {
    name: 'edit-profile',
    path: '/edit-profile',
    scene: <EditProfile />,
    isPrivate: true,
  },
  {
    name: 'edit-app',
    path: '/apps/:id',
    scene: <EditApp />,
    isPrivate: true,
  },
  {
    name: 'webhook-calls-history',
    path: '/apps/:appId/webhook-calls-history',
    scene: <AppWebhooksHistory />,
    isPrivate: true,
  },
];

export type PrivateRouteProps = {
  isPrivate: boolean;
  children: ReactNode;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  isPrivate,
  children,
}) => {
  const { isLogged } = useAuthentication();

  if (isPrivate && !isLogged) return <Navigate to="/" replace />;
  if (!isPrivate && isLogged) return <Navigate to="/dashboard" replace />;

  return <>{children}</>;
};
