import styled from 'styled-components';

export const SliderWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const SliderLabel = styled.label`
  margin-bottom: 1rem;
  display: block;
`;
