import { StyledResendVerificationCodeButton } from './ResendVerificationCodeButton.style';

import loadingIcon from 'assets/images/loading.svg';

export type ResendVerificationCodeButtonProps = {
  label: string;
  disabled: boolean;
  isLoading: boolean;
  onClick: () => void;
};

export const ResendVerificationCodeButton: React.FC<
  ResendVerificationCodeButtonProps
> = ({ label, disabled, isLoading, onClick }) => (
  <StyledResendVerificationCodeButton
    type="button"
    disabled={isLoading || disabled}
    onClick={onClick}
  >
    {!!isLoading ? <img width="10" src={loadingIcon} alt="Loading" /> : label}
  </StyledResendVerificationCodeButton>
);
