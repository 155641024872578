import { useMutation } from 'react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Container,
  Title,
  LoginForm,
  LoginRequest,
  VerificationCodeForm,
  VerificationCodeFormRequest,
} from 'components';
import { useLabels } from 'config';
import {
  useAuthentication,
  useCaptchaValidation,
  useNavigation,
  useRememberLoginCredentials,
  useVisibilityControl,
} from 'hooks';
import { DevelopersPortal, developersPortalClient } from 'http-clients';
import { HttpStatus, Maybe } from 'shared';
import { ResendRegistrationConfirmationModal } from './ResendRegistrationConfirmationModal';
import { ResetPasswordModal } from './ResetPasswordModal';

export const Login: React.FC = () => {
  const { getLabel } = useLabels();
  const navigation = useNavigation();
  const validateCredentials = useMutation(developersPortalClient.login);
  const validateVerificationCode = useMutation(
    developersPortalClient.validateVerificationCode
  );
  const resendVerificationCode = useMutation(
    developersPortalClient.resendVerificationCode
  );
  const authentication = useAuthentication();
  const { initialValues, saveCredentials } = useRememberLoginCredentials();
  const [tempUser, setTempUser] = useState<
    Maybe<{
      email: string;
      shortTimeToken: string;
    }>
  >();
  const resetPasswordModalControl = useVisibilityControl(false);
  const captchaValidation = useCaptchaValidation();

  const resetTempUser = useCallback(() => setTempUser(null), []);

  useEffect(() => {
    [resendVerificationCode.error, validateVerificationCode.error]
      .map(error => error as DevelopersPortal.ServerError)
      .filter(error => error && error.statusCode === HttpStatus.UNAUTHORIZED)
      .forEach(resetTempUser);
  }, [
    navigation,
    resendVerificationCode.error,
    resetTempUser,
    validateVerificationCode.error,
  ]);

  const handleLoginFormSubmit = useCallback(
    async (request: LoginRequest) => {
      try {
        const { captchaCode } = await captchaValidation.validate();

        if (!!request.rememberMe) saveCredentials(request);

        const { token, is2FAEnabled } = await validateCredentials.mutateAsync({
          email: request.email!,
          password: request.password!,
          captchaCode,
        });

        if (is2FAEnabled) {
          setTempUser({
            email: request.email!,
            shortTimeToken: token,
          });
        } else {
          await authentication.login(token);
          navigation.navigate('dashboard');
        }
      } catch (_) {}
    },
    [
      authentication,
      captchaValidation,
      navigation,
      saveCredentials,
      validateCredentials,
    ]
  );

  const handleTwoFAFormSubmit = useCallback(
    async (request: VerificationCodeFormRequest) => {
      const { token } = await validateVerificationCode.mutateAsync({
        verificationCode: request.verificationCode!,
        shortTimeToken: tempUser?.shortTimeToken!,
      });

      await authentication.login(token);
      navigation.navigate('dashboard');
    },
    [
      validateVerificationCode,
      tempUser?.shortTimeToken,
      authentication,
      navigation,
    ]
  );

  const handleResendVerificationCodeBtnClick = useCallback(
    () =>
      resendVerificationCode.mutateAsync({
        shortTimeToken: tempUser?.shortTimeToken!,
      }),
    [resendVerificationCode, tempUser?.shortTimeToken]
  );

  const showResendConfirmationModal = useMemo(() => {
    if (validateCredentials.error instanceof DevelopersPortal.ServerError) {
      const devError: DevelopersPortal.ServerError = validateCredentials.error;
      return (
        devError.statusCode === HttpStatus.FORBIDDEN &&
        devError.description === 'DeveloperIsAwaitingConfirm'
      );
    }

    return false;
  }, [validateCredentials.error]);

  if (!!tempUser) {
    return (
      <Container size="md">
        <Title>{getLabel('verificationCodeForm.title')}</Title>

        <VerificationCodeForm
          email={tempUser.email}
          onSubmit={handleTwoFAFormSubmit}
          onCancel={resetTempUser}
          onResend={handleResendVerificationCodeBtnClick}
        />
      </Container>
    );
  }

  return (
    <>
      <Container size="md">
        <Title>{getLabel('login.title')}</Title>

        {!!validateCredentials.isError && (
          <Alert type="danger">{`${
            (validateCredentials.error as Error).message
          }`}</Alert>
        )}

        {!!initialValues && (
          <LoginForm
            initialValues={initialValues}
            onSubmit={handleLoginFormSubmit}
            onResetPassword={resetPasswordModalControl.toggle}
          />
        )}
      </Container>

      <ResendRegistrationConfirmationModal
        isVisible={showResendConfirmationModal}
        onCancel={validateCredentials.reset}
      />

      <ResetPasswordModal
        isVisible={resetPasswordModalControl.visible}
        onCancel={resetPasswordModalControl.toggle}
      />
    </>
  );
};
