import { useMutation } from 'react-query';
import { useCallback } from 'react';

import {
  Container,
  Title,
  EditAppForm,
  EditAppFormValues,
  AppSuccessfullyCreatedModal,
  mapFormValuesToAppBaseInformation,
} from 'components';
import { useNavigation } from 'hooks';
import { useLabels } from 'config';
import { ContentType, FeeCalculationType } from 'shared';
import { APP_CONTAINER_EXPIRATION_TIME } from 'config/constants';
import { developersPortalClient, storageClient } from 'http-clients';

export const CreateNewApp: React.FC = () => {
  const { getLabel } = useLabels();
  const { goBack } = useNavigation();
  const createApp = useMutation(developersPortalClient.createApp);
  const fileUploader = useMutation(storageClient.upload);

  const initialValues: EditAppFormValues = {
    expirationTimeInHours: APP_CONTAINER_EXPIRATION_TIME.MAX,
    imageUpdated: true,
    require3DAvatar: false,
    requiresPresence: false,
    feeCalculationType: FeeCalculationType.ADDED_TO_REQUESTED,
  };

  const handleSubmit = useCallback(
    async (values: EditAppFormValues) => {
      const { uploadUrl } = await createApp.mutateAsync(
        mapFormValuesToAppBaseInformation({
          ...values,
          imageFileContentLength: values.image?.size,
        })
      );

      fileUploader.mutateAsync({
        uploadUrl,
        file: values.image!,
        contentType: ContentType.IMAGE_PNG,
      });
    },
    [createApp, fileUploader]
  );

  return (
    <>
      <Container size="md">
        <Title>{getLabel('createNewApplication.title')}</Title>

        <EditAppForm
          initialValues={initialValues}
          isCreation={true}
          onSubmit={handleSubmit}
          onCancel={goBack}
        />
      </Container>

      <AppSuccessfullyCreatedModal
        title={getLabel('createNewApplication.successModal.title')}
        isVisible={createApp.isSuccess}
        isUploadLoading={fileUploader.isLoading}
        secretKey={createApp.data?.secretKey!}
        onContinue={goBack}
      />
    </>
  );
};
