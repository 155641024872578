import {
  AppIdentityBar,
  Badge,
  Button,
  Container,
  FlexBox,
  PageHeader,
  SearchWebhookCallsForm,
  Title,
  WebhookCallsSearchParams,
} from 'components';
import { TranslationKeys, useLabels } from 'config';
import { JsonViewer, Wrapper } from './AppWebhooksHistory.style';
import { useWebhookCallsHistory } from 'hooks';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { DateBuilder, formatDate } from 'shared';
import { useFindAppById } from 'hooks/useFindAppById';
import { LoadingBox } from 'components/LoadingBox/LoadingBox';
import { Table, TableColumn } from 'components/Table/Table';
import { DevelopersPortal } from 'http-clients';

const initialSearchParams: WebhookCallsSearchParams = {
  dateRange: {
    startDate: new DateBuilder().addMonths(-1).toDate(),
    endDate: new Date(),
  },
};

export const AppWebhooksHistory: React.FC = () => {
  const { getLabel } = useLabels();
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const queryParams = useParams<{ appId: string }>();
  const appId = parseInt(queryParams.appId!);
  const { webhookCalls, isLoading, hasNextPage, refetch, fetchNextPage } =
    useWebhookCallsHistory({
      searchParams,
      appId,
    });
  const { app, isAppLoading } = useFindAppById(appId);

  if (isAppLoading) {
    return <LoadingBox />;
  }

  if (!app) {
    return <>{getLabel('apps.errorToLoadAppsMessage')}</>;
  }

  return (
    <Wrapper>
      <Container size="md">
        <PageHeader>
          <Title mt={0} mb={0}>
            {getLabel('webhookCallsHistory.title')}
            {false && <Button icon="search" variant="link" />}
          </Title>
          <AppIdentityBar appName={app.name} appStatus={app.status} />
        </PageHeader>

        <SearchWebhookCallsForm
          initialValues={searchParams}
          onSubmit={async searchParams => {
            setSearchParams(searchParams);
            await refetch();
          }}
        />

        <Table<DevelopersPortal.WebhookCall>
          isLoading={isLoading}
          rows={webhookCalls}
        >
          <TableColumn<DevelopersPortal.WebhookCall>
            className="nowrap"
            label={getLabel('webhookCallsHistory.table.notificationType')}
            renderColumn={({ notificationType }) => notificationType}
          />

          <TableColumn<DevelopersPortal.WebhookCall>
            className="text-center"
            label={getLabel('webhookCallsHistory.table.status')}
            renderColumn={({ isSuccess, attemptNumber }) => (
              <FlexBox aligItems="center">
                <Badge
                  color={isSuccess ? 'green' : 'red'}
                  hoverText={getLabel(
                    `webhookCallsHistory.attempts.attempt${attemptNumber}` as TranslationKeys
                  )}
                  width={80}
                >
                  {isSuccess ? 'success' : 'failed'}
                </Badge>
              </FlexBox>
            )}
            width={100}
          />

          <TableColumn<DevelopersPortal.WebhookCall>
            className="text-center"
            label={getLabel('webhookCallsHistory.table.attemptedAt')}
            renderColumn={({ attemptedAt }) => (
              <>
                {formatDate(attemptedAt, 'P')}
                <br />
                {formatDate(attemptedAt, 'pp')}
              </>
            )}
          />

          <TableColumn<DevelopersPortal.WebhookCall>
            className="text-center"
            label={getLabel('webhookCallsHistory.table.containerId')}
            renderColumn={({ containerId }) => (
              <>
                {!!containerId && (
                  <FlexBox aligItems="center">
                    <Badge color="blue">{containerId}</Badge>
                  </FlexBox>
                )}
              </>
            )}
          />

          <TableColumn<DevelopersPortal.WebhookCall>
            label={getLabel('webhookCallsHistory.table.requestBody')}
            renderColumn={({ request }) => (
              <JsonViewer>{JSON.stringify(request)}</JsonViewer>
            )}
          />
        </Table>

        {hasNextPage && !isLoading && (
          <FlexBox aligItems="center">
            <Button
              label={getLabel('general.loadMore')}
              type="button"
              variant="secondary"
              onClick={fetchNextPage}
              size="sm"
              wAuto
            />
          </FlexBox>
        )}
      </Container>
    </Wrapper>
  );
};
