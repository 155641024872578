import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { Modal, SubTitle, Button } from 'components';
import { developersPortalClient } from 'http-clients';
import { useLabels } from 'config';
import { CacheKeys } from 'shared';
import { StyledSandboxCredentialModal } from './Modal.style';
import Skeleton from 'react-loading-skeleton';

export type ShowSandboxCredentialsModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

export const ShowSandboxCredentialsModal: React.FC<
  ShowSandboxCredentialsModalProps
> = ({ isVisible, onClose }) => {
  const findCredentials = useQuery(
    CacheKeys.FIND_SANDBOX_CREDENTIALS,
    developersPortalClient.findSandboxCredentials,
    { enabled: false }
  );
  const { getLabel } = useLabels();

  useEffect(() => {
    if (
      isVisible &&
      !findCredentials.isSuccess &&
      !findCredentials.isRefetching
    ) {
      findCredentials.refetch();
    }
  }, [isVisible, findCredentials]);

  return (
    <Modal size="sm" isVisible={isVisible}>
      <StyledSandboxCredentialModal>
        <SubTitle>{getLabel('sandboxCredentialsModal.title')}</SubTitle>
        <div>
          <div>
            <label>{getLabel('sandboxCredentialsModal.link')}:</label>{' '}
            {findCredentials?.data?.url ?? <Skeleton width={200} height={20} />}
          </div>
          <div>
            <label>{getLabel('sandboxCredentialsModal.username')}:</label>{' '}
            {findCredentials?.data?.username ?? (
              <Skeleton width={200} height={20} />
            )}
          </div>
          <div>
            <label>{getLabel('sandboxCredentialsModal.password')}:</label>{' '}
            {findCredentials?.data?.password ?? (
              <Skeleton width={200} height={20} />
            )}
          </div>
        </div>

        <Button
          label={getLabel('buttons.close')}
          variant="primary"
          onClick={onClose}
          wAuto={true}
        />
      </StyledSandboxCredentialModal>
    </Modal>
  );
};
