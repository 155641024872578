import { useInfiniteQuery } from 'react-query';
import { CacheKeys } from 'shared';
import { DevelopersPortal, developersPortalClient } from 'http-clients';
import { useMemo } from 'react';

export const useWebhookCallsHistory = (
  params: DevelopersPortal.FindWebhookCallsHistoryRequest
) => {
  const { data, isLoading, refetch, fetchNextPage, isFetching, hasNextPage } =
    useInfiniteQuery({
      queryKey: [CacheKeys.FIND_WEBHOOK_CALLS_KEY, params],
      refetchOnWindowFocus: false,
      queryFn: async ({ queryKey, pageParam }) => {
        const [, searchParams] = queryKey;

        return developersPortalClient.findWebhookCallsHistory({
          ...(searchParams as DevelopersPortal.FindWebhookCallsHistoryRequest),
          page: {
            currentPage: pageParam,
            pageSize: 20,
          },
        });
      },
      getNextPageParam: data => {
        if (data.currentPage * data.pageSize > data.totalResults) {
          return undefined;
        }

        return data.currentPage + 1;
      },
    });

  const webhookCalls = useMemo(
    () => data?.pages.flatMap(page => page.results),
    [data]
  );

  return {
    webhookCalls,
    isLoading: isLoading || isFetching,
    hasNextPage,
    refetch,
    fetchNextPage,
  };
};
