import { useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useLabels } from 'config';
import {
  Button,
  DateRangePicker,
  InputText,
  SearchFormRow,
  RadioGroupField,
  FixedWidthBox,
} from 'components';
import { WebhookCallsSearchParams } from './SearchWebhookCallsForm.dto';

export type SearchWebhookCallsFormProps = {
  initialValues: WebhookCallsSearchParams;
  onSubmit: (values: WebhookCallsSearchParams) => Promise<void>;
};

export const SearchWebhookCallsForm: React.FC<SearchWebhookCallsFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const { getLabel } = useLabels();

  const handleSubmit = async (
    request: WebhookCallsSearchParams,
    formikHelpers: FormikHelpers<WebhookCallsSearchParams>
  ) => {
    formikHelpers.setSubmitting(true);
    onSubmit(request).then(() => formikHelpers.setSubmitting(false));
  };

  const statusOptions = useMemo(
    () => [
      {
        label: getLabel('webhookCallsHistory.webhookStatuses.all'),
        value: undefined,
      },
      {
        label: getLabel('webhookCallsHistory.webhookStatuses.success'),
        value: true,
      },
      {
        label: getLabel('webhookCallsHistory.webhookStatuses.failed'),
        value: false,
      },
    ],
    [getLabel]
  );

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={true}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <InputText
            name="searchText"
            className="flex-1"
            placeholder={getLabel(
              'webhookCallsHistory.formLabels.searchByNotificationTypeAndContainer'
            )}
          />

          <SearchFormRow size={60} mb={1}>
            <RadioGroupField
              label={getLabel('webhookCallsHistory.formLabels.statusFilter')}
              name="isSuccess"
              options={statusOptions}
            />

            <div>{getLabel('webhookCallsHistory.formLabels.dateRange')} </div>

            <DateRangePicker name="dateRange" />

            <FixedWidthBox width={150} ignoreMobile>
              <Button
                disabled={!isValid || isSubmitting}
                wAuto
                icon="search"
                label={getLabel('general.search')}
                type="submit"
              />
            </FixedWidthBox>
          </SearchFormRow>
        </Form>
      )}
    </Formik>
  );
};
