export enum Environments {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAGE = 'stage',
  SANDBOX = 'sandbox',
  PRODUCTION = 'production',
}

export enum CaptchaProvider {
  GOOGLE = 'googleCaptcha',
  TURNSTILE = 'turnstile',
}

const LINK_ABOUT_US = process.env.REACT_APP_LINK_ABOUT_US!;
const LINK_FIRST_STEPS = process.env.REACT_APP_LINK_FIRST_STEPS!;
const LINK_API_DOCS = process.env.REACT_APP_LINK_API_DOCS!;
const LINK_UPLAND_HOME_PAGE = process.env.REACT_APP_LINK_UPLAND_HOME_PAGE!;
const LINK_TERMS_AND_CONDITIONS =
  process.env.REACT_APP_LINK_TERMS_AND_CONDITIONS!;
const LINK_PRIVACY_TERMS = process.env.REACT_APP_LINK_PRIVACY_TERMS!;
const LINK_CONTACT = process.env.REACT_APP_LINK_CONTACT!;
const DEVELOPERS_PORTAL_URL = `https://${process.env.REACT_APP_API_DOMAIN}/developers-portal`;
const ACCESS_TOKEN_STORAGE_KEY = `${process.env.REACT_APP_NAME}:${process.env.NODE_ENV}:AccessToken`;
const LAST_LOGIN_STORAGE_KEY = `${process.env.REACT_APP_NAME}:${process.env.NODE_ENV}:LastLogin`;
const APPS_LIMIT_AMOUNT = Number(process.env.REACT_APP_APPS_LIMIT_AMOUNT!);
const APP_CONTAINER_EXPIRATION_TIME = {
  MIN: Number(process.env.REACT_APP_MIN_CONTAINER_EXPIRATION_TIME!),
  MAX: Number(process.env.REACT_APP_MAX_CONTAINER_EXPIRATION_TIME!),
};
const VERIFICATION_CODE_SIZE = Number(
  process.env.REACT_APP_VERIFICATION_CODE_SIZE
);
const SHOW_SANDBOX_CREDENTIALS_MODAL =
  process.env.REACT_APP_SHOW_SANDBOX_CREDENTIALS_MODAL === 'true';
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as Environments;
const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS!;
const PROJECT_VERSION = process.env.REACT_APP_VERSION ?? '0.0.0';
const SANDBOX_BANNER_HEIGHT = '48px';
const SANDBOX_APPLICATION_URL = process.env.REACT_APP_SANDBOX_APPLICATION_URL!;
const TURNSTILE_SITEKEY = process.env.REACT_APP_TURNSTILE_SITEKEY!;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY!;
const CAPTCHA_PROVIDER =
  process.env.REACT_APP_CAPTCHA_PROVIDER === CaptchaProvider.TURNSTILE
    ? CaptchaProvider.TURNSTILE
    : CaptchaProvider.GOOGLE;

export {
  LINK_ABOUT_US,
  LINK_FIRST_STEPS,
  LINK_API_DOCS,
  LINK_UPLAND_HOME_PAGE,
  LINK_TERMS_AND_CONDITIONS,
  LINK_PRIVACY_TERMS,
  LINK_CONTACT,
  DEVELOPERS_PORTAL_URL,
  ACCESS_TOKEN_STORAGE_KEY,
  LAST_LOGIN_STORAGE_KEY,
  APPS_LIMIT_AMOUNT,
  APP_CONTAINER_EXPIRATION_TIME,
  VERIFICATION_CODE_SIZE,
  SHOW_SANDBOX_CREDENTIALS_MODAL,
  ENVIRONMENT,
  SENTRY_DNS,
  PROJECT_VERSION,
  SANDBOX_BANNER_HEIGHT,
  SANDBOX_APPLICATION_URL,
  TURNSTILE_SITEKEY,
  RECAPTCHA_SITE_KEY,
  CAPTCHA_PROVIDER,
};
