export type UplandAccountVerificationType = {
  startVerificationFlow: (uplandUsername: string) => void;
  verified: boolean;
};

export enum UplandAccountVerificationSteps {
  HIDDEN = 0,
  VERIFY_USERNAME = 1,
  VERIFY_CODE = 2,
  VERIFIED = 3,
}
