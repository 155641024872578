import styled from 'styled-components';

export const Wrapper = styled.div<{ error: boolean }>`
  margin-bottom: ${props => (props.error ? '0.25rem' : '1.25rem')};
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<{
  checked: boolean;
  isError?: boolean;
}>`
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 0.75rem;
  background: ${props =>
    props.checked
      ? props.theme.palette.common.black
      : props.theme.palette.common.white};
  border-radius: 3px;
  border: 1px solid
    ${props =>
      props.isError
        ? props.theme.palette.common.red.default
        : props.theme.palette.common.black};
  transition: all 150ms;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const CheckboxContainer = styled.div<{ marginBottom?: string }>`
  display: flex;
  flex-direction: row;
  margin-bottom: ${props => props.marginBottom ?? '0'};
`;

export const StyledContent = styled.div`
  flex: 1;
`;
