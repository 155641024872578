import { object, string, SchemaOf, bool } from 'yup';

import { useLabels } from 'config';
import { validEmail } from 'shared/yup-extensions';
import { LoginRequest } from './LoginRequest.dto';

export const useLoginRequestSchema = () => {
  const { getLabel } = useLabels();

  const loginRequestSchema: SchemaOf<LoginRequest> = object({
    email: validEmail({
      fieldName: getLabel('login.formLabels.email'),
      getLabelByKey: getLabel,
    }).required(),
    password: string()
      .min(8)
      .max(50)
      .required()
      .label(getLabel('login.formLabels.password')),
    rememberMe: bool().optional(),
  });

  return loginRequestSchema;
};
