import styled from 'styled-components';
import { SANDBOX_BANNER_HEIGHT } from 'config/constants';

export const Wrapper = styled.div`
  text-align: center;
  padding: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    padding-bottom: 6rem;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  text-align: left;
  gap: 1rem;
`;

export const PageHeaderActions = styled.div`
  display: flex;
  gap: 1rem;
`;

export const PageHeaderFloatButton = styled.div<{ isSandbox: boolean }>`
  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    position: fixed;
    bottom: ${props => (props.isSandbox ? SANDBOX_BANNER_HEIGHT : '0px')};
    left: 0;
    right: 0;
    z-index: 10;
    padding: 1rem;
    background-color: ${props => props.theme.palette.common.white};
    box-shadow: 0 -4px 10px ${props => props.theme.palette.common.gray.midGray};
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    width: 280px;
  }
`;
