import { AppStatus } from 'shared';
import { StyledAppIdentityBar, StyledAppName } from './AppIdentityBar.style';
import { AppStatusBadge } from 'components/Badge';

export const AppIdentityBar: React.FC<{
  appName: string;
  appStatus: AppStatus;
}> = ({ appName, appStatus }) => (
  <StyledAppIdentityBar>
    <StyledAppName>{appName}</StyledAppName>
    <AppStatusBadge appStatus={appStatus} />
  </StyledAppIdentityBar>
);
