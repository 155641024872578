import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { BadgeColor, StyledBadge } from './Badge.style';

export type BadgeProps = {
  color: BadgeColor;
  children: ReactNode;
  hoverText?: string;
  width?: number;
};

export const Badge: React.FC<BadgeProps> = ({
  hoverText,
  color,
  children,
  width = 300,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [badgePosition, setBadgePosition] = useState<'left' | 'right'>('left');

  const checkBadgePosition = useCallback(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const [left, right] = [rect.left, window.innerWidth - rect.right];
      setBadgePosition(left < right ? 'left' : 'right');
    }
  }, [setBadgePosition]);

  useEffect(() => {
    checkBadgePosition();
    window.addEventListener('resize', checkBadgePosition);
    return () => window.removeEventListener('resize', checkBadgePosition);
  }, [checkBadgePosition]);

  return (
    <StyledBadge
      className="badge"
      position={badgePosition}
      ref={ref}
      data-text={hoverText}
      color={color}
      width={width}
      tooltipDisabled={!hoverText}
    >
      {children}
    </StyledBadge>
  );
};
