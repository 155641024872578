import { AllowedDevices, FeeCalculationType, Maybe } from 'shared';

export type EditAppFormValues = {
  name?: string;
  expirationTimeInHours?: number;
  webhookUrl?: string;
  webhookCustomHeader?: Record<string, string>;
  description?: Maybe<string>;
  image?: Maybe<Blob>;
  imageUpdated?: Maybe<boolean>;
  allowedDevices?: Array<AllowedDevices>;
  require3DAvatar?: boolean;
  requiresPresence?: boolean;
  feeCalculationType?: FeeCalculationType;
};

export const mapFormValuesToAppBaseInformation = (
  values: EditAppFormValues & { imageFileContentLength: Maybe<number> }
) => ({
  name: values.name!,
  description: values.description,
  expirationTimeInHours: values.expirationTimeInHours!,
  webhookUrl: values.webhookUrl ? values.webhookUrl : undefined,
  webhookCustomHeader: values.webhookUrl
    ? values.webhookCustomHeader
    : undefined,
  imageFileContentLength: values.image?.size,
  allowedDevices: values.allowedDevices ?? [],
  require3DAvatar: values.require3DAvatar!,
  requiresPresence: values.requiresPresence!,
  feeCalculationType: values.feeCalculationType!,
});
