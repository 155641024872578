import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export const StyledDatePicker = styled(DatePicker)`
  border: none;
  box-sizing: border-box;
  background-color: ${props => props.theme.palette.common.gray.veryLight};
  font-family: ${props => props.theme.typography.fontFamily};
  width: 240px;
  text-align: center;
  padding: 0 1.25rem;
  border-radius: 25px;
  height: 48px;

  &:focus-visible {
    outline-width: 0;
    box-shadow: 0 0 0 2px ${props => props.theme.palette.primary.main};
  }
`;
