import { object, string, SchemaOf } from 'yup';

import { useLabels } from 'config';
import { UplandUsernameFormValues } from './UplandUsernameForm.dto';

export const useUplandUsernameFormValidationSchema = () => {
  const { getLabel } = useLabels();

  const uplandUsernameFormValidationSchema: SchemaOf<UplandUsernameFormValues> =
    object({
      uplandUsername: string()
        .max(40)
        .required()
        .label(getLabel('registration.formLabels.uplandUsername')),
    });

  return uplandUsernameFormValidationSchema;
};
