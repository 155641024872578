import styled, { css } from 'styled-components';

import { AlignItems, FlexDirection, JustifyContent } from 'shared';

type Props = {
  gap?: number;
  flexDirection?: FlexDirection;
  justifyContent?: JustifyContent;
  aligItems?: AlignItems;
  flex?: number;
  mb?: number;
};

export type FlexBoxProps = {
  md?: Props;
} & Props;

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection ?? 'column'};
  gap: ${props => props.gap ?? 0}rem;
  justify-content: ${props => props.justifyContent ?? 'initial'};
  align-items: ${props => props.aligItems ?? 'initial'};
  flex: ${props => props.flex ?? 'inherit'};
  margin-bottom: ${props => (props.mb ? `${props.mb}rem` : 0)};

  @media (min-width: ${props => props.theme.breakpoints.md}px) {
    ${props =>
      props.md?.flexDirection &&
      css`
        flex-direction: ${props.md?.flexDirection};
      `}
    ${props =>
      props.md?.gap &&
      css`
        gap: ${props.md?.gap}rem;
      `}
    ${props =>
      props.md?.justifyContent &&
      css`
        justify-content: ${props.md?.justifyContent};
      `}
    ${props =>
      props.md?.aligItems &&
      css`
        align-items: ${props.md?.aligItems};
      `}
    ${props =>
      props.md?.flex &&
      css`
        flex: ${props.md?.flex};
      `}
    ${props =>
      props.md?.mb &&
      css`
        margin-bottom: ${props.md?.mb}rem;
      `}
  }
`;
