import { useCallback, useEffect } from 'react';

import { Button } from 'components';
import {
  useAuthentication,
  useUplandAccountVerification,
  KycStatus,
} from 'hooks';
import {
  Content,
  StyledValidateAccountToolbar,
} from './ValidateAccountToolbar.style';
import { useLabels } from 'config';

export const ValidateAccountToolbar: React.FC = () => {
  const { getLabel } = useLabels();
  const { authUser, reloadAuthUserData } = useAuthentication();
  const { startVerificationFlow, verified } = useUplandAccountVerification();

  useEffect(() => {
    if (!!verified) {
      reloadAuthUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verified]);

  const handleValidateYourAccount = useCallback(
    () => startVerificationFlow(authUser?.uplandUsername ?? ''),
    [authUser, startVerificationFlow]
  );

  if (authUser?.verificationState?.kycStatus === KycStatus.UNKNOWN) {
    return (
      <StyledValidateAccountToolbar>
        <Content>
          {getLabel('validateAccountToolbar.description')}
          <Button
            label={getLabel('validateAccountToolbar.btnValidateAccount')}
            onClick={handleValidateYourAccount}
            size="sm"
            wAuto={true}
          />
        </Content>
      </StyledValidateAccountToolbar>
    );
  }

  return null;
};
