import { Field, useField } from 'formik';
import { BaseSyntheticEvent } from 'react';
import { DefaultTheme, StyledComponent } from 'styled-components';

import { StyledErrorMessage, StyledInput, Wrapper } from '../Common.style';

export type InputTextType = 'text' | 'email' | 'password' | 'url' | 'tel';

export type InputTextProps = {
  name: string;
  type?: InputTextType;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  className?: string;
  inputComponent?: StyledComponent<'input', DefaultTheme, {}, never>;
  normalize?: (value: string) => string;
};

export const InputText: React.FC<InputTextProps> = ({
  type = 'text',
  inputComponent = StyledInput,
  ...props
}) => {
  const [, meta, helper] = useField(props.name);

  const hasError = !!meta.error && !!meta.touched;

  if (!!props.normalize) {
    const { normalize } = props;
    Object.assign(props, {
      onChange: (event: BaseSyntheticEvent) => {
        helper.setValue(normalize(event.currentTarget.value ?? ''));
      },
    });
  }

  return (
    <Wrapper error={hasError} className={props.className}>
      <Field type={type} {...props} as={inputComponent} error={hasError} />
      {!!hasError && <StyledErrorMessage>{meta.error}</StyledErrorMessage>}
    </Wrapper>
  );
};
