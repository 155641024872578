import { useCallback, useRef, useState, useEffect } from 'react';

export function useVisibilityControl<T extends HTMLElement>(
  initialValue: boolean
) {
  const [visible, setVisible] = useState(initialValue);
  const ref = useRef<T>(null);

  const toggle = useCallback(() => setVisible(!visible), [visible]);

  const handleClick = useCallback(
    (event: Event) => {
      if (event.target && !ref.current?.contains(event.target as Node)) {
        toggle();
        document.removeEventListener('click', handleClick);
      }
    },
    [toggle]
  );

  useEffect(() => {
    if (ref?.current && visible) {
      setTimeout(() => document.addEventListener('click', handleClick), 1);
    }
  }, [visible, handleClick]);

  return {
    visible,
    ref,
    toggle,
  };
}
