import { ReactNode, Children, useMemo } from 'react';
import { StyledTable, TableWrapper } from './Table.style';
import { LoadingBox } from 'components/LoadingBox';
import { useLabels } from 'config';

export type TableProps<T> = {
  isLoading?: boolean;
  rows?: T[];
  children: ReactNode;
};

export type TableColumnProps<T> = {
  label: ReactNode | string;
  renderColumn: (value: T) => ReactNode | string;
  width?: number;
  className?: string;
};

export function TableColumn<T>(_: TableColumnProps<T>) {
  return null;
}

export function Table<T>({ rows, isLoading, children }: TableProps<T>) {
  const { getLabel } = useLabels();

  const columns = useMemo(
    () =>
      Children.map(
        children,
        child => (child as React.ReactElement<TableColumnProps<T>>).props
      ),
    [children]
  );

  return (
    <TableWrapper>
      {isLoading && <LoadingBox />}
      <StyledTable cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            {columns?.map((column, index) => {
              const { label, renderColumn, ...props } = column;
              return (
                <th key={index} {...props}>
                  {column.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!isLoading && !rows?.length && (
            <tr>
              <td className="text-center" colSpan={columns?.length}>
                {getLabel('table.noRecordsFound')}
              </td>
            </tr>
          )}
          {rows?.map(row => (
            <tr>
              {columns?.map(column => {
                const { label, renderColumn, ...props } = column;
                return <td {...props}>{column.renderColumn(row)}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
}
