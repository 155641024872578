import { useCallback } from 'react';
import { useMutation } from 'react-query';

import {
  Modal,
  RequestResetPasswordForm,
  RequestResetPasswordFormRequest,
} from 'components';
import { developersPortalClient } from 'http-clients';
import { useCaptchaValidation } from 'hooks';

export type ResetPasswordModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onSubmit: (token: string, email: string) => void;
};

export const RequestResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  isVisible,
  onCancel,
  onSubmit,
}) => {
  const captchaValidation = useCaptchaValidation();
  const requestResetPassword = useMutation(
    developersPortalClient.requestResetPassword
  );

  const handleSubmit = useCallback(
    async (values: RequestResetPasswordFormRequest) => {
      const { captchaCode } = await captchaValidation.validate();
      const result = await requestResetPassword.mutateAsync({
        email: values.email!,
        captchaCode,
      });

      return onSubmit(result.token, values.email!);
    },
    [captchaValidation, onSubmit, requestResetPassword]
  );

  return (
    <Modal size="sm" isVisible={isVisible && !requestResetPassword.isSuccess}>
      <RequestResetPasswordForm onSubmit={handleSubmit} onCancel={onCancel} />
    </Modal>
  );
};
