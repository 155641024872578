import styled from 'styled-components';

export type BadgeColor = 'blue' | 'green' | 'red';
export type BadgePosition = 'left' | 'right';

export const StyledBadge = styled.div<{
  color: BadgeColor;
  position: BadgePosition;
  width: number;
  tooltipDisabled?: boolean;
}>`
  color: ${props => props.theme.palette.common[props.color].default};
  background-color: ${props => props.theme.palette.common[props.color].light};
  padding: 0.3rem 0.8rem;
  border-radius: 10rem;
  height: 16px;
  font-size: 0.9rem;
  font-weight: ${props => props.theme.typography.fontWeight.semiBold};
  position: relative;

  ::after {
    content: attr(data-text) ' ';
    color: ${props => props.theme.palette.common.white};
    position: absolute;
    right: ${props => (props.position === 'right' ? 0 : 'auto')};
    left: ${props => (props.position === 'left' ? 0 : 'auto')};
    top: 30px;
    background: ${props => props.theme.palette.common.black};
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    font-weight: normal;
    font-size: 0.8rem;
    width: ${props => props.width}px;
    transform: rotateY(90deg);
    transition: opacity 150ms ease-in-out;
    opacity: 0;
    z-index: 1;
  }

  :hover {
    ::after {
      transform: rotateY(0);
      opacity: ${props => (props.tooltipDisabled ? 0 : 0.9)};
    }
  }
`;
