import { ReactNode } from 'react';

import {
  DropdownButtonContainer,
  OptionsList,
  Option,
  StyledDropdownButton,
  OptionsWrapper,
} from './DropdownButton.style';
import ellipsisIcon from 'assets/images/ellipsis-icon.svg';
import { useVisibilityControl, useScrollControl } from 'hooks';

export type DropdownOption = {
  label: string;
  onClick(): void;
};

export type DropdownButtonProps = {
  options: DropdownOption[];
  disabled?: boolean;
  buttonComponent?: (onClick: () => void) => ReactNode;
};

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  options,
  buttonComponent,
  disabled = false,
}) => {
  const { ref, visible, toggle } = useVisibilityControl<HTMLDivElement>(false);
  useScrollControl(visible, { onlyOnMobile: true });

  return (
    <DropdownButtonContainer>
      {!!buttonComponent ? (
        buttonComponent(toggle)
      ) : (
        <StyledDropdownButton
          type="button"
          onClick={toggle}
          disabled={disabled}
        >
          <img src={ellipsisIcon} alt="dropdown" />
        </StyledDropdownButton>
      )}

      {!!visible && (
        <OptionsWrapper>
          <OptionsList ref={ref}>
            {options.map(option => (
              <Option
                key={option.label}
                onClick={() => {
                  option.onClick();
                  toggle();
                }}
              >
                {option.label}
              </Option>
            ))}
          </OptionsList>
        </OptionsWrapper>
      )}
    </DropdownButtonContainer>
  );
};
