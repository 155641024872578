import {
  Alert,
  Button,
  SubTitle,
  SuccessModal,
  CopyTextButton,
} from 'components';
import { useLabels } from 'config';
import { CopySecretKeyWrapper } from './Modal.style';

export type AppSuccessfullyCreatedModalProps = {
  title: string;
  isVisible: boolean;
  isUploadLoading: boolean;
  secretKey: string;
  onContinue: () => void;
};

export const AppSuccessfullyCreatedModal: React.FC<
  AppSuccessfullyCreatedModalProps
> = ({ title, isVisible, isUploadLoading, secretKey, onContinue }) => {
  const { getLabel } = useLabels();

  return (
    <SuccessModal isVisible={isVisible}>
      <SubTitle>{title}</SubTitle>

      <Alert type="warning">
        {getLabel('createNewApplication.successModal.warningContent')}
      </Alert>
      <Alert type="success">
        {getLabel('createNewApplication.successModal.content')}
      </Alert>

      <CopySecretKeyWrapper>
        <label>
          {getLabel('createNewApplication.successModal.accessTokenLabel')}
        </label>
        <CopyTextButton content={secretKey} />
      </CopySecretKeyWrapper>

      {isUploadLoading && (
        <Alert type="warning">{getLabel('general.waitingForUpload')}</Alert>
      )}
      <Button
        label={getLabel('general.continue')}
        loading={isUploadLoading}
        variant="primary"
        onClick={onContinue}
        wAuto={true}
      />
    </SuccessModal>
  );
};
