import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  borderRadius: '25px',
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontColor: {
      default: '#3B3D45',
      link: '#1d6eab',
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
    },
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1024,
    xl: 1920,
  },
  palette: {
    common: {
      black: '#232323',
      white: '#FFFFFF',
      blue: {
        light: '#c7dcea',
        default: '#1C6EAB',
      },
      red: {
        light: '#F7E2E1',
        default: '#ff5b5b',
      },
      yellow: {
        light: '#fff3cd',
        default: '#856404',
      },
      gray: {
        veryLight: '#F4F4F4',
        midGray: '#CCCCCC',
        standard: '#999999',
      },
      green: {
        light: '#d4edda',
        default: '#155724',
      },
    },
    primary: {
      main: '#0000EE',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F4F4F4',
      contrastText: '#232323',
    },
  },
};
