import Axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { Image as ImageJs } from 'image-js';

import { FormValidationException } from 'common/exceptions';
import { CAPTCHA_PROVIDER, DEVELOPERS_PORTAL_URL } from 'config/constants';
import { getAccessToken, resetAcessToken } from 'hooks';
import { DateBuilder, HttpStatus } from 'shared';
import { DevelopersPortal } from './dtos';
import appPicturePlaceholder from 'assets/images/app-picture-placeholder.png';

const CAPTCHA_TOKEN_KEY = 'captcha-token';
const CAPTCHA_TYPE = 'Captcha-Type';
const CAPTCHA_PLATFORM = 'Platform';

const buildCapchaHeaders = (code: string) => ({
  [CAPTCHA_TOKEN_KEY]: code,
  [CAPTCHA_TYPE]: CAPTCHA_PROVIDER,
  [CAPTCHA_PLATFORM]: 'web',
});

const axios: AxiosInstance = Axios.create({
  baseURL: DEVELOPERS_PORTAL_URL,
});

axios.interceptors.response.use(
  function (success) {
    return success;
  },
  function (error) {
    if (Axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{ error: string }>;

      const blackListPaths = ['developers/me/change-password', 'login'];

      const isUnauthorized =
        axiosError.response?.status === HttpStatus.UNAUTHORIZED;
      const isForbidden =
        axiosError.response?.status === HttpStatus.FORBIDDEN &&
        axiosError.response?.data?.error ===
          DevelopersPortal.Errors.ACTION_NOT_ALLOWED_TO_DEVELOPER_STATUS;
      const inBlacklist =
        blackListPaths.filter(path =>
          axiosError.request?.responseURL.includes(path)
        ).length > 0;

      if ((isUnauthorized || isForbidden) && !inBlacklist) {
        resetAcessToken();
        document.location.href = '/';
      }
    }

    return Promise.reject(error);
  }
);

async function handleResponse<T>(response: Promise<AxiosResponse>) {
  try {
    const { data } = await response;

    return data as T;
  } catch (error) {
    if (Axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      const validationErrors = axiosError.response
        ?.data as DevelopersPortal.ValidationErrors;

      if (axiosError.response?.status === HttpStatus.BAD_REQUEST) {
        if (Array.isArray(validationErrors.message)) {
          const errors: Record<string, string[]> = {};

          validationErrors.message.forEach(error => {
            const [message] = Object.values(error.constraints);
            errors[error.property] = [message];
          });

          throw new FormValidationException('Form validation errors', errors);
        }
      }

      if (!Array.isArray(validationErrors.message)) {
        axiosError.message = ((axiosError.response?.data as any).message ??
          axiosError.message) as string;

        throw new DevelopersPortal.ServerError(
          axiosError,
          validationErrors.statusCode,
          validationErrors.error
        );
      }
    }

    throw error;
  }
}

function getHeaderWithAccessToken() {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
  };
}

export const developersPortalClient = {
  async signup({
    captchaCode,
    ...body
  }: DevelopersPortal.CreateDeveloperRequest): Promise<DevelopersPortal.CreateDeveloperResponse> {
    const preparedBody: Omit<
      DevelopersPortal.CreateDeveloperRequest,
      'captchaCode'
    > = {
      ...body,
      uplandUsername: body.uplandUsername || undefined,
      bio: body.bio || undefined,
      profileUrl: body.profileUrl || undefined,
    };

    return handleResponse(
      axios.post('sign-up', preparedBody, {
        headers: buildCapchaHeaders(captchaCode),
      })
    );
  },

  login({
    captchaCode,
    ...body
  }: DevelopersPortal.LoginRequest): Promise<DevelopersPortal.LoginResponse> {
    return handleResponse(
      axios.post('login', body, { headers: buildCapchaHeaders(captchaCode) })
    );
  },

  resendRegistrationConfirmation(
    body: DevelopersPortal.ResendRegistrationConfirmationRequest
  ): Promise<void> {
    return handleResponse(axios.post('resend-confirmation', body));
  },

  getAuthenticatedUser(): Promise<DevelopersPortal.AuthenticatedUser> {
    const headers = getHeaderWithAccessToken();

    return handleResponse(
      axios.get<DevelopersPortal.AuthenticatedUser>('developers/me', {
        headers,
      })
    );
  },

  async loadImage(url: string): Promise<Blob> {
    try {
      const loadedImage = await ImageJs.load(url);
      return await loadedImage.toBlob();
    } catch (error) {
      const loadedPlaceholder = await ImageJs.load(appPicturePlaceholder);
      return await loadedPlaceholder.toBlob();
    }
  },

  async findProfile(): Promise<
    DevelopersPortal.AuthenticatedUser & { image: Blob }
  > {
    const profile = await developersPortalClient.getAuthenticatedUser();

    return Object.assign({
      ...profile,
      image: await developersPortalClient.loadImage(profile.profilePictureUrl!),
    });
  },

  findApps(): Promise<DevelopersPortal.FindAppsResponse> {
    const headers = getHeaderWithAccessToken();

    return handleResponse(
      axios.get<DevelopersPortal.FindAppsResponse>('apps', { headers })
    );
  },

  async findAppById(appId: number): Promise<DevelopersPortal.FindAppById> {
    const app = await developersPortalClient
      .findApps()
      .then(({ apps }: DevelopersPortal.FindAppsResponse) =>
        apps.find(app => app.id === appId)
      );

    return {
      id: app?.id!,
      name: app?.name!,
      slug: app?.slug!,
      expirationTimeInHours: app?.expirationTimeInHours!,
      status: app?.status!,
      webhookUrl: app?.webhookUrl ? app?.webhookUrl : undefined,
      webhookCustomHeader: app?.webhookCustomHeader,
      description: app?.description!,
      image: await developersPortalClient.loadImage(app?.pictureUrl!),
      allowedDevices: app?.allowedDevices!,
      require3DAvatar: app?.require3DAvatar!,
      requiresPresence: app?.requiresPresence!,
      feeCalculationType: app?.feeCalculationType!,
    };
  },

  validateAccount(
    token: string
  ): Promise<DevelopersPortal.ValidateTokenResponse> {
    return handleResponse(
      axios.patch<DevelopersPortal.AuthenticatedUser>('validate-account', {
        token,
      })
    );
  },

  createApp(
    request: DevelopersPortal.CreateAppRequest
  ): Promise<DevelopersPortal.CreateAppResponse> {
    const headers = getHeaderWithAccessToken();

    return handleResponse(
      axios.post<DevelopersPortal.CreateAppResponse>('apps', request, {
        headers,
      })
    );
  },

  validateVerificationCode(
    request: DevelopersPortal.ValidateVerificationCodeRequest
  ): Promise<DevelopersPortal.ValidateVerificationCodeResponse> {
    const path = `2fa/${request.verificationCode}/validate`;
    const headers = {
      Authorization: `Bearer ${request.shortTimeToken}`,
    };

    return handleResponse(
      axios.post<DevelopersPortal.ValidateVerificationCodeResponse>(
        path,
        null,
        { headers }
      )
    );
  },

  resendVerificationCode(
    request: DevelopersPortal.ResendVerificationCodeRequest
  ): Promise<DevelopersPortal.GenerateVerificationCodeResponse> {
    const path = '2fa/resend';
    const headers = {
      Authorization: `Bearer ${request.shortTimeToken}`,
    };
    return handleResponse(axios.post<void>(path, null, { headers }));
  },

  requestAppEdition(): Promise<DevelopersPortal.GenerateVerificationCodeResponse> {
    const headers = getHeaderWithAccessToken();
    return handleResponse(
      axios.post<DevelopersPortal.GenerateVerificationCodeResponse>(
        'apps/edit',
        null,
        {
          headers,
        }
      )
    );
  },

  editApp({
    id,
    verificationCode,
    appInformation,
  }: DevelopersPortal.EditAppRequest): Promise<DevelopersPortal.EditAppResponse> {
    const headers = getHeaderWithAccessToken();
    return handleResponse(
      axios.put<DevelopersPortal.EditAppResponse>(
        `apps/${id}`,
        {
          verificationCode,
          ...appInformation,
        },
        {
          headers,
        }
      )
    );
  },

  editDeveloper(
    request: DevelopersPortal.EditDeveloperRequest
  ): Promise<DevelopersPortal.EditDeveloperResponse> {
    const headers = getHeaderWithAccessToken();

    return handleResponse(
      axios.put<DevelopersPortal.EditDeveloperResponse>(
        'developers/me',
        request,
        {
          headers,
        }
      )
    );
  },

  requestDeleveloperEdition(): Promise<DevelopersPortal.GenerateVerificationCodeResponse> {
    const headers = getHeaderWithAccessToken();
    return handleResponse(
      axios.post<DevelopersPortal.GenerateVerificationCodeResponse>(
        'developers/me/edit',
        null,
        {
          headers,
        }
      )
    );
  },

  verifyUplandUsername(
    request: DevelopersPortal.VerifyUplandUsernameRequest
  ): Promise<DevelopersPortal.GenerateVerificationCodeResponse> {
    const headers = getHeaderWithAccessToken();
    return handleResponse(
      axios.post<void>('verify-account/upland', request, { headers })
    );
  },

  verifyCodeToConfirmUplandUsername(
    request: DevelopersPortal.VerifyCodeToConfirmUplandUsernameRequest
  ): Promise<void> {
    const headers = getHeaderWithAccessToken();
    return handleResponse(
      axios.patch<void>('verify-account/upland', request, { headers })
    );
  },

  changePassword({
    captchaCode,
    ...request
  }: DevelopersPortal.ChangePasswordRequest): Promise<void> {
    const headers = {
      ...getHeaderWithAccessToken(),
      ...buildCapchaHeaders(captchaCode),
    };
    return handleResponse(
      axios.post<void>('developers/me/change-password', request, { headers })
    );
  },

  changeStatusApp({
    id,
    ...body
  }: DevelopersPortal.ChangeAppStatusRequest): Promise<DevelopersPortal.ChangeAppStatusResponse> {
    const headers = getHeaderWithAccessToken();
    return handleResponse(
      axios.patch<DevelopersPortal.ChangeAppStatusResponse>(
        `apps/${id}/status`,
        body,
        {
          headers,
        }
      )
    );
  },

  requestPasswordReset(
    request: DevelopersPortal.RequestPasswordResetRequest
  ): Promise<DevelopersPortal.GenerateVerificationCodeResponse> {
    return handleResponse(
      axios.post<DevelopersPortal.GenerateVerificationCodeResponse>(
        'request-password-reset',
        request
      )
    );
  },

  requestResetPassword({
    captchaCode,
    ...request
  }: DevelopersPortal.RequestPasswordResetRequest): Promise<DevelopersPortal.RequestPasswordResponse> {
    return handleResponse(
      axios.post<DevelopersPortal.RequestPasswordResponse>(
        'reset-password/request',
        request,
        {
          headers: buildCapchaHeaders(captchaCode),
        }
      )
    );
  },

  resendResetPassword(
    token: string
  ): Promise<DevelopersPortal.GenerateVerificationCodeResponse> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return handleResponse(
      axios.post('reset-password/resend-vcp', null, {
        headers,
      })
    );
  },

  checkVcpResetPassword({
    token,
    ...request
  }: DevelopersPortal.CheckResetPasswordVcpRequest): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return handleResponse(
      axios.post('reset-password/check-vcp', request, {
        headers,
      })
    );
  },

  resetPassword({
    token,
    ...request
  }: DevelopersPortal.ResetPasswordRequest): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return handleResponse(
      axios.post('reset-password', request, {
        headers,
      })
    );
  },

  findSandboxCredentials(): Promise<DevelopersPortal.FindSandboxCredentialsResponse> {
    const headers = getHeaderWithAccessToken();

    return handleResponse(
      axios.get<DevelopersPortal.FindSandboxCredentialsResponse>(
        'sandbox-credentials',
        { headers }
      )
    );
  },

  async findWebhookCallsHistory({
    appId,
    page,
    searchParams,
  }: DevelopersPortal.FindWebhookCallsHistoryRequest) {
    const headers = getHeaderWithAccessToken();
    const params = [
      `currentPage=${page?.currentPage ?? 1}`,
      `pageSize=${page?.pageSize ?? 20}`,
      `startDate=${new DateBuilder(
        searchParams.dateRange.startDate
      ).toString()}`,
      `endDate=${new DateBuilder(searchParams.dateRange.endDate).toString()}`,
    ];

    if (!!searchParams.searchText) {
      params.push(
        `${
          isNaN(Number(searchParams.searchText))
            ? 'notificationType'
            : 'containerId'
        }=${searchParams.searchText.trim()}`
      );
    }

    if (searchParams.isSuccess !== undefined) {
      params.push(`isSuccess=${searchParams.isSuccess}`);
    }

    const response =
      await handleResponse<DevelopersPortal.FindWebhookCallsHistoryResponse>(
        axios.get(`apps/${appId}/webhook-calls-history?${params.join('&')}`, {
          headers,
        })
      );

    return {
      ...response,
      results: response.results.map(item => ({
        ...item,
        attemptedAt: new Date(item.attemptedAt),
      })),
    };
  },

  validateWebhookUrl(
    payload: DevelopersPortal.ValidateWebhookRequest
  ): Promise<DevelopersPortal.ValidateWebhookResponse> {
    const headers = getHeaderWithAccessToken();
    return handleResponse(
      axios.post<DevelopersPortal.AuthenticatedUser>(
        'webhook-url-validation',
        payload,
        { headers }
      )
    );
  },
};
