import { useField } from 'formik';
import { ReactNode, useCallback, useMemo } from 'react';

import {
  CheckboxContainer,
  StyledCheckbox,
  StyledContent,
} from './Checkbox.style';
import checkIcon from 'assets/images/check.svg';
import { CheckboxItem, Title } from './Common.style';

export type CheckboxGroupProps<T> = {
  name: string;
  options: Array<T>;
  label: ReactNode;
  getLabel?: (option: T) => string;
  compareTo?: (left: T, right: T) => boolean;
};

export function CheckboxGroup<T>({
  name,
  options,
  label,
  getLabel = (option: T) => `${option}`,
  compareTo = (left: T, right: T) => left === right,
}: CheckboxGroupProps<T>) {
  const [field, meta, helper] = useField(name);

  const selectedValues = useMemo(
    () => (field.value ?? []) as Array<T>,
    [field.value]
  );

  const isChecked = useCallback(
    (option: T) => selectedValues.some(value => compareTo(value, option)),
    [selectedValues, compareTo]
  );

  const addItem = useCallback(
    (item: T) => [...selectedValues, item],
    [selectedValues]
  );

  const removeItem = useCallback(
    (item: T) => selectedValues.filter(value => !compareTo(value, item)),
    [selectedValues, compareTo]
  );

  const handleChange = useCallback(
    (selected: T) => {
      helper.setTouched(true);
      helper.setValue(
        isChecked(selected) ? removeItem(selected) : addItem(selected)
      );
    },
    [helper, isChecked, addItem, removeItem]
  );

  const isError = useMemo(() => meta.touched && !!meta.error, [meta]);

  return (
    <div>
      <Title>{label}</Title>
      {options.map(option => (
        <CheckboxItem key={`${option}`} onClick={() => handleChange(option)}>
          <CheckboxContainer marginBottom="0.75rem">
            <StyledCheckbox
              isError={isError}
              className="option"
              checked={isChecked(option)}
            >
              <img src={checkIcon} alt={getLabel(option)} />
            </StyledCheckbox>
            <StyledContent>{getLabel(option)}</StyledContent>
          </CheckboxContainer>
        </CheckboxItem>
      ))}
    </div>
  );
}
