import { useCallback } from 'react';
import { useMutation } from 'react-query';
import {
  Modal,
  ChangePasswordForm,
  ChangePasswordFormRequest,
  SuccessModal,
  SubTitle,
  Button,
} from 'components';
import { developersPortalClient } from 'http-clients';
import { useLabels } from 'config';
import { useCaptchaValidation } from 'hooks';

export type ChangePasswordModalProps = {
  isVisible: boolean;
  onCancel: () => void;
};

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  isVisible,
  onCancel,
}) => {
  const captchaValidation = useCaptchaValidation();
  const changePassword = useMutation(developersPortalClient.changePassword);
  const { getLabel } = useLabels();

  const handleCancelClick = useCallback(() => {
    onCancel();
    changePassword.reset();
  }, [onCancel, changePassword]);

  const handleSubmit = useCallback(
    async (values: ChangePasswordFormRequest) => {
      const { captchaCode } = await captchaValidation.validate();

      return changePassword.mutateAsync({
        currentPassword: values.currentPassword!,
        newPassword: values.newPassword!,
        captchaCode,
      });
    },
    [captchaValidation, changePassword]
  );

  return (
    <>
      <Modal size="sm" isVisible={isVisible && !changePassword.isSuccess}>
        <ChangePasswordForm onSubmit={handleSubmit} onCancel={onCancel} />
      </Modal>

      <SuccessModal isVisible={isVisible && changePassword.isSuccess}>
        <SubTitle>{getLabel('changePasswordModal.successMessage')}</SubTitle>

        <Button
          label={getLabel('general.continue')}
          variant="primary"
          onClick={handleCancelClick}
          wAuto={true}
        />
      </SuccessModal>
    </>
  );
};
