import styled from 'styled-components';

export const StyledAvatarButton = styled.button`
  border: none;
  background: none;
  gap: 0.5rem;
  color: ${props => props.theme.palette.common.white};
  font-weight: ${props => props.theme.typography.fontWeight.extraBold};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;

  > span {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > img {
    border-radius: 100%;
    overflow: hidden;
  }
`;
