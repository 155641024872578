import styled from 'styled-components';

export const NavBar = styled.nav`
  background-color: ${props => props.theme.palette.common.black};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Menu = styled.ul<{ opened: boolean }>`
  gap: 2rem;
  display: flex;
  list-style-type: none;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    display: ${props => (props.opened ? 'flex' : 'none')};
    background-color: ${props => props.theme.palette.common.black};
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 68px;
    left: 0;
    align-items: center;
    padding: 2rem;
    z-index: 10;
  }
`;

export const MobileMenuButton = styled.button<{ opened: boolean }>`
  background-color: transparent;
  border: none;
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;

  @media (min-width: ${props => props.theme.breakpoints.md}px) {
    display: none;
  }

  ::after,
  ::before {
    content: '';
    display: block;
    position: absolute;
    left: 10px;
    right: 10px;
    height: 1px;
    background-color: ${props => props.theme.palette.common.white};
    transition: transform 0.15s ease-in;
  }

  ::after {
    top: ${props => (props.opened ? '24px' : '20px')};
    transform: rotate(${props => (props.opened ? '45deg' : '0deg')});
  }

  ::before {
    bottom: ${props => (props.opened ? '24px' : '20px')};
    transform: rotate(${props => (props.opened ? '-45deg' : '0deg')});
  }
`;

export const MenuItem = styled.li``;

export const Link = styled.a`
  color: ${props => props.theme.palette.common.white};
  font-weight: ${props => props.theme.typography.fontWeight.extraBold};
  text-decoration: none;
  text-transform: uppercase;
`;
