import { object, string, SchemaOf, mixed, bool } from 'yup';

import { useLabels } from 'config';
import { validEmail } from 'shared/yup-extensions';
import { EditProfileFormValues } from './EditProfileForm.dto';

export const useEditProfileFormValidationSchema = () => {
  const { getLabel } = useLabels();

  const editProfileFormValidationSchema: SchemaOf<
    Omit<EditProfileFormValues, 'status'>
  > = object({
    uplandUsername: string()
      .nullable()
      .label(getLabel('editProfile.formLabels.uplandUsername')),
    name: string()
      .required()
      .min(3)
      .max(250)
      .required()
      .label(getLabel('editProfile.formLabels.name')),
    phone: string().required().label(getLabel('editProfile.formLabels.phone')),
    email: validEmail({
      fieldName: getLabel('editProfile.formLabels.phone'),
      getLabelByKey: getLabel,
    }).required(),
    bio: string().nullable().label(getLabel('editProfile.formLabels.bio')),
    profileUrl: string()
      .nullable()
      .url()
      .label(getLabel('editProfile.formLabels.profileUrl')),
    image: mixed().required(),
    imageUpdated: bool(),
  });

  return editProfileFormValidationSchema;
};
