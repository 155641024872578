import styled from 'styled-components';

import { StyledButton } from 'components/Button/Button.style';

export const CopyTextButtonWrapper = styled.div`
  border: 1px solid ${props => props.theme.palette.common.gray.midGray};
  display: flex;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  border-radius: 36px;
  position: relative;
  gap: 1rem;

  input {
    flex: 1;
    border: none;
    background-color: transparent;
    text-overflow: ellipsis;
  }
`;

export const CopyTextStyledButton = styled(StyledButton)`
  width: auto;
  font-size: 0.8rem;
`;

export const CopiedFeedback = styled.div<{ isVisible: boolean }>`
  position: absolute;
  right: 8px;
  top: 50px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-weight: ${props => props.theme.typography.fontWeight.bold};
  color: ${props => props.theme.palette.common.white};
  background: ${props => props.theme.palette.common.black};
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  font-weight: normal;
  font-size: 0.8rem;
  transform: rotateY(${props => (props.isVisible ? '0' : '90deg')});
  transition: opacity 150ms ease-in-out;
  opacity: ${props => (props.isVisible ? 1 : 0)};
`;
