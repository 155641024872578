import { Form, Formik, FormikHelpers } from 'formik';

import { Button, Checkbox, InputText } from 'components';
import { useLabels } from 'config';
import { useLoginRequestSchema } from './LoginRequest.validation';
import { LoginRequest } from './LoginRequest.dto';
import { FormButtonActions, FormRow } from '../Common.style';
import { useNavigation } from 'hooks';
import { RememberPasswordContainer } from './LoginForm.style';

export type LoginFormProps = {
  initialValues: LoginRequest;
  onSubmit(values: LoginRequest): Promise<unknown>;
  onResetPassword(): void;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  initialValues,
  onSubmit,
  onResetPassword,
}) => {
  const { getLabel } = useLabels();
  const { navigate } = useNavigation();
  const schemaValidation = useLoginRequestSchema();

  const handleSubmit = async (
    request: LoginRequest,
    formikHelpers: FormikHelpers<LoginRequest>
  ) => {
    try {
      formikHelpers.setSubmitting(true);

      await onSubmit(request);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      validationSchema={schemaValidation}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <FormRow size={50}>
            <InputText
              name="email"
              placeholder={`${getLabel('login.formLabels.email')} *`}
            />
          </FormRow>
          <FormRow size={50}>
            <InputText
              name="password"
              type="password"
              placeholder={`${getLabel('login.formLabels.password')} *`}
            />
          </FormRow>
          <FormRow size={50}>
            <RememberPasswordContainer>
              <Checkbox
                name="rememberMe"
                label={getLabel('login.formLabels.rememberMe')}
              />
              <Button
                variant="link"
                type="button"
                label={getLabel('forgotPassword.title')}
                onClick={onResetPassword}
              />
            </RememberPasswordContainer>
          </FormRow>

          <FormButtonActions>
            <Button
              disabled={!isValid}
              loading={isSubmitting}
              type="submit"
              label={getLabel('login.btnLogin')}
            />
            <Button
              variant="link"
              type="button"
              label={getLabel('login.btnRegister')}
              onClick={() => navigate('signup')}
            />
          </FormButtonActions>
        </Form>
      )}
    </Formik>
  );
};
