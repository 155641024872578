import { useEffect, useRef } from 'react';
import { unlock, lock } from 'tua-body-scroll-lock';

import { theme } from 'config';

export const BODY_SELECTOR = 'body';

export function useScrollControl(
  controlValue: boolean,
  options?: { onlyOnMobile: boolean }
) {
  const body = useRef(document.querySelector(BODY_SELECTOR));

  useEffect(() => () => unlock(body.current), []);

  useEffect(() => {
    if (!body.current) {
      return;
    }

    if (options) {
      controlValue && window.innerWidth <= theme.breakpoints.sm
        ? setTimeout(() => lock(body.current), 500)
        : unlock(body.current);
    } else {
      controlValue
        ? setTimeout(() => lock(body.current), 500)
        : unlock(body.current);
    }
  }, [body, options, controlValue]);
}
