import { Variant } from 'shared';
import { StyledButton } from './Button.style';
import loadingIcon from 'assets/images/loading.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faGears,
  faPlus,
  faRemove,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';

export type ButtonSize = 'sm' | 'md';
export type IconName = 'search' | 'gears' | 'add' | 'remove';

export type ButtonProps = {
  label?: string;
  icon?: IconName;
  onClick?: () => void;
  variant?: Variant;
  size?: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit';
  uppercase?: boolean;
  wAuto?: boolean;
  width?: string;
};

export const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  type = 'button',
  size = 'md',
  icon,
  loading,
  disabled,
  uppercase = true,
  wAuto = false,
  width = '100%',
  ...props
}) => {
  const iconComponent = useMemo(() => {
    switch (icon) {
      case 'search':
        return faSearch;
      case 'gears':
        return faGears;
      case 'add':
        return faPlus;
      case 'remove':
        return faRemove;

      default:
        return null;
    }
  }, [icon]);

  return (
    <StyledButton
      disabled={!!loading || disabled}
      type={type}
      variant={variant}
      uppercase={uppercase}
      size={size}
      wAuto={wAuto}
      width={width}
      {...props}
    >
      {!!loading ? (
        <img width="25" src={loadingIcon} alt="Loading" />
      ) : (
        <>
          {!!iconComponent && (
            <FontAwesomeIcon
              icon={iconComponent}
              size="lg"
              style={{ marginRight: !!props.label ? '10px' : 0 }}
            />
          )}
          {props.label}
        </>
      )}
    </StyledButton>
  );
};
