import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Button, Container, Paragraph, Title } from 'components';
import { developersPortalClient } from 'http-clients';
import { useLabels } from 'config';
import { useNavigation } from 'hooks';
import {
  LoadingWrapper,
  Oops,
  OopsParagraph,
  Wrapper,
} from './ValidateAccount.style';

import successIcon from 'assets/images/success.svg';
import loadingIcon from 'assets/images/loading.svg';

export const ValidateAccount: React.FC = () => {
  const { getLabel } = useLabels();
  const { navigate } = useNavigation();
  const { token } = useParams();
  const validateAccount = useMutation(developersPortalClient.validateAccount);

  useEffect(() => {
    if (!!token && validateAccount.isIdle) {
      validateAccount.mutate(token);
    }
  }, [token, validateAccount]);

  if (validateAccount.isLoading) {
    return (
      <LoadingWrapper>
        <img width="50" src={loadingIcon} alt="Loading" />
        {getLabel('validateAccount.loadingText')}
      </LoadingWrapper>
    );
  }

  if (validateAccount.isSuccess && validateAccount.data?.validToken) {
    return (
      <Container size="md">
        <Wrapper>
          <div>
            <img src={successIcon} alt="success" />
          </div>
          <Title>{getLabel('validateAccount.emailVerifiedTitle')}</Title>

          <Paragraph>
            {getLabel('validateAccount.emailVerifiedContent')}
          </Paragraph>

          <Button
            onClick={() => navigate('login')}
            label={getLabel('general.login')}
            wAuto={true}
          />
        </Wrapper>
      </Container>
    );
  }

  return (
    <Container size="md">
      <Wrapper>
        <Oops>Oops...</Oops>

        <OopsParagraph>{getLabel('validateAccount.oopsText')}</OopsParagraph>

        <Button
          onClick={() => navigate('login')}
          label={getLabel('general.login')}
          wAuto={true}
        />
      </Wrapper>
    </Container>
  );
};
