import { ReactNode } from 'react';

import { StyledParagraph } from './Paragraph.style';

export type ParagraphProps = {
  children: ReactNode;
  noMargin?: boolean;
};

export const Paragraph: React.FC<ParagraphProps> = ({ children, noMargin }) => (
  <StyledParagraph noMargin={noMargin}>{children}</StyledParagraph>
);
