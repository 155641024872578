import styled from 'styled-components';
import { theme, useLabels } from 'config';
import { UplandBrandImage } from 'assets/images';
import {
  ENVIRONMENT,
  Environments,
  SANDBOX_BANNER_HEIGHT,
} from 'config/constants';

export const SandboxBanner = () => {
  const { getLabel } = useLabels();

  if (ENVIRONMENT !== Environments.SANDBOX) {
    return null;
  }

  return (
    <StyledSandboxBanner zIndex={1}>
      <UplandBrandImage
        width={114}
        height={24}
        fill={theme.palette.common.white}
      />
      <Text>{getLabel('components.sandboxBanner.title')}</Text>
    </StyledSandboxBanner>
  );
};

const StyledSandboxBanner = styled.div<{ zIndex: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: ${SANDBOX_BANNER_HEIGHT};
  background-color: ${props => props.theme.palette.common.red.default};
  color: ${props => props.theme.palette.common.white};
  z-index: ${p => p.zIndex};
  padding: 0 11px;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    justify-content: flex-start;
    gap: 127px;
  }
`;

const Text = styled.h3`
  font-weight: ${props => props.theme.typography.fontWeight.semiBold};
  font-size: 13px;
  color: inherit;
  text-transform: uppercase;
`;
