import styled from 'styled-components';

export const StyledValidateAccountToolbar = styled.nav`
  background-color: ${props => props.theme.palette.common.blue.default};
`;

export const Content = styled.div`
  max-width: ${props => props.theme.breakpoints.lg}px;
  padding: 1rem;
  margin: 0 auto;
  display: flex;
  color: #fff;
  gap: 1rem;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    gap: 2rem;
  }

  button {
    background-color: ${props => props.theme.palette.common.white}30;
    color: ${props => props.theme.palette.common.white};
    font-size: 0.8rem;
    height: 35px;
    padding: 0 0.5rem;
  }
`;
