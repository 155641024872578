import { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { v4 as generateUuid } from 'uuid';
import { useLabels } from 'config';
import { SubTitle, Button, InputText, FlexBox, Paragraph } from 'components';
import { FormButtonActions } from '../Common.style';
import { Wrapper } from 'components/FormFields/Common.style';

export type EditWebhookHeadersFormProps = {
  initialValues: Record<string, string>;
  onSubmit: (values: Record<string, string>) => void;
  onCancel: () => void;
};

type CustomHeaderParams = {
  headers: {
    id: string;
    key: string;
    value: string;
  }[];
};

export const EditWebhookHeadersForm: React.FC<EditWebhookHeadersFormProps> = ({
  initialValues,
  onSubmit,
  onCancel,
}) => {
  const { getLabel } = useLabels();

  const formData: CustomHeaderParams = useMemo(
    () => ({
      headers: Object.keys(initialValues).map(key => ({
        key,
        value: initialValues[key],
        id: generateUuid(),
      })),
    }),
    [initialValues]
  );

  const handleSubmit = useCallback(
    (values: CustomHeaderParams) =>
      onSubmit(
        values.headers.reduce(
          (obj, item) => ({
            [item.key]: item.value,
            ...obj,
          }),
          {}
        )
      ),
    [onSubmit]
  );

  const addItem = useCallback(
    (
      values: CustomHeaderParams,
      setFieldValue: (field: string, value: any) => void
    ) => setFieldValue('headers', [...values.headers, { id: generateUuid() }]),
    []
  );

  const removeItem = useCallback(
    (
      index: number,
      values: CustomHeaderParams,
      setFieldValue: (field: string, value: any) => void
    ) => {
      values.headers.splice(index, 1);
      setFieldValue('headers', [...values.headers]);
    },
    []
  );

  return (
    <Formik initialValues={formData} onSubmit={handleSubmit}>
      {({ isValid, isSubmitting, values, setFieldValue }) => (
        <Form>
          <SubTitle>{getLabel('editWebhookHeadersForm.title')}</SubTitle>
          <Paragraph>
            {getLabel('editWebhookHeadersForm.description')}
          </Paragraph>

          <FlexBox mb={2} flexDirection="row" justifyContent="flex-start">
            <div>
              <Button
                type="button"
                icon="add"
                label={getLabel('editWebhookHeadersForm.addParameter')}
                size="sm"
                disabled={values.headers.length >= 5}
                onClick={() => addItem(values, setFieldValue)}
              />
            </div>
          </FlexBox>

          {values.headers.map((parameter, index) => (
            <FlexBox key={parameter.id} flexDirection="row" aligItems="center">
              <FlexBox
                flexDirection="column"
                md={{ flexDirection: 'row', flex: 1, gap: 1 }}
              >
                <InputText
                  maxLength={2000}
                  name={`headers[${index}].key`}
                  type="text"
                  placeholder={getLabel(
                    'editWebhookHeadersForm.keyPlaceholder'
                  )}
                />
                <InputText
                  maxLength={2000}
                  name={`headers[${index}].value`}
                  type="text"
                  placeholder={getLabel(
                    'editWebhookHeadersForm.valuePlaceholder'
                  )}
                />
              </FlexBox>

              <Wrapper>
                <Button
                  type="button"
                  icon="remove"
                  variant="link"
                  width="50px"
                  onClick={() => removeItem(index, values, setFieldValue)}
                />
              </Wrapper>
            </FlexBox>
          ))}

          <FormButtonActions mt={2} mb={0}>
            <Button
              disabled={!isValid}
              loading={isSubmitting}
              type="submit"
              label={getLabel('general.continue')}
            />
            <Button
              variant="secondary"
              type="button"
              onClick={onCancel}
              label={getLabel('general.cancel')}
              uppercase={false}
            />
          </FormButtonActions>
        </Form>
      )}
    </Formik>
  );
};
