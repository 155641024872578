import { useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { Alert, Button, InputText, Link, SubTitle } from 'components';
import { useLabels } from 'config';
import { UplandUsernameFormValues } from './UplandUsernameForm.dto';
import { useUplandUsernameFormValidationSchema } from './UplandUsernameForm.validation';
import { FormBody, FormRow, FormButtonActions } from '../Common.style';
import { FormValidationException } from 'common/exceptions';
import { DevelopersPortal } from 'http-clients';
import { Trans } from 'react-i18next';
import {
  ENVIRONMENT,
  Environments,
  SANDBOX_APPLICATION_URL,
} from 'config/constants';

export type UplandUsernameFormOnSubmitProps = {
  values: UplandUsernameFormValues;
};

export type UplandUsernameFormProps = {
  initialValues: UplandUsernameFormValues;
  onSubmit(values: UplandUsernameFormOnSubmitProps): Promise<unknown>;
  onCancel(): void;
};

export const UplandUsernameForm: React.FC<UplandUsernameFormProps> = ({
  initialValues,
  onSubmit,
  onCancel,
}) => {
  const { getLabel } = useLabels();
  const validationSchema = useUplandUsernameFormValidationSchema();

  const handleSubmit = useCallback(
    async (
      values: UplandUsernameFormValues,
      formikHelpers: FormikHelpers<UplandUsernameFormValues>
    ) => {
      try {
        formikHelpers.setSubmitting(true);

        await onSubmit({ values });
      } catch (error) {
        if (error instanceof FormValidationException) {
          const { errors }: FormValidationException = error;

          formikHelpers.setErrors(errors);
        }

        if (error instanceof DevelopersPortal.ServerError) {
          const { message }: DevelopersPortal.ServerError = error;

          formikHelpers.setFieldError('uplandUsername', message);
        }
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={!!initialValues.uplandUsername}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <FormBody>
            <SubTitle
              mb={ENVIRONMENT === Environments.SANDBOX ? 1.5 : undefined}
            >
              {getLabel('validateUplandAccountFlow.uplandUsernameFormTitle')}
            </SubTitle>
            {ENVIRONMENT === Environments.SANDBOX && (
              <Alert type="warning">
                <Trans
                  i18nKey={'validateUplandAccountFlow.sandboxInfo'}
                  components={[
                    <Link
                      href={SANDBOX_APPLICATION_URL}
                      target="_blank"
                      rel="noreferrer"
                    />,
                  ]}
                />
              </Alert>
            )}

            <FormRow mb={1}>
              <InputText
                name="uplandUsername"
                placeholder={`${getLabel(
                  'validateUplandAccountFlow.formLabels.uplandUsername'
                )}`}
              />
            </FormRow>

            <FormButtonActions mb={0}>
              <Button
                disabled={!isValid}
                loading={isSubmitting}
                type="submit"
                label={getLabel('buttons.continue')}
              />

              <Button
                variant="secondary"
                type="button"
                label={getLabel('buttons.cancel')}
                onClick={onCancel}
              />
            </FormButtonActions>
          </FormBody>
        </Form>
      )}
    </Formik>
  );
};
