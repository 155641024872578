import styled from 'styled-components';

import { ScreenSize } from 'shared';

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: ${props => props.theme.palette.common.white}99;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  overflow: auto;
`;

export const StyledModal = styled.div<{ size: ScreenSize; width?: number }>`
  flex: 1;
  max-width: ${props => props.width ?? props.theme.breakpoints[props.size]}px;
  margin: 2rem;
  background-color: #fdfcfc;
  border-radius: 25px;
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: 0 0 25px ${props => props.theme.palette.common.black}25;
`;

export const CustomModalWrapper = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledSandboxCredentialModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.2rem;

    label {
      font-weight: ${props => props.theme.typography.fontWeight.extraBold};
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
  }

  button {
    align-self: center;
  }
`;

export const CopySecretKeyWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  margin-bottom: 2rem;

  label {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
`;
