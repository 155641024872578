import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { theme, loadTranslation } from 'config';
import {
  RootContainer,
  Header,
  ValidateAccountToolbar,
  SandboxBanner,
  SandboxWrapper,
} from 'components';
import { routes, PrivateRoute } from 'config/routes';
import {
  AuthenticatedUserProvider,
  UplandAccountVerificationProvider,
  ModalBuilderProvider,
} from 'hooks';
import { NotFound } from 'scenes';
import { ENVIRONMENT, Environments, SENTRY_DNS } from 'config/constants';

if (ENVIRONMENT !== Environments.LOCAL) {
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [new BrowserTracing()],
    environment: ENVIRONMENT,
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient();

queryClient.setDefaultOptions({
  queries: {
    cacheTime: 0,
  },
});

loadTranslation();

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <ModalBuilderProvider>
      <QueryClientProvider client={queryClient}>
        <AuthenticatedUserProvider>
          <UplandAccountVerificationProvider>
            <RootContainer>
              <SandboxWrapper>
                <Header />
                <ValidateAccountToolbar />
                <Routes>
                  {routes.map(route => (
                    <Route
                      key={route.name}
                      path={route.path}
                      element={
                        <PrivateRoute isPrivate={route.isPrivate}>
                          {route.scene}
                        </PrivateRoute>
                      }
                    />
                  ))}
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <SandboxBanner />
              </SandboxWrapper>
            </RootContainer>
          </UplandAccountVerificationProvider>
        </AuthenticatedUserProvider>
      </QueryClientProvider>
    </ModalBuilderProvider>
  </ThemeProvider>
);

export default App;
