import styled from 'styled-components';

export type FixedWidthBoxProps = {
  width: number;
  ignoreMobile?: boolean;
};

export const FixedWidthBox = styled.div<FixedWidthBoxProps>`
  width: ${props => `${props.width}px`};

  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    width: ${props => (props.ignoreMobile ? '100%' : `${props.width}px`)};
  }

  button {
    min-width: 0;
    width: 100%;
  }
`;
