import { Area, Size } from 'react-easy-crop';
import { Image as ImageJs } from 'image-js';

import { ContentType, Maybe } from 'shared';
import { AllowedFileType } from './CropImageModal.enums';

export async function getCroppedImg(
  imageSrc: string,
  cropArea: Area,
  squareSize: number,
  originalImageSize: Size
): Promise<Maybe<Blob>> {
  let image = await ImageJs.load(imageSrc);

  // dimension checking to handle take picture feature on IOS
  if (image.width !== originalImageSize.width) {
    image = image.rotateRight();
  }

  return image
    .crop({ ...cropArea })
    .resize({ width: squareSize, height: squareSize })
    .toBlob(ContentType.IMAGE_PNG);
}

export async function rotateImg(image: Blob) {
  return (await ImageJs.load(await image.arrayBuffer()))
    .rotateRight()
    .toBlob(ContentType.IMAGE_PNG);
}

export function createImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
}

export function readFile(file: File): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => resolve(reader.result as string),
      false
    );
    reader.readAsDataURL(file);
  });
}

export function getAllowedFileTypes() {
  return [AllowedFileType.JPEG, AllowedFileType.PNG];
}

export function isValidImage(file: File) {
  return getAllowedFileTypes()
    .map(type => type.toString())
    .includes(file.type);
}
