import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

export const MIN_ZOOM = 1;
export const MAX_ZOOM = 3;

const MULTIPLIER_FACTOR = 10;

export type ZoomSliderProps = {
  value: number;
  onChange(value: number): void;
};

export const ZoomSlider: React.FC<ZoomSliderProps> = ({ value, onChange }) => (
  <Slider
    min={MIN_ZOOM * MULTIPLIER_FACTOR}
    max={MAX_ZOOM * MULTIPLIER_FACTOR}
    value={value * MULTIPLIER_FACTOR}
    onChange={selected => {
      if (!Array.isArray(selected)) {
        onChange(selected / MULTIPLIER_FACTOR);
      }
    }}
  />
);
