import {
  Environments,
  ENVIRONMENT,
  SANDBOX_BANNER_HEIGHT,
} from 'config/constants';
import styled from 'styled-components';

export const RootContainer = styled.div`
  min-height: 100vh;
`;

export const SandboxWrapper = styled.div`
  padding-bottom: ${() =>
    ENVIRONMENT === Environments.SANDBOX ? SANDBOX_BANNER_HEIGHT : '0px'};
`;
