import { WebhookCallsSearchParams } from 'components';
import { VerificationState } from 'hooks';
import {
  AllowedDevices,
  AppStatus,
  FeeCalculationType,
  HttpStatus,
  Maybe,
  PageResult,
  RegistrationStatus,
  Uuid,
} from 'shared';

export namespace DevelopersPortal {
  export enum Errors {
    ACTION_NOT_ALLOWED_TO_DEVELOPER_STATUS = 'ActionNotAllowedToDeveloperStatus',
  }

  export type EditDeveloperRequest = {
    verificationCode: string;
    name: string;
    phone: string;
    bio: Maybe<string>;
    uplandUsername: Maybe<string>;
    profileUrl: Maybe<string>;
    profileFileContentLength: Maybe<number>;
  };

  export type CreateDeveloperRequest = {
    name: string;
    email: string;
    password: string;
    phone: string;
    captchaCode: string;
    uplandUsername: Maybe<string>;
    bio: Maybe<string>;
    profileUrl: Maybe<string>;
    profileFileContentLength: Maybe<number>;
  };

  export type CreateDeveloperResponse = {
    uploadUrl: string;
  };

  export type LoginRequest = {
    email: string;
    password: string;
    captchaCode: string;
  };

  export type ResendRegistrationConfirmationRequest = {
    email: string;
  };

  export type AuthenticatedUser = {
    id: Uuid;
    name: string;
    phone: string;
    email: string;
    status: RegistrationStatus;
    uplandUsername: Maybe<string>;
    profilePictureUrl: Maybe<string>;
    bio: Maybe<string>;
    profileUrl: Maybe<string>;
    verificationState: VerificationState;
  };

  export type LoginResponse = {
    token: string;
    is2FAEnabled: boolean;
  };

  export type App = {
    id: number;
    name: string;
    slug: string;
    expirationTimeInHours: number;
    status: AppStatus;
    webhookUrl?: string;
    webhookCustomHeader?: Record<string, string>;
    description: Maybe<string>;
    pictureUrl: Maybe<string>;
    allowedDevices: Array<AllowedDevices>;
    require3DAvatar: boolean;
    requiresPresence: boolean;
    feeCalculationType: FeeCalculationType;
  };

  export type FindAppsResponse = {
    apps: App[];
  };

  export type FindAppById = {
    id: number;
    name: string;
    slug: string;
    expirationTimeInHours: number;
    status: AppStatus;
    webhookUrl?: string;
    webhookCustomHeader?: Record<string, string>;
    description: Maybe<string>;
    allowedDevices: Array<AllowedDevices>;
    image: Blob;
    require3DAvatar: boolean;
    requiresPresence: boolean;
    feeCalculationType: FeeCalculationType;
  };

  export type AppBaseInformation = {
    name: string;
    description: Maybe<string>;
    expirationTimeInHours: number;
    webhookUrl?: string;
    webhookCustomHeader?: Record<string, string>;
    imageFileContentLength: Maybe<number>;
    allowedDevices: Array<AllowedDevices>;
    require3DAvatar: boolean;
    requiresPresence: boolean;
    feeCalculationType: FeeCalculationType;
  };

  export type CreateAppRequest = AppBaseInformation;

  export type EditAppRequest = {
    id: number;
    verificationCode: string;
    appInformation: AppBaseInformation;
  };

  export type CreateAppResponse = {
    id: number;
    secretKey: string;
    uploadUrl: string;
  };

  export type FieldError = {
    property: string;
    constraints: Record<string, string>;
  };

  export type ValidationErrors = {
    error: string;
    message: FieldError[] | string;
    statusCode: number;
  };

  export type ValidateTokenResponse = {
    validToken: boolean;
  };

  export type ValidateVerificationCodeRequest = {
    verificationCode: string;
    shortTimeToken: string;
  };

  export type ValidateVerificationCodeResponse = {
    token: string;
  };

  export type ResendVerificationCodeRequest = {
    shortTimeToken: string;
  };

  export class ServerError extends Error {
    constructor(
      error: Error,
      readonly statusCode: number,
      readonly description?: string
    ) {
      super(error.message);
    }

    isDeveloperNameMustBeUniqueError = () =>
      this.statusCode === HttpStatus.UNPROCESSABLE_ENTITY &&
      this.description === 'developers_name_uk';

    isDeveloperPhoneMustBeUniqueError = () =>
      this.statusCode === HttpStatus.UNPROCESSABLE_ENTITY &&
      this.description === 'developers_phone_uk';
  }

  export type EditAppResponse = {
    uploadUrl?: string;
  };

  export type GenerateVerificationCodeResponse = {
    success: boolean;
    availableAfter: Maybe<number>;
  };

  export type EditDeveloperResponse = {
    uploadUrl?: string;
  };

  export type VerifyUplandUsernameRequest = {
    uplandUsername: string;
  };

  export type VerifyCodeToConfirmUplandUsernameRequest = {
    verificationCode: string;
  };

  export type ChangePasswordRequest = {
    currentPassword: string;
    newPassword: string;
    captchaCode: string;
  };

  export type ChangeAppStatusRequest = {
    id: number;
    verificationCode: string;
    status: string;
  };

  export type ChangeAppStatusResponse = {
    secretKey?: string;
  };

  export type RequestPasswordResetRequest = {
    email: string;
    captchaCode: string;
  };

  export type RequestPasswordResponse = {
    token: string;
  };

  export type CheckResetPasswordVcpRequest = {
    token: string;
    verificationCode: string;
  };

  export type ResetPasswordRequest = {
    token: string;
    verificationCode: string;
    newPassword: string;
  };

  export type FindSandboxCredentialsResponse = {
    url: string;
    username: string;
    password: string;
  };

  export type FindWebhookCallsHistoryRequest = {
    appId: number;
    searchParams: WebhookCallsSearchParams;
    page?: {
      currentPage: number;
      pageSize: number;
    };
  };

  export type FindWebhookCallsHistoryResponse = PageResult<WebhookCall>;

  export type WebhookCall = {
    id: number;
    notificationType: string;
    appId: number;
    attemptNumber: number;
    attemptedAt: Date;
    isSuccess: boolean;
    request: object;
    response: object;
    containerId?: number;
  };

  export type ValidateWebhookRequest = {
    url: string;
    webhookCustomHeader: {
      parameters: {
        key: string;
        value: string;
      }[];
    };
  };

  export type ValidateWebhookResponse = {
    isValid: boolean;
    request: {
      body: string;
    };
    response: {
      status?: HttpStatus;
      message?: string;
    };
  };
}
