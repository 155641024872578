import styled from 'styled-components';

export const StyledResendVerificationCodeButton = styled.button<{
  disabled: boolean;
}>`
  background-color: ${props => props.theme.palette.common.black}${props => (props.disabled ? 40 : 85)};
  font-family: ${props => props.theme.palette.common.white};
  font-weight: ${props => props.theme.typography.fontWeight.semiBold};
  color: ${props => props.theme.palette.common.white};
  font-size: 0.6rem;
  border: none;
  height: 21px;
  width: 85px;
  text-transform: uppercase;
  border-radius: 20px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 150ms;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: ${props => props.theme.palette.common.black}${props => (props.disabled ? 40 : 90)};
  }
`;
