import { useCallback, useState } from 'react';
import copyToClipboard from 'copy-to-clipboard';

import {
  CopiedFeedback,
  CopyTextButtonWrapper,
  CopyTextStyledButton,
} from './CopyTextButton.style';
import { useLabels } from 'config';

export type CopyTextButtonProps = {
  content: string;
};

const FEEDBACK_LIFETIME_IN_MILISECONDS = 2000;

export const CopyTextButton: React.FC<CopyTextButtonProps> = ({ content }) => {
  const { getLabel } = useLabels();
  const [showCopiedFeedback, setShowCopiedFeedback] = useState(false);

  const showFeedback = useCallback(() => {
    setShowCopiedFeedback(true);
    setTimeout(
      () => setShowCopiedFeedback(false),
      FEEDBACK_LIFETIME_IN_MILISECONDS
    );
  }, [setShowCopiedFeedback]);

  const handleCopyClick = useCallback(() => {
    copyToClipboard(content);
    showFeedback();
  }, [content, showFeedback]);

  return (
    <CopyTextButtonWrapper>
      <input type="text" value={content} disabled={true} />
      <CopyTextStyledButton
        type="button"
        variant="primary"
        size="sm"
        uppercase={true}
        onClick={handleCopyClick}
      >
        {getLabel('components.copyTextButton.btnLabel')}
      </CopyTextStyledButton>
      <CopiedFeedback isVisible={showCopiedFeedback}>
        {getLabel('components.copyTextButton.feedbackText')}
      </CopiedFeedback>
    </CopyTextButtonWrapper>
  );
};
