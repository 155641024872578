import { useEffect, useState } from 'react';

import { Maybe } from 'shared';
import { LAST_LOGIN_STORAGE_KEY } from 'config/constants';
import { LoginRequest } from 'components/Forms';

export const useRememberLoginCredentials = () => {
  const [initialValues, setInitialValues] = useState<Maybe<LoginRequest>>();

  useEffect(() => {
    const rawRequest = localStorage.getItem(LAST_LOGIN_STORAGE_KEY);

    try {
      setInitialValues(!!rawRequest ? JSON.parse(rawRequest) : {});
    } catch {
      localStorage.removeItem(LAST_LOGIN_STORAGE_KEY);
      setInitialValues({});
    }
  }, []);

  const saveCredentials = (request: LoginRequest) => {
    localStorage.setItem(
      LAST_LOGIN_STORAGE_KEY,
      JSON.stringify({
        email: request.email,
        rememberMe: true,
      } as LoginRequest)
    );
  };

  return { initialValues, saveCredentials };
};
