import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useCallback, useState } from 'react';

import {
  Button,
  Container,
  SubTitle,
  SuccessModal,
  Title,
  EditAppForm,
  EditAppFormValues,
  Modal,
  VerificationCodeForm,
  VerificationCodeFormRequest,
  AppStatusBadge,
  PageHeader,
  LoadingBox,
  mapFormValuesToAppBaseInformation,
} from 'components';
import { useAuthentication, useNavigation } from 'hooks';
import { useLabels } from 'config';
import { ContentType, HttpStatus, Maybe } from 'shared';
import {
  DevelopersPortal,
  developersPortalClient,
  storageClient,
} from 'http-clients';
import { useFindAppById } from 'hooks/useFindAppById';

export const EditApp: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { authUser } = useAuthentication();
  const { getLabel } = useLabels();
  const { goBack } = useNavigation();
  const { app, isAppLoading } = useFindAppById(Number(id));
  const requestAppEdition = useMutation(
    developersPortalClient.requestAppEdition
  );
  const editApp = useMutation(developersPortalClient.editApp);
  const fileUploader = useMutation(storageClient.upload);
  const [tempApp, setTempApp] = useState<Maybe<EditAppFormValues>>();
  const [generalErrors, setGeneralErrors] = useState<unknown>();

  const handleEditFormSubmit = useCallback(
    async (props: EditAppFormValues) => {
      const result = await requestAppEdition.mutateAsync();
      requestAppEdition.reset();
      setTempApp(props);

      return result;
    },
    [requestAppEdition]
  );

  const handleConfirmationSubmit = useCallback(
    async (props: VerificationCodeFormRequest) => {
      try {
        const imageFileContentLength: Maybe<number> = tempApp?.imageUpdated
          ? tempApp?.image?.size
          : null;

        const { uploadUrl } = await editApp.mutateAsync({
          id: Number(id),
          appInformation: mapFormValuesToAppBaseInformation({
            ...tempApp!,
            imageFileContentLength,
          }),
          verificationCode: props.verificationCode!,
        });

        if (uploadUrl) {
          fileUploader.mutateAsync({
            uploadUrl: uploadUrl,
            file: tempApp?.image!,
            contentType: ContentType.IMAGE_PNG,
          });
        }
      } catch (error: unknown) {
        const isInvalidCode =
          error instanceof DevelopersPortal.ServerError &&
          (error as DevelopersPortal.ServerError).statusCode ===
            HttpStatus.FORBIDDEN;

        if (!isInvalidCode) {
          setTempApp(null);
          setGeneralErrors(error);
        } else {
          throw error;
        }
      }
    },
    [editApp, fileUploader, id, tempApp]
  );

  if (isAppLoading || !app) {
    return <LoadingBox />;
  }

  return (
    <>
      <Container size="md">
        <PageHeader>
          <Title mb={1}>{getLabel('editApplication.title')}</Title>
          <AppStatusBadge appStatus={app.status} />
        </PageHeader>

        <EditAppForm
          initialValues={app}
          errors={generalErrors}
          onSubmit={handleEditFormSubmit}
          onCancel={goBack}
        />
      </Container>

      <Modal size="sm" isVisible={!!tempApp && !editApp.isSuccess}>
        <SubTitle>{getLabel('editApplication.confirmEditionTitle')}</SubTitle>
        <VerificationCodeForm
          email={authUser?.email!}
          onResend={() => handleEditFormSubmit(tempApp!)}
          onSubmit={handleConfirmationSubmit}
          onCancel={() => setTempApp(null)}
        />
      </Modal>

      <SuccessModal isVisible={editApp.isSuccess}>
        <SubTitle>{getLabel('editApplication.successModal.title')}</SubTitle>
        <Button
          label={getLabel('general.continue')}
          variant="primary"
          onClick={goBack}
          wAuto={true}
        />
      </SuccessModal>
    </>
  );
};
