import { StyledAvatarButton } from './AvatarButton.style';
import { ImageLazy } from 'components';
import { Maybe } from 'shared';

import avatarIcon from 'assets/images/avatar-icon.svg';

export type AvatarButtonProps = {
  username: string;
  profilePicture: Maybe<string>;
  onClick?: () => void;
};

export const AvatarButton: React.FC<AvatarButtonProps> = ({
  username,
  profilePicture,
  onClick,
}) => {
  const firstName = username?.split(' ')[0];

  return (
    <StyledAvatarButton
      type="button"
      onClick={() => (onClick ? onClick() : null)}
    >
      <span>{firstName}</span>
      <ImageLazy
        src={profilePicture}
        defaultImageUrl={avatarIcon}
        alt={username}
        width={27}
        height={27}
      />
    </StyledAvatarButton>
  );
};
