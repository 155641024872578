import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 1.25rem;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 1rem;

  span {
    font-weight: ${props => props.theme.typography.fontWeight.regular};
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const CheckboxOption = styled.label`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  cursor: not-allowed;

  img {
    width: 11px;
    height: 11px;
    border-radius: 3px;
    padding: 5px;
    background-color: ${props => props.theme.palette.common.black};
  }
`;
