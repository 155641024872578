import styled from 'styled-components';

export const FilterButtonContainer = styled.span`
  position: relative;
`;

export const OpenFilterButton = styled.button<{ disabled: boolean }>`
  border: none;
  background-color: transparent;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.common.gray.midGray}50;
  transition: all 150ms;

  &:hover {
    background-color: ${props =>
      props.theme.palette.common.gray.midGray.concat(
        props.disabled ? '50' : '90'
      )};
  }
`;

export const FilterTitle = styled.label`
  display: block;
  font-weight: ${props => props.theme.typography.fontWeight.extraBold};
  font-size: 0.825rem;
  text-transform: uppercase;
`;

export const FilterBox = styled.div`
  position: absolute;
  border-radius: 25px;
  box-shadow: 0 0 1rem ${props => props.theme.palette.common.gray.standard}60;
  background-color: ${props => props.theme.palette.common.white};
  display: flex;
  flex-direction: column;
  top: 60px;
  right: 0;
  z-index: 1;
  width: 250px;
  padding: 1.25rem;
`;

export const OptionsList = styled.div`
  margin: 0.5rem 0 0.75rem 0;
`;

export const Option = styled.label<{ checked: boolean }>`
  padding: 0.275rem 0;
  border: none;
  background-color: transparent;
  text-align: left;
  font-weight: ${props => props.theme.typography.fontWeight.semiBold};
  color: ${props => props.theme.palette.common.gray.standard};
  font-size: 0.825rem;
  cursor: pointer;
  transition: all 200ms;
  display: flex;
  gap: 0.275rem;
  position: relative;
  align-items: center;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
  }

  &::before {
    content: '';
    height: 12px;
    width: 12px;
    background-color: ${props =>
      props.checked ? props.theme.palette.common.black : 'transparent'};
    border-radius: 50%;
    box-shadow: 0 0 0 2px
      ${props =>
        props.checked
          ? props.theme.palette.common.black
          : props.theme.palette.common.gray.standard};
    margin-right: 0.25rem;
    transition: all 150ms;
  }
`;
