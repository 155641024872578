import Axios from 'axios';

import { Storage } from './dtos';

const axios = Axios.create();

export const storageClient = {
  upload(request: Storage.UploadFileRequest) {
    const { uploadUrl, file, contentType } = request;
    const headers = {
      'Content-Type': contentType,
    };

    return axios.put(uploadUrl, file, { headers });
  },
};
