import { ReactNode } from 'react';

import { useScrollControl } from 'hooks';

import { ScreenSize } from 'shared';
import { ModalContainer, StyledModal } from './Modal.style';

export type ModalProps = {
  children: ReactNode;
  isVisible: boolean;
  size: ScreenSize;
};

export const Modal: React.FC<ModalProps> = ({ children, size, isVisible }) => {
  useScrollControl(isVisible);

  if (!isVisible) {
    return null;
  }

  return (
    <ModalContainer>
      <StyledModal size={size}>{children}</StyledModal>
    </ModalContainer>
  );
};
