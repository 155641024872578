import { useEffect, useState } from 'react';
import { Maybe } from 'shared';

export type ImageLazyProps = {
  src: Maybe<string>;
  defaultImageUrl: string;
  alt: string;
  width?: number;
  height?: number;
};

export const ImageLazy: React.FC<ImageLazyProps> = ({
  src,
  defaultImageUrl,
  alt,
  ...props
}) => {
  const [url, setUrl] = useState(defaultImageUrl);

  useEffect(() => {
    if (!!src) {
      const image = new Image();
      image.addEventListener('load', () => setUrl(src));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = src;
    }
  }, [src]);

  return <img src={url} alt={alt} {...props} />;
};
