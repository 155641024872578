import styled from 'styled-components';

type FormRowSize = 100 | 50 | 60;

export const FormBody = styled.div`
  text-align: left;
`;

export const FormRow = styled.div<{ size?: FormRowSize; mb?: number }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.mb ?? 0}rem;

  > * {
    flex: 1;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    gap: 1rem;
    flex-direction: row;
    width: ${props => props.size ?? 100}%;
  }
`;

export const FormButtonActions = styled.div<{ mt?: number; mb?: number }>`
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.mt ? `${props.mt}rem` : 0)};
  margin-bottom: 2rem 0 ${props => props.mb ?? 1}rem 0;
  gap: 1rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    flex-direction: row;
    margin-bottom: ${props => props.mb ?? 2}rem;

    button {
      width: auto;
      min-width: 250px;
    }
  }
`;

export const SearchFormRow = styled.div<{ size?: FormRowSize; mb?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${props => props.mb ?? 0}rem;
  gap: 1rem;

  .flex-1 {
    flex: 1;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    flex-direction: row;
  }

  .radio-group {
    display: flex;
    flex-direction: row;
    margin: 0;
    gap: 1rem;
    align-items: center;
    flex: 1;

    & > div {
      display: flex;
      flex-direction: row;
      padding: 0;
      gap: 1.5rem;
    }
  }
`;
