import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  ActionsContainer,
  AppCard,
  AppContent,
  AppId,
  AppListContainer,
  AppPicture,
} from './AppList.style';
import { DropdownButton } from 'components';

import 'react-loading-skeleton/dist/skeleton.css';

export type AppListSkeletonProps = {
  appsAmount: number;
};

export const AppListSkeleton: React.FC<AppListSkeletonProps> = ({
  appsAmount,
}) => {
  const keys = useMemo(
    () => Array.from(Array(appsAmount).keys()),
    [appsAmount]
  );

  return (
    <AppListContainer>
      {keys.map((key: number) => (
        <AppCard key={key}>
          <AppPicture>
            <Skeleton width={300} height={300} />
          </AppPicture>
          <AppContent>
            <Skeleton width={250} />
            <AppId>
              <Skeleton width={150} />
            </AppId>
          </AppContent>
          <ActionsContainer>
            <DropdownButton options={[]} disabled={true} />
          </ActionsContainer>
        </AppCard>
      ))}
    </AppListContainer>
  );
};
