import { object, SchemaOf } from 'yup';

import { useLabels } from 'config';
import { password, samePassword } from 'shared/yup-extensions';
import { ResetPasswordFormRequest } from './ResetPasswordForm.dto';

export const useResetPasswordFormRequestSchema = () => {
  const { getLabel } = useLabels();

  const resetPasswordFormRequestSchema: SchemaOf<ResetPasswordFormRequest> =
    object({
      newPassword: password().label(
        getLabel('changePasswordModal.formLabels.newPassword')
      ),
      passwordConfirmation: samePassword(
        'newPassword',
        getLabel('general.passwordShouldBeTheSame')
      ).label(getLabel('changePasswordModal.formLabels.passwordConfirmation')),
    });

  return resetPasswordFormRequestSchema;
};
