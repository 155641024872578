import { object, SchemaOf, string } from 'yup';

import { useLabels } from 'config';
import { VERIFICATION_CODE_SIZE } from 'config/constants';
import { VerificationCodeFormRequest } from './VerificationCodeForm.dto';

export const useVerificationCodeFormSchema = () => {
  const { getLabel } = useLabels();

  const verificationCodeFormSchema: SchemaOf<VerificationCodeFormRequest> =
    object({
      verificationCode: string()
        .length(VERIFICATION_CODE_SIZE)
        .required()
        .label(getLabel('verificationCodeForm.formLabels.code')),
    });

  return verificationCodeFormSchema;
};
