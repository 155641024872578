import { useRef } from 'react';
import uniqueId from 'lodash/uniqueId';

/**
 * Custom React hook for generating unique IDs with an optional prefix.
 *
 * @param prefix - An optional prefix to prepend to the generated unique ID.
 * @returns A string representing a unique ID, which is generated based on the given prefix.
 */
export const useUniqueId = (prefix?: string) => {
  const uniqueIdRef = useRef(uniqueId(`${prefix}-`));

  return uniqueIdRef.current;
};
