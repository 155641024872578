import { useQuery } from 'react-query';
import { CacheKeys } from 'shared';
import { developersPortalClient } from 'http-clients';

export const useFindAppById = (appId: number) => {
  const { data, isLoading } = useQuery(
    [CacheKeys.FIND_APP_BY_ID, appId],
    () => developersPortalClient.findAppById(appId),
    { cacheTime: 0 }
  );

  return {
    app: data,
    isAppLoading: isLoading,
  };
};
