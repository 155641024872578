export class FormValidationException extends Error {
  readonly name: string;

  constructor(
    readonly message: string,
    readonly errors: Record<string, string[]>
  ) {
    super();
    this.name = 'FormValidationException';
  }
}
