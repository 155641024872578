import { useField } from 'formik';
import Slider from 'rc-slider';

import { SliderLabel, SliderWrapper } from './SliderField.style';

import 'rc-slider/assets/index.css';
import { HelpText } from '../Common.style';

export type SliderFieldProps = {
  getLabel(value: number): string;
  name: string;
  minValue: number;
  maxValue: number;
  helpText?: string;
};

export const SliderField: React.FC<SliderFieldProps> = ({
  getLabel,
  minValue,
  maxValue,
  name,
  helpText,
}) => {
  const [field, , helper] = useField(name);

  return (
    <SliderWrapper>
      <SliderLabel>{getLabel(field.value)}</SliderLabel>
      <Slider
        min={minValue}
        max={maxValue}
        value={field.value}
        onChange={selected => {
          if (!Array.isArray(selected)) {
            helper.setValue(selected);
          }
        }}
      />
      {!!helpText && <HelpText>{helpText}</HelpText>}
    </SliderWrapper>
  );
};
