import { ref, string } from 'yup';

import { TranslationKeys } from 'config';

export const password = () => string().min(8).max(50).required();

export const samePassword = (fieldName: string, errorMessage: string) =>
  string()
    .required()
    .oneOf([ref(fieldName), null], errorMessage);

type ValidEmailParams = {
  fieldName: string;
  getLabelByKey: (
    messageKey: TranslationKeys,
    options?: Record<string, any>
  ) => string;
  maxLength?: number;
};

const ALLOWED_CHARACTERS_IN_EMAIL_PATTERN = /^$|^[\w\s!#$%&'*/=?^_+-`{|}~]+$/;

export const validEmail = ({
  fieldName,
  getLabelByKey,
  maxLength = 250,
}: ValidEmailParams) =>
  string()
    .email()
    .max(maxLength)
    .matches(
      ALLOWED_CHARACTERS_IN_EMAIL_PATTERN,
      getLabelByKey('validation.mustBeValidEmail', { fieldName })
    )
    .label(fieldName);
