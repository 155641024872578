import { Maybe, RegistrationStatus, Uuid } from 'shared';

export type AuthenticatedUser = {
  id: Uuid;
  name: string;
  email: string;
  phone: string;
  status: RegistrationStatus;
  uplandUsername: Maybe<string>;
  profilePictureUrl: Maybe<string>;
  verificationState: VerificationState;
};

export type VerificationState = {
  kycStatus: KycStatus;
};

export enum KycStatus {
  UNKNOWN = 'unknown',
  REJECTED = 'rejected',
  PENDING = 'pending',
  SUCCESS = 'success',
  BLOCKED = 'blocked',
}

export type AuthContextType = {
  authUser: Maybe<AuthenticatedUser>;
  isLogged: boolean;
  isAbleToCreateApps: boolean;
  isAbleToSeeSandboxCredentials: boolean;
  login(accessToken: string): Promise<void>;
  logout(): void;
  reloadAuthUserData(): void;
};
