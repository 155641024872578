import { useMemo } from 'react';

import { TranslationKeys, useLabels } from 'config';
import { Badge } from './Badge';
import { BadgeColor } from './Badge.style';
import { AppStatus, Maybe } from 'shared';

export type AppStatusBadgeProps = {
  appStatus?: Maybe<AppStatus>;
};

export const AppStatusBadge: React.FC<AppStatusBadgeProps> = ({
  appStatus,
}) => {
  const { getLabel } = useLabels();

  const [color, labelKey, placeHolderKey]: [
    BadgeColor,
    TranslationKeys,
    TranslationKeys
  ] = useMemo(() => {
    const statuses: Record<
      Partial<AppStatus>,
      [BadgeColor, TranslationKeys, TranslationKeys]
    > = {
      [AppStatus.ACTIVE]: [
        'blue',
        'badges.appStatus.active',
        'badges.appStatus.placeholder.active',
      ],
      [AppStatus.INACTIVE]: [
        'red',
        'badges.appStatus.inactive',
        'badges.appStatus.placeholder.inactive',
      ],
      [AppStatus.DISABLED]: [
        'red',
        'badges.appStatus.disabled',
        'badges.appStatus.placeholder.disabled',
      ],
    };

    return appStatus
      ? statuses[appStatus]
      : [
          'blue',
          'badges.appStatus.active',
          'badges.appStatus.placeholder.active',
        ];
  }, [appStatus]);

  if (!appStatus) {
    return null;
  }

  return (
    <Badge color={color} hoverText={getLabel(placeHolderKey)}>
      {getLabel(labelKey)}
    </Badge>
  );
};
