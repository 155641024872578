import { object, string, SchemaOf, number, mixed, bool, array } from 'yup';

import { useLabels } from 'config';
import { APP_CONTAINER_EXPIRATION_TIME } from 'config/constants';
import { EditAppFormValues } from './EditAppForm.dto';
import { FeeCalculationType } from 'shared';

export const useEditAppFormValidationSchema = () => {
  const { getLabel } = useLabels();

  const editAppFormValidationSchema: SchemaOf<EditAppFormValues> = object({
    name: string()
      .min(3)
      .max(250)
      .required()
      .label(getLabel('editApplicationForm.formLabels.name')),
    description: string()
      .nullable()
      .max(1000)
      .label(getLabel('editApplicationForm.formLabels.description')),
    expirationTimeInHours: number()
      .max(APP_CONTAINER_EXPIRATION_TIME.MAX)
      .min(APP_CONTAINER_EXPIRATION_TIME.MIN)
      .integer()
      .positive()
      .required(),
    webhookUrl: string()
      .url()
      .max(500)
      .label(
        getLabel('editApplicationForm.formLabels.webhook.fieldPlacehoolder')
      ),
    webhookCustomHeader: mixed().nullable(),
    image: mixed().required(),
    imageUpdated: bool(),
    allowedDevices: array()
      .min(1)
      .required()
      .label(getLabel('editApplicationForm.formLabels.allowedDevices')),
    require3DAvatar: bool(),
    requiresPresence: bool(),
    feeCalculationType: mixed<FeeCalculationType>()
      .oneOf(Object.values(FeeCalculationType))
      .required(),
  });

  return editAppFormValidationSchema;
};
