import { ErrorMessage, Field, useField } from 'formik';
import { ReactNode } from 'react';

import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  StyledContent,
  Wrapper,
} from './Checkbox.style';
import { StyledErrorMessage } from '../Common.style';
import checkIcon from 'assets/images/check.svg';

export type CheckboxProps = {
  name: string;
  label: ReactNode;
};

export const Checkbox: React.FC<CheckboxProps> = ({ name, label }) => {
  const [field, meta] = useField(name);

  const hasError = !!meta.error && meta.touched;

  return (
    <label>
      <Wrapper error={hasError}>
        <CheckboxContainer>
          <Field name={name} checked={!!field.value} as={HiddenCheckbox} />
          <StyledCheckbox checked={!!field.value}>
            <img src={checkIcon} alt="Check" />
          </StyledCheckbox>
          <StyledContent>{label}</StyledContent>
        </CheckboxContainer>
        <ErrorMessage name={name}>
          {msg => <StyledErrorMessage>{msg}</StyledErrorMessage>}
        </ErrorMessage>
      </Wrapper>
    </label>
  );
};
