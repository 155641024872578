import { ReactNode } from 'react';

import { Modal } from 'components';
import { CustomModalWrapper } from './Modal.style';

import successIcon from 'assets/images/success.svg';

export type SuccessModalProps = {
  isVisible: boolean;
  children: ReactNode;
};

export const SuccessModal: React.FC<SuccessModalProps> = ({
  isVisible,
  children,
}) => (
  <Modal size="sm" isVisible={isVisible}>
    <CustomModalWrapper>
      <img src={successIcon} alt="Success" />
      {children}
    </CustomModalWrapper>
  </Modal>
);
