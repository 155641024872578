import styled from 'styled-components';

export const StyledErrorMessage = styled.div`
  padding: 0 1.25rem;
  color: ${props => props.theme.palette.common.red.default};
  font-size: 0.75rem;
  line-height: 1rem;
`;

export const Wrapper = styled.div<{ error?: boolean }>`
  margin-bottom: ${props => (props.error ? '0.25rem' : '1.25rem')};
`;

export const StyledInput = styled.input<{ error: boolean }>`
  border: none;
  box-sizing: border-box;
  background-color: ${props =>
    props.error
      ? props.theme.palette.common.red.light
      : props.theme.palette.common.gray.veryLight};
  font-family: ${props => props.theme.typography.fontFamily};
  width: 100%;
  padding: 0 1.25rem;
  border-radius: 25px;
  height: 48px;

  &:focus-visible {
    outline-width: 0;
    box-shadow: 0 0 0 2px ${props => props.theme.palette.primary.main};
  }
`;

export const HelpText = styled.div`
  margin-top: 0.5rem;
  display: block;
  color: ${props => props.theme.palette.common.gray.standard};
  font-size: 0.75rem;
`;
