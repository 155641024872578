import i18n from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next, useTranslation } from 'react-i18next';
import { PROJECT_VERSION } from './constants';

export type TranslationKeys =
  | 'general.continue'
  | 'general.remove'
  | 'general.saveChanges'
  | 'general.cancel'
  | 'general.login'
  | 'general.signup'
  | 'general.deactivate'
  | 'general.activate'
  | 'general.edit'
  | 'general.passwordShouldBeTheSame'
  | 'general.waitingForUpload'
  | 'general.deactivated'
  | 'general.disabled'
  | 'general.active'
  | 'general.search'
  | 'general.loadMore'
  | 'general.loading'
  | 'table.noRecordsFound'
  | 'captchaModal.title'
  | 'captchaModal.subtitle'
  | 'booleanField.yes'
  | 'booleanField.no'
  | 'validation.mustBeValidEmail'
  | 'home.title'
  | 'buttons.login'
  | 'buttons.continue'
  | 'buttons.cancel'
  | 'buttons.close'
  | 'buttons.editApp'
  | 'buttons.openWebhookHistory'
  | 'menu.aboutUs'
  | 'menu.firstSteps'
  | 'menu.apiDocs'
  | 'menu.logout'
  | 'menu.editProfile'
  | 'menu.changePassword'
  | 'menu.openSandboxCredentialsModal'
  | 'upload.imageSizeLimit'
  | 'upload.errorSendingFile'
  | 'upload.errorWrongImageType'
  | 'registration.title'
  | 'registration.formLabels.name'
  | 'registration.formLabels.phone'
  | 'registration.formLabels.uplandUsername'
  | 'registration.formLabels.bio'
  | 'registration.formLabels.profileUrl'
  | 'registration.formLabels.email'
  | 'registration.formLabels.password'
  | 'registration.formLabels.passwordConfirmation'
  | 'registration.formLabels.acceptedTerms'
  | 'registration.terms.mainText'
  | 'registration.successModal.title'
  | 'registration.successModal.content'
  | 'resendRegistrationConfirmation.description'
  | 'resendRegistrationConfirmation.success.title'
  | 'resendRegistrationConfirmation.success.content'
  | 'login.title'
  | 'login.formLabels.email'
  | 'login.formLabels.password'
  | 'login.formLabels.rememberMe'
  | 'login.btnLogin'
  | 'login.btnRegister'
  | 'verificationCodeForm.title'
  | 'verificationCodeForm.description'
  | 'verificationCodeForm.formLabels.code'
  | 'verificationCodeForm.btnVerifyCode'
  | 'verificationCodeForm.btnResend'
  | 'verificationCodeForm.btnSent'
  | 'verificationCodeForm.contactUs'
  | 'notFound.title'
  | 'validateAccount.loadingText'
  | 'validateAccount.emailVerifiedTitle'
  | 'validateAccount.emailVerifiedContent'
  | 'validateAccount.oopsText'
  | 'filter.title'
  | 'filter.btnApply'
  | 'filter.btnCancel'
  | 'dashboard.title'
  | 'apps.btnCreate'
  | 'apps.filter.allApps'
  | 'apps.filter.activeApps'
  | 'apps.notFoundMessage'
  | 'apps.userIsNotAbleToCreateApps'
  | 'apps.errorToLoadAppsMessage'
  | 'createNewApplication.title'
  | 'createNewApplication.successModal.title'
  | 'createNewApplication.successModal.content'
  | 'createNewApplication.successModal.warningContent'
  | 'createNewApplication.successModal.accessTokenLabel'
  | 'editApplication.title'
  | 'editApplication.confirmEditionTitle'
  | 'editApplication.successModal.title'
  | 'editApplicationForm.basicInformationTitle'
  | 'editApplicationForm.configurationsTitle'
  | 'editApplicationForm.logo'
  | 'editApplicationForm.formLabels.name'
  | 'editApplicationForm.formLabels.description'
  | 'editApplicationForm.formLabels.expirationTimeInHours'
  | 'editApplicationForm.formLabels.expirationTimeInHoursHelp'
  | 'editApplicationForm.formLabels.webhook.title'
  | 'editApplicationForm.formLabels.webhook.description'
  | 'editApplicationForm.formLabels.webhook.tooltip'
  | 'editApplicationForm.formLabels.webhook.fieldPlacehoolder'
  | 'editApplicationForm.formLabels.webhook.btnConfigureHeader'
  | 'editApplicationForm.formLabels.webhook.btnTestUrl'
  | 'editApplicationForm.formLabels.status'
  | 'editApplicationForm.formLabels.allowedDevices'
  | 'editApplicationForm.formLabels.allowedDevicesRequirements'
  | 'editApplicationForm.formLabels.require3DAvatar.label'
  | 'editApplicationForm.formLabels.require3DAvatar.helpText'
  | 'editApplicationForm.formLabels.requiresPresence.label'
  | 'editApplicationForm.formLabels.requiresPresence.helpText'
  | 'editApplicationForm.formLabels.feeCalculationType.label'
  | 'editApplicationForm.formLabels.feeCalculationType.helpText'
  | 'editApplicationForm.formLabels.feeCalculationType.options.addedToRequested.label'
  | 'editApplicationForm.formLabels.feeCalculationType.options.addedToRequested.tooltip'
  | 'editApplicationForm.formLabels.feeCalculationType.options.deductedFromRequested.label'
  | 'editApplicationForm.formLabels.feeCalculationType.options.deductedFromRequested.tooltip'
  | 'webhookValidationModal.title'
  | 'webhookValidationModal.successDescription'
  | 'webhookValidationModal.dangerDescription'
  | 'webhookValidationModal.request'
  | 'webhookValidationModal.response'
  | 'editProfile.title'
  | 'editProfile.profilePicture'
  | 'editProfile.formLabels.name'
  | 'editProfile.formLabels.phone'
  | 'editProfile.formLabels.uplandUsername'
  | 'editProfile.formLabels.bio'
  | 'editProfile.formLabels.profileUrl'
  | 'editProfile.formLabels.email'
  | 'editProfile.formLabels.password'
  | 'editProfile.formLabels.passwordConfirmation'
  | 'editProfile.successModal.title'
  | 'editProfile.successModal.content'
  | 'editProfile.confirmEditionTitle'
  | 'editProfile.successModal.title'
  | 'validateAccountToolbar.description'
  | 'validateAccountToolbar.btnValidateAccount'
  | 'validateUplandAccountFlow.uplandUsernameFormTitle'
  | 'validateUplandAccountFlow.sandboxInfo'
  | 'validateUplandAccountFlow.uplandAccountDescription'
  | 'validateUplandAccountFlow.formLabels.uplandUsername'
  | 'validateUplandAccountFlow.successModal.title'
  | 'validateUplandAccountFlow.successModal.content'
  | 'badges.kycStatus.success'
  | 'badges.kycStatus.pending'
  | 'badges.kycStatus.rejected'
  | 'badges.kycStatus.blocked'
  | 'badges.kycStatus.placeholderText'
  | 'badges.appStatus.active'
  | 'badges.appStatus.inactive'
  | 'badges.appStatus.disabled'
  | 'badges.appStatus.placeholder.active'
  | 'badges.appStatus.placeholder.inactive'
  | 'badges.appStatus.placeholder.disabled'
  | 'changePasswordModal.title'
  | 'changePasswordModal.successMessage'
  | 'changePasswordModal.formLabels.currentPassword'
  | 'changePasswordModal.formLabels.newPassword'
  | 'changePasswordModal.formLabels.passwordConfirmation'
  | 'changeApplicationStatus.titleInactivate'
  | 'changeApplicationStatus.confirmInactivateMessage'
  | 'changeApplicationStatus.confirmEditionTitle'
  | 'changeApplicationStatus.successModal.titleInactive'
  | 'changeApplicationStatus.successModal.titleActive'
  | 'changeApplicationStatus.successModal.warningContent'
  | 'changeApplicationStatus.successModal.content'
  | 'changeApplicationStatus.successModal.accessTokenLabel'
  | 'forgotPassword.title'
  | 'forgotPassword.resetPassword'
  | 'forgotPassword.successModal'
  | 'sandboxCredentialsModal.title'
  | 'sandboxCredentialsModal.link'
  | 'sandboxCredentialsModal.username'
  | 'sandboxCredentialsModal.password'
  | 'components.copyTextButton.btnLabel'
  | 'components.copyTextButton.feedbackText'
  | 'components.sandboxBanner.title'
  | 'webhookCallsHistory.title'
  | 'webhookCallsHistory.formLabels.searchByNotificationTypeAndContainer'
  | 'webhookCallsHistory.formLabels.statusFilter'
  | 'webhookCallsHistory.formLabels.dateRange'
  | 'webhookCallsHistory.webhookStatuses.all'
  | 'webhookCallsHistory.webhookStatuses.success'
  | 'webhookCallsHistory.webhookStatuses.failed'
  | 'webhookCallsHistory.table.notificationType'
  | 'webhookCallsHistory.table.status'
  | 'webhookCallsHistory.table.attemptedAt'
  | 'webhookCallsHistory.table.containerId'
  | 'webhookCallsHistory.table.requestBody'
  | 'webhookCallsHistory.attempts.attempt1'
  | 'webhookCallsHistory.attempts.attempt2'
  | 'webhookCallsHistory.attempts.attempt3'
  | 'webhookCallsHistory.attempts.attempt4'
  | 'webhookCallsHistory.attempts.attempt5'
  | 'editWebhookHeadersForm.title'
  | 'editWebhookHeadersForm.description'
  | 'editWebhookHeadersForm.addParameter'
  | 'editWebhookHeadersForm.keyPlaceholder'
  | 'editWebhookHeadersForm.valuePlaceholder';

export const loadTranslation = () => {
  i18n
    .use(I18NextHttpBackend)
    .use(initReactI18next)
    .init({
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.json?ver=${PROJECT_VERSION}`,
      },
    });
};

export const useLabels = () => {
  const [t] = useTranslation();

  return {
    getLabel: (key: TranslationKeys, options?: Record<string, any>): string =>
      t(key, options),
  };
};
