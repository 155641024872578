import { useNavigate, matchPath, useLocation } from 'react-router-dom';

import { RouteName, routes } from 'config/routes';

export const useNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return {
    navigate: (routeName: RouteName, params?: Record<string, string>) => {
      const [route] = routes.filter(({ name }) => name === routeName);
      let path = route.path;

      if (params) {
        Object.keys(params).forEach((key: any) => {
          path = path.replaceAll(`:${key}`, params[key]);
        });
      }

      if (!matchPath(route.path, location.pathname)) {
        navigate(path);
      }
    },
    goBack: () => navigate(-1),
  };
};
