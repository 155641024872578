import styled from 'styled-components';

export const StyledTextArea = styled.textarea<{ error: boolean }>`
  border: none;
  box-sizing: border-box;
  resize: vertical;
  background-color: ${props =>
    props.error
      ? props.theme.palette.common.red.light
      : props.theme.palette.common.gray.veryLight};
  font-family: ${props => props.theme.typography.fontFamily};
  width: 100%;
  padding: 1.25rem;
  border-radius: 25px;
`;
