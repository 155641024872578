import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 as generateUuid } from 'uuid';
import { ModalContainer, StyledModal } from 'components/Modal/Modal.style';
import { BODY_SELECTOR } from './useScrollControl';
import { unlock, lock } from 'tua-body-scroll-lock';
import { ScreenSize } from 'shared';

type ModalBuilderContextType = {
  modals: ModalParams[];
  openModal: (modal: ModalParams) => void;
  closeCurrentModal: () => void;
};

type ModalParams = {
  component: ReactNode;
  size?: ScreenSize;
  width?: number;
};

type ModalParamsWithId = {
  id: string;
} & ModalParams;

const ModalBuilderContext = React.createContext<ModalBuilderContextType>({
  modals: [],
  openModal: () => {},
  closeCurrentModal: () => {},
});

export const ModalBuilderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const body = useRef(document.querySelector(BODY_SELECTOR));
  const [modals, setModals] = useState<ModalParamsWithId[]>([]);

  const openModal = useCallback(
    (modal: ModalParams) => {
      setModals([...modals, { ...modal, id: generateUuid() }]);
    },
    [setModals, modals]
  );

  const closeCurrentModal = useCallback(() => {
    const [, ...others] = modals;
    setModals([...others]);
  }, [setModals, modals]);

  useEffect(() => {
    if (!modals.length) unlock(body.current);
    if (modals.length === 1) lock(body.current);
  }, [modals]);

  return (
    <ModalBuilderContext.Provider
      value={{ modals, openModal, closeCurrentModal }}
    >
      {children}

      {modals.map(modal => (
        <ModalContainer>
          <StyledModal size={modal.size ?? 'sm'} width={modal.width}>
            {modal.component}
          </StyledModal>
        </ModalContainer>
      ))}
    </ModalBuilderContext.Provider>
  );
};

export const useModalBuilder = () => useContext(ModalBuilderContext);
