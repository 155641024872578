import { useEffect } from 'react';
import ReCaptcha from 'react-google-recaptcha';
import {
  CAPTCHA_PROVIDER,
  TURNSTILE_SITEKEY,
  RECAPTCHA_SITE_KEY,
  CaptchaProvider,
} from 'config/constants';

export type CaptchaProps = {
  name?: string;
  onValidationSuccessful: (token: string) => void;
  onFail: () => void;
};

export const Captcha: React.FC<CaptchaProps> = props => {
  switch (CAPTCHA_PROVIDER) {
    case CaptchaProvider.GOOGLE:
      return <GoogleCaptcha {...props} />;

    case CaptchaProvider.TURNSTILE:
      return <TurnstileCaptcha {...props} />;

    default:
      return null;
  }
};

export const TurnstileCaptcha: React.FC<CaptchaProps> = ({
  onValidationSuccessful,
  onFail,
}) => {
  useEffect(() => {
    const elementId = window.turnstile.render('#captcha-wrapper', {
      sitekey: TURNSTILE_SITEKEY,
      theme: 'light',
      callback: (token: string) => {
        onValidationSuccessful(token);
      },
      'error-callback': onFail,
    });

    return () => {
      if (elementId) {
        window.turnstile.remove(elementId);
      }
    };
  }, [onFail, onValidationSuccessful]);

  return <div id="captcha-wrapper"></div>;
};

export const GoogleCaptcha: React.FC<CaptchaProps> = ({
  onValidationSuccessful,
  onFail,
}) => (
  <ReCaptcha
    sitekey={RECAPTCHA_SITE_KEY}
    onChange={token => {
      if (token) {
        onValidationSuccessful(token);
      } else {
        fail();
      }
    }}
    onExpired={() => onFail()}
  />
);
