import { useMemo } from 'react';

import { TranslationKeys, useLabels } from 'config';
import { KycStatus } from 'hooks';
import { Badge } from './Badge';
import { BadgeColor } from './Badge.style';
import { Maybe } from 'shared';

export type KYCStatusBadgeProps = {
  kycStatus?: Maybe<KycStatus>;
};

export const KYCStatusBadge: React.FC<KYCStatusBadgeProps> = ({
  kycStatus,
}) => {
  const { getLabel } = useLabels();

  const [color, labelKey]: [BadgeColor, TranslationKeys] = useMemo(() => {
    const statuses: Record<
      Partial<KycStatus>,
      [BadgeColor, TranslationKeys]
    > = {
      [KycStatus.UNKNOWN]: ['blue', 'badges.kycStatus.pending'],
      [KycStatus.PENDING]: ['blue', 'badges.kycStatus.pending'],
      [KycStatus.REJECTED]: ['red', 'badges.kycStatus.rejected'],
      [KycStatus.BLOCKED]: ['red', 'badges.kycStatus.blocked'],
      [KycStatus.SUCCESS]: ['green', 'badges.kycStatus.success'],
    };

    return kycStatus
      ? statuses[kycStatus]
      : ['blue', 'badges.kycStatus.pending'];
  }, [kycStatus]);

  if (!kycStatus || kycStatus === KycStatus.UNKNOWN) {
    return null;
  }

  return (
    <Badge
      color={color}
      hoverText={getLabel('badges.kycStatus.placeholderText')}
    >
      {getLabel(labelKey)}
    </Badge>
  );
};
