import styled from 'styled-components';

export const AppListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const AppCard = styled.div`
  padding: 2rem 1.5rem 2rem 2.5rem;
  border-radius: 25px;
  background-color: ${props => props.theme.palette.common.gray.veryLight};
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    flex-direction: row;
    text-align: left;
  }
`;

export const AppPicture = styled.div`
  width: 103px;
  height: 103px;
  border-radius: 15px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
`;

export const AppContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1;
  }
`;

export const AppName = styled.div`
  font-weight: ${props => props.theme.typography.fontWeight.extraBold};
`;

export const AppId = styled.div`
  font-weight: ${props => props.theme.typography.fontWeight.semiBold};
  color: ${props => props.theme.palette.common.gray.standard};
`;

export const ActionsContainer = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    position: absolute;
    top: 25px;
    right: 15px;
  }
`;

export const AppStatusLabel = styled.span`
  font-size: 0.75rem;
  font-weight: ${props => props.theme.typography.fontWeight.semiBold};
  background-color: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.primary.contrastText};
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
`;

export const AppNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;

  img {
    max-width: 80%;
  }

  h2 {
    max-width: 400px;
  }
`;

export const AppListErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;

  h1 {
    font-size: 6rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-direction: column;
  margin-top: 1rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    flex-direction: row;
    margin-top: 0;

    button {
      width: auto;
    }
  }
`;
