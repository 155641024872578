import { Form, Formik, FormikHelpers } from 'formik';

import { SubTitle, Button, InputText } from 'components';
import { useLabels } from 'config';
import { useResetPasswordFormRequestSchema } from './ResetPasswordForm.validation';
import { ResetPasswordFormRequest } from './ResetPasswordForm.dto';
import { FormButtonActions, FormRow } from '../Common.style';
import { FormValidationException } from 'common/exceptions';
import { DevelopersPortal } from 'http-clients';

export type ResetPasswordFormProps = {
  onSubmit: (values: ResetPasswordFormRequest) => void;
  onCancel: () => void;
};

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSubmit,
  onCancel,
}) => {
  const { getLabel } = useLabels();
  const schemaValidation = useResetPasswordFormRequestSchema();

  const handleSubmit = async (
    values: ResetPasswordFormRequest,
    formikHelpers: FormikHelpers<ResetPasswordFormRequest>
  ) => {
    try {
      formikHelpers.setSubmitting(true);
      await onSubmit(values);
    } catch (error) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });

      if (error instanceof FormValidationException) {
        formikHelpers.setErrors((error as FormValidationException).errors);
      } else if (error instanceof DevelopersPortal.ServerError) {
        formikHelpers.setFieldError('newPassword', error.message);
      } else {
        throw error;
      }
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{}}
      isInitialValid={false}
      validationSchema={schemaValidation}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <SubTitle>{getLabel('changePasswordModal.title')}</SubTitle>

          <FormRow>
            <InputText
              name="newPassword"
              type="password"
              placeholder={`${getLabel(
                'changePasswordModal.formLabels.newPassword'
              )} *`}
            />
          </FormRow>
          <FormRow>
            <InputText
              name="passwordConfirmation"
              type="password"
              placeholder={`${getLabel(
                'changePasswordModal.formLabels.passwordConfirmation'
              )} *`}
            />
          </FormRow>

          <FormButtonActions mb={0}>
            <Button
              disabled={!isValid}
              loading={isSubmitting}
              type="submit"
              label={getLabel('general.continue')}
            />
            <Button
              variant="secondary"
              type="button"
              onClick={onCancel}
              label={getLabel('general.cancel')}
              uppercase={false}
            />
          </FormButtonActions>
        </Form>
      )}
    </Formik>
  );
};
