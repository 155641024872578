import {
  Actions,
  AppCard,
  AppContent,
  AppId,
  AppListContainer,
  AppName,
  AppPicture,
  AppStatusLabel,
} from './AppList.style';
import { Button, ImageLazy } from 'components';
import { AppStatus, Maybe } from 'shared';
import { useLabels } from 'config';
import { AppListSkeleton } from './AppListSkeleton';
import { AppNotFound } from './AppNotFound';
import { AppListError } from './AppListError';

import appPicturePlaceholder from 'assets/images/app-picture-placeholder.png';

export type App = {
  id: number;
  name: string;
  status: AppStatus;
  pictureUrl: Maybe<string>;
};

export type AppListProps = {
  apps: Maybe<App[]>;
  isLoading: boolean;
  isError: boolean;
  onEditClick(app: App): void;
  onWebhookCallsHistoryClick(app: App): void;
  onChangeAppStatusClick(app: App): void;
};

export const AppList: React.FC<AppListProps> = ({
  apps,
  isLoading,
  isError,
  onEditClick,
  onWebhookCallsHistoryClick,
  onChangeAppStatusClick,
}) => {
  const { getLabel } = useLabels();

  if (!!isLoading) {
    return <AppListSkeleton appsAmount={3} />;
  }

  if (!!isError) {
    return <AppListError />;
  }

  if (apps?.length === 0) {
    return <AppNotFound />;
  }

  return (
    <AppListContainer>
      {apps?.map(app => (
        <AppCard key={app.id}>
          <AppPicture>
            <ImageLazy
              src={app.pictureUrl}
              defaultImageUrl={appPicturePlaceholder}
              alt={app.name}
              width={103}
              height={103}
            />
          </AppPicture>
          <AppContent>
            <div>
              <AppName>
                {app.name}{' '}
                {app.status !== AppStatus.ACTIVE && (
                  <AppStatusLabel>
                    {app.status === AppStatus.INACTIVE
                      ? getLabel('general.deactivated')
                      : getLabel('general.disabled')}
                  </AppStatusLabel>
                )}
              </AppName>
            </div>
            <AppId>ID: {app.id}</AppId>

            <Actions>
              <Button
                label={getLabel('buttons.editApp')}
                size="sm"
                onClick={() => onEditClick(app)}
              />
              <Button
                label={getLabel('buttons.openWebhookHistory')}
                size="sm"
                onClick={() => onWebhookCallsHistoryClick(app)}
              />
              <Button
                label={getLabel(
                  app.status === AppStatus.ACTIVE
                    ? 'general.deactivate'
                    : 'general.activate'
                )}
                size="sm"
                onClick={() => onChangeAppStatusClick(app)}
              />
            </Actions>
          </AppContent>
        </AppCard>
      ))}
    </AppListContainer>
  );
};
