import { useCallback } from 'react';

import { VERIFICATION_CODE_SIZE } from 'config/constants';
import { InputText } from '../InputText';
import { StyledInputVerificationCode } from './InputVerificationCode.style';

export type InputVerificationCodeProps = {
  name: string;
  placeholder?: string;
};

const ALPHANUMERIC_PATTERN = /[^\w]+/;

export const InputVerificationCode: React.FC<
  InputVerificationCodeProps
> = props => {
  const normalizeCode = useCallback(
    (value: string) =>
      value.toUpperCase().replace(ALPHANUMERIC_PATTERN, '').replace('_', ''),
    []
  );

  return (
    <InputText
      type="text"
      inputComponent={StyledInputVerificationCode}
      maxLength={VERIFICATION_CODE_SIZE}
      normalize={normalizeCode}
      {...props}
    />
  );
};
