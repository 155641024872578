import { Form, Formik, FormikHelpers } from 'formik';
import { Trans } from 'react-i18next';
import { useCallback } from 'react';
import {
  Button,
  InputText,
  TextArea,
  Checkbox,
  PhoneNumber,
  CropImageModal,
} from 'components';
import { useLabels } from 'config';
import { TermsLink } from './RegistrationForm.style';
import {
  LINK_PRIVACY_TERMS,
  LINK_TERMS_AND_CONDITIONS,
} from 'config/constants';
import { FormButtonActions, FormRow } from 'components/Forms/Common.style';
import { useRegistrationRequestValidationSchema } from './RegistrationRequest.validation';
import { RegistrationRequest } from './RegistrationRequest.dto';
import { FormValidationException } from 'common/exceptions';

export type RegistrationFormProps = {
  onSubmit(values: RegistrationRequest): Promise<unknown>;
  onCancel(): void;
};

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
  onSubmit,
  onCancel,
}) => {
  const { getLabel } = useLabels();
  const validationSchema = useRegistrationRequestValidationSchema();

  const initialValues: RegistrationRequest = {};

  const handleSubmit = useCallback(
    async (
      request: RegistrationRequest,
      formikHelpers: FormikHelpers<RegistrationRequest>
    ) => {
      try {
        formikHelpers.setSubmitting(true);
        await onSubmit(request);
      } catch (error) {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });

        if (error instanceof FormValidationException) {
          formikHelpers.setErrors((error as FormValidationException).errors);
        } else {
          throw error;
        }
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <FormRow size={50}>
            <InputText
              name="name"
              placeholder={`${getLabel('registration.formLabels.name')} *`}
            />
          </FormRow>
          <FormRow size={50}>
            <PhoneNumber
              name="phone"
              placeholder={`${getLabel('registration.formLabels.phone')} *`}
            />
          </FormRow>
          <FormRow size={50} mb={1}>
            <InputText
              name="uplandUsername"
              placeholder={`${getLabel(
                'registration.formLabels.uplandUsername'
              )}`}
            />
          </FormRow>

          <FormRow mb={2}>
            <CropImageModal
              name="image"
              label={`${getLabel('editProfile.profilePicture')} *`}
            />
          </FormRow>

          <FormRow mb={1}>
            <TextArea
              name="bio"
              placeholder={getLabel('registration.formLabels.bio')}
            />
          </FormRow>

          <FormRow size={50} mb={1}>
            <InputText
              name="profileUrl"
              type="url"
              placeholder={getLabel('registration.formLabels.profileUrl')}
            />
          </FormRow>

          <FormRow size={50} mb={1}>
            <InputText
              name="email"
              type="email"
              placeholder={`${getLabel('registration.formLabels.email')} *`}
            />
          </FormRow>

          <FormRow>
            <InputText
              name="password"
              type="password"
              placeholder={`${getLabel('registration.formLabels.password')} *`}
            />

            <InputText
              name="passwordConfirmation"
              type="password"
              placeholder={`${getLabel(
                'registration.formLabels.passwordConfirmation'
              )} *`}
            />
          </FormRow>

          <Checkbox
            name="acceptedTerms"
            label={
              <div>
                <Trans
                  i18nKey="registration.terms.mainText"
                  components={[
                    <TermsLink
                      href={LINK_TERMS_AND_CONDITIONS}
                      target="_blank"
                      rel="noreferrer"
                    />,
                    <TermsLink
                      href={LINK_PRIVACY_TERMS}
                      target="_blank"
                      rel="noreferrer"
                    />,
                  ]}
                />
              </div>
            }
          />

          <FormButtonActions mt={3}>
            <Button
              disabled={!isValid}
              loading={isSubmitting}
              type="submit"
              label={getLabel('buttons.continue')}
            />

            <Button
              variant="secondary"
              type="button"
              label={getLabel('buttons.cancel')}
              onClick={onCancel}
            />
          </FormButtonActions>
        </Form>
      )}
    </Formik>
  );
};
