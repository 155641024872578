import React, { ReactNode } from 'react';
import {
  CheckboxGroup,
  CheckboxOption,
  Label,
  Wrapper,
} from './FakeSelectScopeField.style';
import checkIcon from 'assets/images/check.svg';

export type FakeSelectScopeFieldProps = {
  label: ReactNode;
  options: string[];
};

export const FakeSelectScopeField: React.FC<FakeSelectScopeFieldProps> = ({
  label,
  options,
}) => (
  <Wrapper>
    <Label>{label}</Label>
    <CheckboxGroup>
      {options.map(option => (
        <CheckboxOption key={option}>
          <img src={checkIcon} alt="Check" /> {option}
        </CheckboxOption>
      ))}
    </CheckboxGroup>
  </Wrapper>
);
