import { ErrorMessage, useField } from 'formik';
import { ReactNode, useCallback } from 'react';

import { TooltipIcon } from 'components/TooltipIcon/TooltipIcon';

import {
  Options,
  Option,
  Wrapper,
  OptionContainer,
} from './RadioGroupField.style';
import { HelpText, StyledErrorMessage } from '../Common.style';

export type RadioGroupFieldProps<T> = {
  name: string;
  label: ReactNode;
  helpText?: ReactNode;
  options: RadioGroupFieldOption<T>[];
};

export type RadioGroupFieldOption<T> = {
  label: string;
  value: T;
  tooltip?: string;
};

export function RadioGroupField<T>({
  name,
  label,
  helpText,
  options,
}: RadioGroupFieldProps<T>) {
  const [field, meta, helper] = useField(name);

  const hasError = !!meta.error && meta.touched;

  const handleClick = useCallback(
    (value: T) => () => helper.setValue(value),
    [helper]
  );

  return (
    <Wrapper error={hasError} className="radio-group">
      {label}
      {!!helpText && <HelpText>{helpText}</HelpText>}
      <Options>
        {options.map(option => (
          <OptionContainer key={`${option.value}`}>
            <Option
              onClick={handleClick(option.value)}
              checked={field.value === option.value}
            >
              {option.label}
            </Option>
            {option.tooltip && <TooltipIcon hint={option.tooltip} />}
          </OptionContainer>
        ))}
      </Options>
      <ErrorMessage name={name}>
        {msg => <StyledErrorMessage>{msg}</StyledErrorMessage>}
      </ErrorMessage>
    </Wrapper>
  );
}
