import { object, SchemaOf } from 'yup';

import { useLabels } from 'config';
import { RequestResetPasswordFormRequest } from './RequestResetPasswordForm.dto';
import { validEmail } from 'shared/yup-extensions';

export const useRequestResetPasswordFormRequestSchema = () => {
  const { getLabel } = useLabels();

  const requestResetPasswordFormRequestSchema: SchemaOf<RequestResetPasswordFormRequest> =
    object({
      email: validEmail({
        fieldName: getLabel('registration.formLabels.email'),
        getLabelByKey: getLabel,
      }).required(),
    });

  return requestResetPasswordFormRequestSchema;
};
