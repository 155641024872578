import React from 'react';
import loadingIcon from 'assets/images/loading.svg';
import { StyledLoadingBox } from './LoadingBox.style';
import { useLabels } from 'config';

export const LoadingBox: React.FC = () => {
  const { getLabel } = useLabels();

  return (
    <StyledLoadingBox className="loading-box">
      <img width="50" src={loadingIcon} alt="Loading" />
      {getLabel('general.loading')}
    </StyledLoadingBox>
  );
};
