import { ReactNode } from 'react';

import { ScreenSize } from 'shared';
import { StyledContainer } from './Container.style';

export type ContainerProps = {
  size: ScreenSize;
  children: ReactNode;
};

export const Container: React.FC<ContainerProps> = ({ size, children }) => (
  <StyledContainer size={size}>{children}</StyledContainer>
);
