import styled from 'styled-components';

export const DropdownButtonContainer = styled.span`
  position: relative;
`;

export const StyledDropdownButton = styled.button<{ disabled: boolean }>`
  border: none;
  background-color: transparent;
  cursor: ${props => (!props.disabled ? 'pointer' : 'not-allowed')};
  padding: 0.75rem 0;
  width: 40px;
  border-radius: 6px;
  opacity: ${props => (!props.disabled ? 1 : 0.5)};

  &:hover {
    background-color: ${props =>
      !props.disabled
        ? props.theme.palette.common.gray.midGray.concat('50')
        : 'transparent'};
  }
`;

export const OptionsWrapper = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.palette.common.white}99;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const OptionsList = styled.div`
  position: absolute;
  border-radius: 25px;
  box-shadow: 0 0 1rem ${props => props.theme.palette.common.gray.midGray}80;
  background-color: ${props => props.theme.palette.common.white};
  display: flex;
  flex-direction: column;
  top: 25px;
  right: 0;
  z-index: 1;
  width: 270px;
  overflow: hidden;

  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    position: relative;
  }
`;

export const Option = styled.button`
  padding: 1rem 2rem;
  border: none;
  background-color: transparent;
  text-align: left;
  font-weight: ${props => props.theme.typography.fontWeight.semiBold};
  cursor: pointer;
  transition: all 200ms;

  &:hover {
    background-color: ${props => props.theme.palette.secondary.main};
    color: ${props => props.theme.palette.secondary.contrastText};
  }

  &:first-child {
    padding-top: 1.5rem;
  }

  &:last-child {
    padding-bottom: 1.5rem;
  }
`;
