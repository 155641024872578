import styled from 'styled-components';

export type TitleProps = {
  mt?: string | number;
  mb?: string | number;
};

export const Title = styled.h1<TitleProps>`
  font-size: 1.875rem;
  font-weight: ${props => props.theme.typography.fontWeight.extraBold};
  color: ${props => props.theme.typography.fontColor.default};
  margin-top: ${props => props.mt ?? '2rem'};
  margin-bottom: ${props => props.mb ?? '2.5rem'};
`;
