import { object, string, SchemaOf, bool, mixed } from 'yup';

import { useLabels } from 'config';
import { password, samePassword, validEmail } from 'shared/yup-extensions';
import { RegistrationRequest } from './RegistrationRequest.dto';

export const useRegistrationRequestValidationSchema = () => {
  const { getLabel } = useLabels();

  const registrationRequestValidationSchema: SchemaOf<RegistrationRequest> =
    object({
      uplandUsername: string()
        .nullable()
        .label(getLabel('registration.formLabels.uplandUsername')),
      name: string()
        .required()
        .min(3)
        .max(250)
        .required()
        .label(getLabel('registration.formLabels.name')),
      phone: string()
        .required()
        .label(getLabel('registration.formLabels.phone')),
      email: validEmail({
        fieldName: getLabel('registration.formLabels.email'),
        getLabelByKey: getLabel,
      }).required(),
      bio: string().nullable().label(getLabel('registration.formLabels.bio')),
      profileUrl: string()
        .url()
        .nullable()
        .label(getLabel('registration.formLabels.profileUrl')),
      password: password().label(getLabel('registration.formLabels.password')),
      passwordConfirmation: samePassword(
        'password',
        getLabel('general.passwordShouldBeTheSame')
      ).label(getLabel('registration.formLabels.passwordConfirmation')),
      acceptedTerms: bool()
        .isTrue()
        .required()
        .label(getLabel('registration.formLabels.acceptedTerms')),
      image: mixed().required(),
    });

  return registrationRequestValidationSchema;
};
