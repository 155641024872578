import styled from 'styled-components';

export const TooltipIconContainer = styled.span`
  display: 'block';
  position: 'relative';
  cursor: pointer;
`;

export const TooltipWrapper = styled.div`
  max-width: 90vw;
`;
