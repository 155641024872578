import styled from 'styled-components';

export const Wrapper = styled.div<{ error: boolean }>`
  margin-bottom: ${props => (props.error ? '0.25rem' : '1.25rem')};
`;

export const Options = styled.div<{ marginBottom?: string }>`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
`;

export const Option = styled.label<{
  checked: boolean;
  isError?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  ::before {
    content: '';
    width: 14px;
    height: 14px;
    margin-right: 0.75rem;
    background: ${props =>
      props.checked
        ? props.theme.palette.common.black
        : props.theme.palette.common.white};
    border-radius: 20px;
    box-shadow: 0 0 0 1px
      ${props =>
        props.isError
          ? props.theme.palette.common.red.default
          : props.theme.palette.common.black};
    border: 4px solid ${props => props.theme.palette.common.white};
    transition: all 150ms;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
