import { useCallback } from 'react';
import styled from 'styled-components';
import { useModalBuilder } from './useModalBuilder';
import { Alert, AlertType, Button, FlexBox, SubTitle } from 'components';
import { DevelopersPortal } from 'http-clients';
import { TranslationKeys, useLabels } from 'config';

type ModalProps = {
  data: DevelopersPortal.ValidateWebhookResponse;
  alertType: AlertType;
  getLabel: (key: TranslationKeys, options?: Record<string, any>) => string;
  onClose: () => void;
};

const Modal: React.FC<ModalProps> = ({
  data,
  alertType,
  getLabel,
  onClose,
}) => (
  <>
    <SubTitle mb={1}>{getLabel(`webhookValidationModal.title`)}</SubTitle>
    <Alert type={alertType}>
      {getLabel(
        `webhookValidationModal.${alertType}Description` as TranslationKeys
      )}
    </Alert>

    <JsonViewer>
      <SubTitle>{getLabel(`webhookValidationModal.request`)}</SubTitle>
      <div>{JSON.stringify(data.request).replaceAll('\\"', '"')}</div>
    </JsonViewer>

    <JsonViewer>
      <SubTitle>{getLabel(`webhookValidationModal.response`)}</SubTitle>
      <div>{JSON.stringify(data.response).replaceAll('\\"', '"')}</div>
    </JsonViewer>

    <FlexBox flexDirection="row" justifyContent="center">
      <Button
        variant="secondary"
        type="button"
        label={getLabel('buttons.close')}
        onClick={onClose}
        wAuto
      />
    </FlexBox>
  </>
);

export const useWebhookValidationModal = () => {
  const modalBuilder = useModalBuilder();
  const { getLabel } = useLabels();

  return useCallback(
    (data: DevelopersPortal.ValidateWebhookResponse) => {
      modalBuilder.openModal({
        component: (
          <Modal
            data={data}
            alertType={data.isValid ? 'success' : 'danger'}
            getLabel={getLabel}
            onClose={() => modalBuilder.closeCurrentModal()}
          />
        ),
      });
    },
    [getLabel, modalBuilder]
  );
};

const JsonViewer = styled.div`
  overflow-wrap: break-word;
  font-size: 0.8rem;

  h2 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  div {
    background-color: ${props => props.theme.palette.common.gray.veryLight};
    padding: 1.5rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
  }
`;
