import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import {
  AppList,
  Button,
  Container,
  Title,
  FilterButton,
  FilterOption,
  App,
  Modal,
  SubTitle,
  Paragraph,
  VerificationCodeForm,
  SuccessModal,
  VerificationCodeFormRequest,
  FormButtonActions,
  AppSuccessfullyCreatedModal,
} from 'components';
import { useLabels } from 'config';
import { useAuthentication, useNavigation, useVisibilityControl } from 'hooks';
import { APPS_LIMIT_AMOUNT, ENVIRONMENT, Environments } from 'config/constants';
import { AppStatus, CacheKeys, HttpStatus, Maybe } from 'shared';
import {
  PageHeader,
  PageHeaderActions,
  PageHeaderFloatButton,
  Wrapper,
} from './Dashboard.style';
import { DevelopersPortal, developersPortalClient } from 'http-clients';

enum AppStatusFilter {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
}

export const Dashboard: React.FC = () => {
  const { authUser, isAbleToCreateApps } = useAuthentication();
  const { getLabel } = useLabels();
  const { navigate } = useNavigation();
  const [currentApp, setcurrentApp] = useState<Maybe<App>>();
  const [showModalInactivate, setShowModalInactivate] = useState(false);
  const confirmationCodeStatusModal = useVisibilityControl(false);
  const successfullyInativateModal = useVisibilityControl(false);
  const successfullyActivateModal = useVisibilityControl(false);
  const [appStatusFilter, setAppStatusFilter] = useState(
    AppStatusFilter.ACTIVE
  );
  const loadAppsResponse = useQuery(
    [CacheKeys.FIND_APPS_KEY, authUser?.id],
    developersPortalClient.findApps
  );

  const requestAppEdition = useMutation(
    developersPortalClient.requestAppEdition
  );
  const changeStatusApp = useMutation(developersPortalClient.changeStatusApp);

  const apps = useMemo(
    () =>
      loadAppsResponse?.data?.apps
        .filter(
          app =>
            appStatusFilter === AppStatusFilter.ALL ||
            app.status === AppStatus.ACTIVE
        )
        .map(({ id, name, status, pictureUrl }) => ({
          id,
          name,
          status,
          pictureUrl,
        })),
    [loadAppsResponse, appStatusFilter]
  );

  const filterOptions: FilterOption<AppStatusFilter>[] = useMemo(
    () => [
      {
        label: getLabel('apps.filter.allApps'),
        value: AppStatusFilter.ALL,
      },
      {
        label: getLabel('apps.filter.activeApps'),
        value: AppStatusFilter.ACTIVE,
      },
    ],
    [getLabel]
  );

  const isAppsLimitExceeded = useMemo(() => {
    const activeApps =
      loadAppsResponse.data?.apps.filter(
        app => app.status === AppStatus.ACTIVE
      ) ?? [];

    return activeApps.length >= APPS_LIMIT_AMOUNT;
  }, [loadAppsResponse]);

  const isAbleToFilter = useMemo(() => {
    return (loadAppsResponse?.data?.apps?.length ?? 0) === 0;
  }, [loadAppsResponse]);

  const handleCreateAppButton = useCallback(
    () => navigate('create-new-app'),
    [navigate]
  );

  const handleEditAppButton = useCallback(
    (app: App) => {
      navigate('edit-app', { 'id': app.id.toString() });
    },
    [navigate]
  );

  const handleRequestChangeStatusSubmit = useCallback(async () => {
    const result = await requestAppEdition.mutateAsync();
    requestAppEdition.reset();

    if (currentApp?.status === AppStatus.ACTIVE) {
      setShowModalInactivate(false);
    }

    if (!confirmationCodeStatusModal.visible) {
      confirmationCodeStatusModal.toggle();
    }

    return result;
  }, [requestAppEdition, currentApp?.status, confirmationCodeStatusModal]);

  const handleWebhookCallsHistoryClick = useCallback(
    (app: App) => {
      navigate('webhook-calls-history', { 'appId': app.id.toString() });
    },
    [navigate]
  );

  const handleChangeAppStatusButton = useCallback(
    (app: App) => {
      setcurrentApp(app);
      if (app.status === AppStatus.ACTIVE) {
        setShowModalInactivate(true);
      } else {
        handleRequestChangeStatusSubmit();
      }
    },
    [handleRequestChangeStatusSubmit]
  );

  const handleChangeStatusSubmit = useCallback(
    async (props: VerificationCodeFormRequest) => {
      try {
        await changeStatusApp.mutateAsync({
          id: currentApp?.id!,
          verificationCode: props.verificationCode!,
          status:
            currentApp?.status === AppStatus.ACTIVE
              ? AppStatus.INACTIVE
              : AppStatus.ACTIVE,
        });
      } catch (error: unknown) {
        const isInvalidCode =
          error instanceof DevelopersPortal.ServerError &&
          (error as DevelopersPortal.ServerError).statusCode ===
            HttpStatus.FORBIDDEN;

        if (!isInvalidCode) {
          setcurrentApp(null);
        } else {
          throw error;
        }
      }

      confirmationCodeStatusModal.toggle();

      if (currentApp?.status === AppStatus.ACTIVE) {
        successfullyInativateModal.toggle();
      } else {
        successfullyActivateModal.toggle();
      }

      loadAppsResponse.refetch();
    },
    [
      confirmationCodeStatusModal,
      currentApp,
      loadAppsResponse,
      changeStatusApp,
      successfullyInativateModal,
      successfullyActivateModal,
    ]
  );

  return (
    <>
      <Wrapper>
        <Container size="md">
          <PageHeader>
            <Title mt={0} mb={0}>
              {getLabel('dashboard.title')}
            </Title>
            <PageHeaderActions>
              <PageHeaderFloatButton
                isSandbox={ENVIRONMENT === Environments.SANDBOX}
              >
                <Button
                  label={getLabel('apps.btnCreate')}
                  onClick={handleCreateAppButton}
                  disabled={
                    loadAppsResponse.isLoading ||
                    !isAbleToCreateApps ||
                    isAppsLimitExceeded
                  }
                />
              </PageHeaderFloatButton>
              <FilterButton<AppStatusFilter>
                disabled={isAbleToFilter}
                name="appStatus"
                defaultValue={appStatusFilter}
                options={filterOptions}
                onApply={setAppStatusFilter}
              />
            </PageHeaderActions>
          </PageHeader>

          <AppList
            onEditClick={handleEditAppButton}
            onWebhookCallsHistoryClick={handleWebhookCallsHistoryClick}
            onChangeAppStatusClick={handleChangeAppStatusButton}
            apps={apps}
            isLoading={loadAppsResponse.isLoading}
            isError={loadAppsResponse.isError}
          />
        </Container>
      </Wrapper>

      <Modal size="sm" isVisible={showModalInactivate}>
        <SubTitle>
          {getLabel('changeApplicationStatus.titleInactivate')}
        </SubTitle>
        <Paragraph>
          <div>
            {getLabel('changeApplicationStatus.confirmInactivateMessage')}
          </div>
        </Paragraph>
        <FormButtonActions mb={0}>
          <Button
            type="submit"
            label={getLabel('buttons.continue')}
            onClick={handleRequestChangeStatusSubmit}
          />

          <Button
            variant="secondary"
            type="button"
            label={getLabel('buttons.cancel')}
            onClick={() => setShowModalInactivate(false)}
          />
        </FormButtonActions>
      </Modal>

      <Modal size="sm" isVisible={confirmationCodeStatusModal.visible}>
        <SubTitle>
          {getLabel('changeApplicationStatus.confirmEditionTitle')}
        </SubTitle>
        <VerificationCodeForm
          email={authUser?.email!}
          onResend={handleRequestChangeStatusSubmit}
          onSubmit={handleChangeStatusSubmit}
          onCancel={confirmationCodeStatusModal.toggle}
        />
      </Modal>

      <SuccessModal isVisible={successfullyInativateModal.visible}>
        <SubTitle>
          {getLabel('changeApplicationStatus.successModal.titleInactive')}
        </SubTitle>
        <Button
          label={getLabel('general.continue')}
          variant="primary"
          onClick={successfullyInativateModal.toggle}
          wAuto={true}
        />
      </SuccessModal>

      <AppSuccessfullyCreatedModal
        title={getLabel('changeApplicationStatus.successModal.titleActive')}
        isVisible={successfullyActivateModal.visible}
        isUploadLoading={false}
        secretKey={changeStatusApp.data?.secretKey!}
        onContinue={successfullyActivateModal.toggle}
      />
    </>
  );
};
