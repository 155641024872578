import { useCallback, useMemo } from 'react';
import { useModalBuilder } from './useModalBuilder';
import { Button, Captcha, FlexBox, Paragraph, SubTitle } from 'components';
import { useLabels } from 'config';

export const useCaptchaValidation = () => {
  const modalBuilder = useModalBuilder();
  const { getLabel } = useLabels();

  const validate = useCallback(
    () =>
      new Promise<{ captchaCode: string }>((resolve, reject) => {
        modalBuilder.openModal({
          component: (
            <>
              <FlexBox flexDirection="column" gap={1.5} aligItems="center">
                <FlexBox flexDirection="column" aligItems="center" gap={0.5}>
                  <SubTitle noMargin>{getLabel('captchaModal.title')}</SubTitle>

                  <Paragraph noMargin>
                    {getLabel('captchaModal.subtitle')}
                  </Paragraph>

                  <Captcha
                    onValidationSuccessful={(captchaCode: string) => {
                      modalBuilder.closeCurrentModal();
                      resolve({ captchaCode });
                    }}
                    onFail={() => {
                      modalBuilder.closeCurrentModal();
                      reject();
                    }}
                  />
                </FlexBox>

                <div>
                  <Button
                    variant="secondary"
                    label={getLabel('general.cancel')}
                    wAuto
                    onClick={() => {
                      modalBuilder.closeCurrentModal();
                      reject();
                    }}
                  />
                </div>
              </FlexBox>
            </>
          ),
          width: 364,
        });
      }),
    [getLabel, modalBuilder]
  );

  return useMemo(
    () => ({
      validate,
    }),
    [validate]
  );
};
