import styled from 'styled-components';

type TypographyProps = {
  noMargin?: boolean;
  mb?: number;
};

export const SubTitle = styled.h2<TypographyProps>`
  font-size: 1.5rem;
  font-weight: ${props => props.theme.typography.fontWeight.semiBold};
  color: ${props => props.theme.typography.fontColor.default};
  ${props =>
    props.noMargin
      ? 'margin: 0;'
      : `margin-bottom: ${`${props.mb ?? 2.5}rem`};`}
  text-align: left;
`;
