import { AppListErrorContainer } from './AppList.style';
import { SubTitle, Title } from 'components';
import { useLabels } from 'config';

export const AppListError: React.FC = () => {
  const { getLabel } = useLabels();

  return (
    <AppListErrorContainer>
      <Title>Ops...</Title>
      <SubTitle>{getLabel('apps.errorToLoadAppsMessage')}</SubTitle>
    </AppListErrorContainer>
  );
};
