import { FC } from "react";

export type UplandBrandImageProps = {
  width: number;
  height: number;
  fill?: string,
}

export const UplandBrandImage: FC<UplandBrandImageProps> = ({ width, height, fill = '#E1E1E1' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 114 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.716 0h5.205v17.159c0 1.061-.157 2.032-.471 2.89a5.839 5.839 0 0 1-1.369 2.145c-.583.587-1.301 1.038-2.131 1.354-.853.294-1.818.452-2.872.452H6.82c-1.054 0-1.997-.158-2.85-.452a5.824 5.824 0 0 1-2.154-1.354A5.972 5.972 0 0 1 .47 20.049C.157 19.19 0 18.243 0 17.159V0h5.206v17.182c0 .745.157 1.287.47 1.58.315.27.831.406 1.571.406h.427c.74 0 1.256-.135 1.548-.406.337-.294.494-.813.494-1.58V0ZM28.51 11.83c.45 0 .786-.044 1.055-.112.27-.09.471-.203.628-.384.135-.18.247-.429.292-.7.045-.361.09-.7.067-1.061V7.135c0-.362-.022-.7-.067-1.062-.067-.293-.157-.519-.292-.7a1.194 1.194 0 0 0-.628-.383c-.27-.09-.606-.113-1.055-.113h-2.468v6.999l2.468-.045Zm-7.696 11.831V0h8.1c2.222 0 3.927.632 5.094 1.897 1.166 1.241 1.727 2.98 1.727 5.238V9.55c0 2.258-.583 3.997-1.727 5.238-1.19 1.265-2.872 1.897-5.094 1.897H26.02v6.999h-5.206v-.023Zm24.758-4.854h7.719v4.832H40.367V0h5.206v18.807Zm18.61-3.95h2.67L65.53 7.743l-1.346 7.112Zm3.86 4.853H62.97l-.964 3.951h-5.363L63.06 0h4.937l6.394 23.661h-5.34l-1.01-3.95Zm19.568-8.33V0h5.206v23.661h-5.004L83.078 12.26v11.4h-5.205V0h5.003l4.735 11.38ZM98.525 0h7.471c2.356 0 4.174.542 5.43 1.603 1.369 1.151 2.042 2.89 2.042 5.238v9.98c0 2.347-.673 4.086-2.042 5.237-1.256 1.061-3.074 1.603-5.43 1.603h-7.471V0Zm5.205 4.832v13.975h2.266a7.57 7.57 0 0 0 1.01-.068c.292-.045.539-.135.718-.27a1.45 1.45 0 0 0 .404-.61c.09-.271.135-.61.135-1.016V6.818c0-.429-.045-.767-.135-1.016a1.607 1.607 0 0 0-.404-.61c-.179-.135-.426-.225-.718-.27-.336-.045-.673-.09-1.01-.068h-2.266v-.022Z"
      fill={fill}
    />
  </svg>
)
