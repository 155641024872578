import styled from 'styled-components';

export const CleanedInput = styled.input`
  border: none;
  background: none;
  font-family: ${props => props.theme.typography.fontFamily};
  outline: none;
  flex: 1;
`;

export const FakeInput = styled.div<{ error: boolean }>`
  border: none;
  box-sizing: border-box;
  background-color: ${props =>
    props.error
      ? props.theme.palette.common.red.light
      : props.theme.palette.common.gray.veryLight};
  font-family: ${props => props.theme.typography.fontFamily};
  width: 100%;
  padding: 0 1.25rem;
  border-radius: 25px;
  height: 48px;
  display: flex;

  &:focus-within {
    outline-width: 0;
    box-shadow: 0 0 0 2px ${props => props.theme.palette.primary.main};
  }

  > * {
    flex: 1;
    width: 100%;
  }
`;
