import { useCallback, useMemo, useState } from 'react';
import Cropper, { Area, Point, Size } from 'react-easy-crop';
import { useField } from 'formik';

import {
  StyledModal,
  BottomBar,
  RotateImageButton,
} from './CropImageModal.style';
import {
  createImage,
  getCroppedImg,
  isValidImage,
  readFile,
  rotateImg,
} from './CropImageModal.helper';
import { Button, Modal, Alert, CallToActionContainer } from 'components';
import { Maybe } from 'shared';
import { useLabels } from 'config';
import { ImagePreviewButton } from './ImagePreviewButton';
import { ZoomSlider, MIN_ZOOM, MAX_ZOOM } from './CustomSlider';
import { useVisibilityControl } from 'hooks';
import rotateIcon from 'assets/images/rotate-icon.svg';

const GENERATED_THUMBNAIL_SIZE = 300;

export type CropImageModalProps = {
  name: string;
  label: string;
  onUpdate?: () => void;
};

export const CropImageModal: React.FC<CropImageModalProps> = ({
  name,
  label,
  onUpdate,
}) => {
  const [props, meta, helper] = useField(name);
  const { getLabel } = useLabels();
  const modalControl = useVisibilityControl(false);
  const [isCropInProgress, setIsCropInProgress] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<string>();
  const [originalImageSize, setOriginalImageSize] = useState<Maybe<Size>>();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(MIN_ZOOM);
  const [pixelCropped, setPixelCropped] = useState<Maybe<Area>>();

  const hasError = useMemo(() => !!meta.error && !!meta.touched, [meta]);

  const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
    setPixelCropped(croppedAreaPixels);
  }, []);

  const handleOnSelectFile = useCallback(
    async (file: File) => {
      const imageDataUrl = await readFile(file);
      const { width, height } = await createImage(imageDataUrl);
      const validSize = [width, height].every(
        size => size >= GENERATED_THUMBNAIL_SIZE
      );

      if (!isValidImage(file)) {
        return alert(getLabel('upload.errorWrongImageType'));
      }

      if (!validSize) {
        return alert(
          getLabel('upload.imageSizeLimit', { size: GENERATED_THUMBNAIL_SIZE })
        );
      }

      setOriginalImageSize({ width, height });
      setSelectedImageSrc(imageDataUrl);
      setZoom(MIN_ZOOM);
      modalControl.toggle();
    },
    [getLabel, modalControl]
  );

  const isValidCrop = useMemo(
    () =>
      !!pixelCropped &&
      [pixelCropped.height, pixelCropped.width].every(
        size => size >= GENERATED_THUMBNAIL_SIZE
      ),
    [pixelCropped]
  );

  const handleSaveButton = useCallback(async () => {
    if (!!selectedImageSrc && !!pixelCropped && !!originalImageSize) {
      setIsCropInProgress(true);

      setTimeout(async () => {
        try {
          const image = await getCroppedImg(
            selectedImageSrc,
            pixelCropped,
            GENERATED_THUMBNAIL_SIZE,
            originalImageSize
          );

          if (image) {
            helper.setValue(image);
            //workaround to solve problems with validation state delay
            setTimeout(() => helper.setTouched(true), 500);

            modalControl.toggle();

            if (onUpdate) {
              onUpdate();
            }
          }
        } finally {
          setIsCropInProgress(false);
        }
      }, 1000);
    }
  }, [
    helper,
    modalControl,
    onUpdate,
    originalImageSize,
    pixelCropped,
    selectedImageSrc,
    setIsCropInProgress,
  ]);

  const rotateImage = useCallback(async () => {
    if (props.value) {
      helper.setValue(await rotateImg(props.value));
    }
  }, [helper, props.value]);

  const rotateImageButton = useMemo(
    () =>
      selectedImageSrc ? (
        <RotateImageButton type="button" onClick={rotateImage}>
          <img src={rotateIcon} alt="Rotate" />
        </RotateImageButton>
      ) : null,
    [selectedImageSrc, rotateImage]
  );

  return (
    <div>
      <ImagePreviewButton
        label={label}
        errorMessage={hasError ? meta.error : null}
        initialValue={props.value}
        selectedImage={props.value}
        onSelect={handleOnSelectFile}
        rotateButton={rotateImageButton}
        onRemove={() => {
          helper.setValue(null);
          //workaround to solve problems with validation state delay
          setTimeout(() => helper.setTouched(true), 1);
        }}
      />

      <Modal size="sm" isVisible={modalControl.visible}>
        <StyledModal>
          <Cropper
            image={selectedImageSrc}
            crop={crop}
            zoom={zoom}
            aspect={MIN_ZOOM}
            minZoom={MIN_ZOOM}
            maxZoom={MAX_ZOOM}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </StyledModal>

        <BottomBar>
          <ZoomSlider value={zoom} onChange={setZoom} />
          <CallToActionContainer>
            <Button
              disabled={!isValidCrop}
              loading={isCropInProgress}
              label={getLabel('general.saveChanges')}
              onClick={handleSaveButton}
            />

            <Button
              variant="link"
              label={getLabel('general.cancel')}
              onClick={() => modalControl.toggle()}
            />
          </CallToActionContainer>

          {!isValidCrop && (
            <Alert type="danger">
              {getLabel('upload.imageSizeLimit', {
                size: GENERATED_THUMBNAIL_SIZE,
              })}
            </Alert>
          )}
        </BottomBar>
      </Modal>
    </div>
  );
};
