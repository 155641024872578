import { object, SchemaOf } from 'yup';

import { useLabels } from 'config';
import { ValidEmailFormValues } from './ValidEmailForm.dto';
import { validEmail } from 'shared/yup-extensions';

export const useValidEmailFormValidationSchema = () => {
  const { getLabel } = useLabels();

  const validEmailFormValidationSchema: SchemaOf<ValidEmailFormValues> = object(
    {
      email: validEmail({
        fieldName: getLabel('login.formLabels.email'),
        getLabelByKey: getLabel,
      }).required(),
    }
  );

  return validEmailFormValidationSchema;
};
