import { ReactNode, useMemo } from 'react';

import { Maybe } from 'shared';
import {
  CircleIcon,
  RemoveImageButton,
  StyledInput,
  StyledUploadButton,
} from './CropImageModal.style';
import { useLabels } from 'config';
import plusIcon from 'assets/images/plus.svg';
import { StyledErrorMessage } from 'components/FormFields/Common.style';
import { getAllowedFileTypes } from './CropImageModal.helper';

export type ImagePreviewButtonProps = {
  selectedImage: Maybe<Blob>;
  initialValue: Maybe<string>;
  label: string;
  errorMessage: Maybe<string>;
  rotateButton: ReactNode;
  onSelect(file: File): void;
  onRemove(): void;
};

export const ImagePreviewButton: React.FC<ImagePreviewButtonProps> = ({
  selectedImage,
  initialValue,
  label,
  errorMessage,
  rotateButton,
  onSelect,
  onRemove,
}) => {
  const { getLabel } = useLabels();

  const handleFileSelection = (input: HTMLInputElement) => {
    const files = input.files;

    if (files && files.length > 0) {
      onSelect(files[0]);
    }

    input.value = '';
  };

  const allowedFileTypes = useMemo(() => getAllowedFileTypes().join(','), []);

  if (initialValue && !selectedImage) {
    return (
      <StyledUploadButton>
        <StyledInput
          onChange={event => handleFileSelection(event?.target)}
          accept={allowedFileTypes}
        />
        <>
          <img src={initialValue} alt="Profile" />
          <button
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              onRemove();
            }}
          >
            {getLabel('general.remove')}
          </button>
        </>
      </StyledUploadButton>
    );
  }

  return (
    <>
      <StyledUploadButton error={!!errorMessage}>
        <StyledInput
          onChange={event => handleFileSelection(event?.target)}
          accept={allowedFileTypes}
        />
        {!selectedImage ? (
          <>
            <CircleIcon>
              <img src={plusIcon} alt="Add" />
            </CircleIcon>
            {label}
          </>
        ) : (
          <>
            {rotateButton}
            <img src={URL.createObjectURL(selectedImage)} alt="Profile" />
            <RemoveImageButton
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onRemove();
              }}
            >
              {getLabel('general.remove')}
            </RemoveImageButton>
          </>
        )}
      </StyledUploadButton>
      <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
    </>
  );
};
