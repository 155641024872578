export enum ContentType {
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
}

export enum AppStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DISABLED = 'DISABLED',
}

export enum RegistrationStatus {
  AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  DISABLED = 'DISABLED',
  UPLAND_ACCOUNT_CONFIRMED = 'UPLAND_ACCOUNT_CONFIRMED',
  AWAITING_UPLAND_ACCOUNT_CONFIRMATION = 'AWAITING_UPLAND_ACCOUNT_CONFIRMATION',
}

export enum CacheKeys {
  FIND_APPS_KEY = 'find-apps-key',
  FIND_APP_BY_ID = 'find-app-by-id-key',
  GET_AUTH_USER_KEY = 'get-auth-user-key',
  FIND_DEVELOPER_PROFILE = 'find-developer-profile-key',
  FIND_SANDBOX_CREDENTIALS = 'find-sandbox-credentials',
  FIND_WEBHOOK_CALLS_KEY = 'find-webhook-calls-key',
}

export enum HttpStatus {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  BAD_REQUEST = 400,
  UNPROCESSABLE_ENTITY = 422,
}

export enum AllowedDevices {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  WINDOWS = 'WINDOWS',
  LINUX = 'LINUX',
  MACOS = 'MACOS',
}

export enum FeeCalculationType {
  ADDED_TO_REQUESTED = 'ADDED_TO_REQUESTED',
  DEDUCTED_FROM_REQUESTED = 'DEDUCTED_FROM_REQUESTED',
}
