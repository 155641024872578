import { Field, useField } from 'formik';

import { Wrapper, StyledErrorMessage } from '../Common.style';
import { StyledTextArea } from './TextArea.style';

export type TextAreaProps = {
  name: string;
  placeholder?: string;
  maxLength?: number;
};

export const TextArea: React.FC<TextAreaProps> = props => {
  const [, meta] = useField(props.name);

  const hasError = !!meta.error && !!meta.touched;

  return (
    <Wrapper error={hasError}>
      <Field {...props} as={StyledTextArea} error={hasError} />
      {!!hasError && <StyledErrorMessage>{meta.error}</StyledErrorMessage>}
    </Wrapper>
  );
};
