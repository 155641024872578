import { useEffect, useState } from 'react';

import {
  FilterButtonContainer,
  FilterBox,
  Option,
  OpenFilterButton,
  FilterTitle,
  OptionsList,
} from './FilterButton.style';
import { FlexBox, Button } from 'components';
import { useLabels } from 'config';

import filterIcon from 'assets/images/filter-icon.svg';
import { useVisibilityControl } from 'hooks';

export type FilterOption<T> = {
  label: string;
  value: T;
};

export type FilterButtonProps<T> = {
  name: string;
  defaultValue: T;
  options: FilterOption<T>[];
  disabled?: boolean;
  onApply(value: T): void;
};

export function FilterButton<T>({
  name,
  defaultValue,
  options,
  disabled = false,
  onApply,
}: FilterButtonProps<T>) {
  const { getLabel } = useLabels();
  const { ref, visible, toggle } = useVisibilityControl<HTMLDivElement>(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    if (visible) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue, visible]);

  return (
    <FilterButtonContainer>
      <OpenFilterButton type="button" onClick={toggle} disabled={disabled}>
        <img src={filterIcon} alt={getLabel('filter.title')} />
      </OpenFilterButton>
      {visible && (
        <FilterBox ref={ref}>
          <FilterTitle>{getLabel('filter.title')}</FilterTitle>
          <OptionsList>
            {options.map(option => (
              <Option
                key={option.label}
                checked={option.value === (selectedValue ?? defaultValue)}
                onClick={() => {}}
              >
                <input
                  name={name}
                  type="radio"
                  value={`${option.value}`}
                  onChange={() => setSelectedValue(option.value)}
                  checked={option.value === (selectedValue ?? defaultValue)}
                />
                {option.label}
              </Option>
            ))}
          </OptionsList>
          <FlexBox flexDirection="row" gap={1}>
            <Button
              variant="primary"
              size="sm"
              label={getLabel('filter.btnApply')}
              onClick={() => {
                onApply(selectedValue);
                toggle();
              }}
            />
            <Button
              variant="secondary"
              size="sm"
              label={getLabel('filter.btnCancel')}
              onClick={toggle}
            />
          </FlexBox>
        </FilterBox>
      )}
    </FilterButtonContainer>
  );
}
