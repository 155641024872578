import styled from 'styled-components';

export const StyledTable = styled.table`
  border-color: ${props => props.theme.palette.common.gray.midGray};
  border-style: solid;
  border-width: 0;
  width: 100%;
  border-top-width: 1px;
  padding: 0;
  margin: 0 0 1.5rem 0;
  font-size: 0.9rem;

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .nowrap {
    white-space: nowrap;
  }

  tr:nth-child(odd) {
    td {
      background-color: ${props => props.theme.palette.common.gray.veryLight};
    }
  }

  th,
  td {
    text-align: left;
    padding: 0.75rem;
  }

  thead {
    th {
      border-bottom: 2px solid
        ${props => props.theme.palette.common.gray.midGray};
      white-space: nowrap;
      position: sticky;
      top: 0;
      background: ${props => props.theme.palette.common.white};
      z-index: 10;
    }
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
  min-height: 200px;

  .loading-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.palette.common.white}90;
    z-index: 100;
    height: 100%;
  }
`;
