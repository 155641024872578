import { useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { Button, InputText, SubTitle } from 'components';
import { useLabels } from 'config';
import { ValidEmailFormValues } from './ValidEmailForm.dto';
import { useValidEmailFormValidationSchema } from './ValidEmailForm.validation';
import { FormBody, FormRow, FormButtonActions } from '../Common.style';

export type ValidEmailFormOnSubmitProps = {
  values: ValidEmailFormValues;
};

export type ValidEmailFormProps = {
  initialValues: ValidEmailFormValues;
  onSubmit(values: ValidEmailFormOnSubmitProps): Promise<unknown>;
  onCancel(): void;
};

export const ValidEmailForm: React.FC<ValidEmailFormProps> = ({
  initialValues,
  onSubmit,
  onCancel,
}) => {
  const { getLabel } = useLabels();
  const validationSchema = useValidEmailFormValidationSchema();

  const handleSubmit = useCallback(
    async (
      values: ValidEmailFormValues,
      formikHelpers: FormikHelpers<ValidEmailFormValues>
    ) => {
      try {
        formikHelpers.setSubmitting(true);

        await onSubmit({ values });
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <FormBody>
            <SubTitle>
              {getLabel('resendRegistrationConfirmation.description')}
            </SubTitle>

            <FormRow>
              <InputText
                name="email"
                placeholder={`${getLabel('login.formLabels.email')} *`}
              />
            </FormRow>

            <FormButtonActions>
              <Button
                disabled={!isValid}
                loading={isSubmitting}
                type="submit"
                label={getLabel('buttons.continue')}
              />

              <Button
                variant="secondary"
                type="button"
                label={getLabel('buttons.cancel')}
                onClick={onCancel}
              />
            </FormButtonActions>
          </FormBody>
        </Form>
      )}
    </Formik>
  );
};
