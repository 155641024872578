import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';

import {
  Button,
  InputText,
  TextArea,
  SubTitle,
  CropImageModal,
  SliderField,
  FakeSelectScopeField,
  CheckboxGroup,
  BooleanField,
  EditWebhookHeadersForm,
  FlexBox,
} from 'components';
import { useLabels } from 'config';
import { EditAppFormValues } from './EditAppForm.dto';
import { useEditAppFormValidationSchema } from './EditAppForm.validation';
import { FormBody, FormRow, FormButtonActions } from '../Common.style';
import { FormValidationException } from 'common/exceptions';
import { APP_CONTAINER_EXPIRATION_TIME } from 'config/constants';
import { AllowedDevices, FeeCalculationType } from 'shared';
import { RadioGroupField } from 'components/FormFields/RadioGroupField';
import { useModalBuilder, useWebhookValidationModal } from 'hooks';
import { HelpText } from 'components/FormFields/Common.style';
import { TooltipIcon } from 'components/TooltipIcon/TooltipIcon';
import { developersPortalClient } from 'http-clients';
import { useMutation } from 'react-query';

export type EditAppFormProps = {
  initialValues: EditAppFormValues;
  isCreation?: boolean;
  errors?: unknown;
  onSubmit(values: EditAppFormValues): Promise<unknown>;
  onCancel(): void;
};

export const EditAppForm: React.FC<EditAppFormProps> = ({
  initialValues,
  isCreation = false,
  errors,
  onSubmit,
  onCancel,
}) => {
  const { getLabel } = useLabels();
  const validationSchema = useEditAppFormValidationSchema();
  const formikRef = useRef<FormikProps<EditAppFormValues>>(null);
  const modalBuilder = useModalBuilder();
  const webhookValidationModal = useWebhookValidationModal();
  const webhookUrlValidation = useMutation(
    developersPortalClient.validateWebhookUrl
  );

  const handleErrors = useCallback(
    (error: unknown, formikHelpers: FormikHelpers<EditAppFormValues>) => {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });

      if (error instanceof FormValidationException) {
        formikHelpers.setErrors((error as FormValidationException).errors);
      } else {
        throw error;
      }
    },
    []
  );

  useEffect(() => {
    if (errors && formikRef?.current) {
      handleErrors(errors, formikRef.current);
    }
  }, [errors, handleErrors]);

  const handleValidateWebhoookUrlClick = useCallback(
    (values: EditAppFormValues) =>
      webhookUrlValidation.mutateAsync({
        url: values.webhookUrl!,
        webhookCustomHeader: {
          parameters: Object.keys(values.webhookCustomHeader ?? {}).map(
            key => ({
              key,
              value: values.webhookCustomHeader
                ? values.webhookCustomHeader[key]
                : '',
            }),
            []
          ),
        },
      }),
    [webhookUrlValidation]
  );

  const handleSubmit = useCallback(
    async (
      values: EditAppFormValues,
      formikHelpers: FormikHelpers<EditAppFormValues>
    ) => {
      try {
        formikHelpers.setSubmitting(true);

        if (values.webhookUrl) {
          const { isValid } = await handleValidateWebhoookUrlClick(values);

          if (!isValid) return;
          webhookUrlValidation.reset();
        }

        await onSubmit(values);
      } catch (error) {
        handleErrors(error, formikHelpers);
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [
      handleErrors,
      handleValidateWebhoookUrlClick,
      onSubmit,
      webhookUrlValidation,
    ]
  );

  const allowedDeviceOptions = useMemo(() => Object.keys(AllowedDevices), []);

  const feeCalculationOptions = useMemo(
    () => [
      {
        label: getLabel(
          'editApplicationForm.formLabels.feeCalculationType.options.addedToRequested.label'
        ),
        value: FeeCalculationType.ADDED_TO_REQUESTED,
        tooltip: getLabel(
          'editApplicationForm.formLabels.feeCalculationType.options.addedToRequested.tooltip'
        ),
      },
      {
        label: getLabel(
          'editApplicationForm.formLabels.feeCalculationType.options.deductedFromRequested.label'
        ),
        value: FeeCalculationType.DEDUCTED_FROM_REQUESTED,
        tooltip: getLabel(
          'editApplicationForm.formLabels.feeCalculationType.options.deductedFromRequested.tooltip'
        ),
      },
    ],
    [getLabel]
  );

  const openCustomizeHeaderModal = useCallback(
    (
      values: EditAppFormValues,
      setFieldValue: (field: string, value: any) => void
    ) => {
      modalBuilder.openModal({
        component: (
          <EditWebhookHeadersForm
            initialValues={values.webhookCustomHeader ?? { '': '' }}
            onSubmit={headers => {
              setFieldValue('webhookCustomHeader', headers);
              modalBuilder.closeCurrentModal();
            }}
            onCancel={() => modalBuilder.closeCurrentModal()}
          />
        ),
      });
    },
    [modalBuilder]
  );

  useEffect(() => {
    if (webhookUrlValidation.data) {
      webhookValidationModal(webhookUrlValidation.data);
      webhookUrlValidation.reset();
    }
  }, [
    getLabel,
    modalBuilder,
    onCancel,
    webhookUrlValidation,
    webhookUrlValidation.data,
    webhookValidationModal,
  ]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      isInitialValid={!isCreation}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting, setFieldValue, values, errors }) => (
        <Form>
          <FormBody>
            <SubTitle>
              {getLabel('editApplicationForm.basicInformationTitle')}
            </SubTitle>

            <FormRow size={60}>
              <InputText
                name="name"
                placeholder={`${getLabel(
                  'editApplicationForm.formLabels.name'
                )} *`}
              />
            </FormRow>

            <FormRow mb={2}>
              <CropImageModal
                name="image"
                label={`${getLabel('editApplicationForm.logo')} *`}
                onUpdate={() => setFieldValue('imageUpdated', true)}
              />
            </FormRow>

            <TextArea
              name="description"
              placeholder={getLabel(
                'editApplicationForm.formLabels.description'
              )}
            />

            <FormRow size={50}>
              <SliderField
                getLabel={expirationTimeInHours =>
                  getLabel(
                    'editApplicationForm.formLabels.expirationTimeInHours',
                    { expirationTimeInHours }
                  )
                }
                helpText={getLabel(
                  'editApplicationForm.formLabels.expirationTimeInHoursHelp',
                  { expirationTimeInHours: APP_CONTAINER_EXPIRATION_TIME.MAX }
                )}
                name="expirationTimeInHours"
                minValue={APP_CONTAINER_EXPIRATION_TIME.MIN}
                maxValue={APP_CONTAINER_EXPIRATION_TIME.MAX}
              />
            </FormRow>

            <SubTitle>
              {getLabel('editApplicationForm.configurationsTitle')}
            </SubTitle>

            <FlexBox
              mb={1}
              md={{
                mb: 0,
                flexDirection: 'row',
                aligItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>
                {getLabel('editApplicationForm.formLabels.webhook.title')}{' '}
                <TooltipIcon
                  hint={getLabel(
                    'editApplicationForm.formLabels.webhook.tooltip'
                  )}
                />
                <HelpText className="mb-1">
                  {getLabel(
                    'editApplicationForm.formLabels.webhook.description'
                  )}
                </HelpText>
              </div>
              <div>
                <Button
                  variant="light"
                  size="sm"
                  type="button"
                  icon="gears"
                  label={getLabel(
                    'editApplicationForm.formLabels.webhook.btnConfigureHeader'
                  )}
                  onClick={() =>
                    openCustomizeHeaderModal(values, setFieldValue)
                  }
                />
              </div>
            </FlexBox>

            <FlexBox flexDirection="row" gap={1} aligItems="center">
              <InputText
                className="flex-1"
                name="webhookUrl"
                placeholder={getLabel(
                  'editApplicationForm.formLabels.webhook.fieldPlacehoolder'
                )}
              />
            </FlexBox>

            <FlexBox
              mb={2}
              flexDirection="row"
              justifyContent="center"
              md={{ justifyContent: 'flex-end' }}
            >
              <Button
                variant="primary"
                type="button"
                size="sm"
                label={getLabel(
                  'editApplicationForm.formLabels.webhook.btnTestUrl'
                )}
                width="200px"
                disabled={!values.webhookUrl || !!errors.webhookUrl}
                loading={webhookUrlValidation.isLoading}
                onClick={() => handleValidateWebhoookUrlClick(values)}
              />
            </FlexBox>

            <FormRow mb={2}>
              <BooleanField
                label={getLabel(
                  'editApplicationForm.formLabels.require3DAvatar.label'
                )}
                name="require3DAvatar"
                helpText={getLabel(
                  'editApplicationForm.formLabels.require3DAvatar.helpText'
                )}
              />
            </FormRow>

            <FormRow mb={2}>
              <BooleanField
                label={getLabel(
                  'editApplicationForm.formLabels.requiresPresence.label'
                )}
                name="requiresPresence"
                helpText={getLabel(
                  'editApplicationForm.formLabels.requiresPresence.helpText'
                )}
              />
            </FormRow>

            <FormRow mb={2}>
              <RadioGroupField
                label={getLabel(
                  'editApplicationForm.formLabels.feeCalculationType.label'
                )}
                name="feeCalculationType"
                helpText={getLabel(
                  'editApplicationForm.formLabels.feeCalculationType.helpText'
                )}
                options={feeCalculationOptions}
              />
            </FormRow>

            <FormRow mb={2}>
              <CheckboxGroup
                label={
                  <>
                    {getLabel('editApplicationForm.formLabels.allowedDevices')}*{' '}
                    <span>
                      (
                      {getLabel(
                        'editApplicationForm.formLabels.allowedDevicesRequirements'
                      )}
                      )
                    </span>
                  </>
                }
                name="allowedDevices"
                options={allowedDeviceOptions}
              />
            </FormRow>

            <FormRow mb={2}>
              <FakeSelectScopeField
                label={
                  <>
                    Scope* <span>(NFT Service - Backend Upland)</span>
                  </>
                }
                options={['read', 'assets transfer']}
              />
            </FormRow>

            <FormButtonActions>
              <Button
                disabled={!isValid}
                loading={isSubmitting}
                type="submit"
                label={getLabel('buttons.continue')}
              />

              <Button
                variant="secondary"
                type="button"
                label={getLabel('buttons.cancel')}
                onClick={onCancel}
              />
            </FormButtonActions>
          </FormBody>
        </Form>
      )}
    </Formik>
  );
};
