import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { getAccessToken, useAuthentication, useNavigation } from 'hooks';
import { useLabels } from 'config/translation';
import {
  Container,
  Title,
  EditProfileFormValues,
  EditProfileForm,
  Modal,
  SubTitle,
  VerificationCodeForm,
  VerificationCodeFormRequest,
  SuccessModal,
  Button,
  KYCStatusBadge,
  PageHeader,
} from 'components';
import {
  DevelopersPortal,
  developersPortalClient,
  storageClient,
} from 'http-clients';
import { CacheKeys, ContentType, HttpStatus, Maybe } from 'shared';

export const EditProfile: React.FC = () => {
  const { getLabel } = useLabels();
  const { goBack } = useNavigation();
  const { reloadAuthUserData } = useAuthentication();

  const loadAuthUsersResponse = useQuery(
    [CacheKeys.FIND_DEVELOPER_PROFILE, getAccessToken()],
    developersPortalClient.findProfile
  );

  const requestDeleveloperEdition = useMutation(
    developersPortalClient.requestDeleveloperEdition
  );

  const editProfile = useMutation(developersPortalClient.editDeveloper);
  const fileUploader = useMutation(storageClient.upload);

  const [tempProfile, setTempProfile] =
    useState<Maybe<EditProfileFormValues>>();
  const [generalErrors, setGeneralErrors] = useState<unknown>();

  const initialValues: EditProfileFormValues = useMemo(
    () => ({
      ...loadAuthUsersResponse.data,
      imageUpdated: false,
    }),
    [loadAuthUsersResponse?.data]
  );

  const handleEditFormSubmit = useCallback(
    async (props: EditProfileFormValues) => {
      const result = await requestDeleveloperEdition.mutateAsync();
      requestDeleveloperEdition.reset();
      setTempProfile(props);

      return result;
    },
    [requestDeleveloperEdition]
  );

  const handleConfirmationSubmit = useCallback(
    async (props: VerificationCodeFormRequest) => {
      try {
        const profileFileContentLength: Maybe<number> =
          tempProfile?.imageUpdated ? tempProfile?.image?.size : null;

        const editAppResponse = await editProfile.mutateAsync({
          name: tempProfile?.name!,
          phone: tempProfile?.phone!,
          uplandUsername: tempProfile?.uplandUsername,
          bio: tempProfile?.bio,
          profileUrl: tempProfile?.profileUrl,
          profileFileContentLength,
          verificationCode: props.verificationCode!,
        });

        reloadAuthUserData();

        if (editAppResponse.uploadUrl) {
          fileUploader.mutateAsync({
            uploadUrl: editAppResponse.uploadUrl,
            file: tempProfile?.image!,
            contentType: ContentType.IMAGE_PNG,
          });
        }
      } catch (error: unknown) {
        const isInvalidCode =
          error instanceof DevelopersPortal.ServerError &&
          (error as DevelopersPortal.ServerError).statusCode ===
            HttpStatus.FORBIDDEN;

        if (!isInvalidCode) {
          setTempProfile(null);
          setGeneralErrors(error);
        } else {
          throw error;
        }
      }
    },
    [editProfile, fileUploader, tempProfile, reloadAuthUserData]
  );

  if (!loadAuthUsersResponse.isSuccess) {
    return null;
  }

  return (
    <>
      <Container size="md">
        <PageHeader>
          <Title mb={1}>{getLabel('editProfile.title')}</Title>
          <KYCStatusBadge
            kycStatus={
              loadAuthUsersResponse?.data?.verificationState?.kycStatus
            }
          />
        </PageHeader>

        <EditProfileForm
          initialValues={initialValues}
          errors={generalErrors}
          onSubmit={handleEditFormSubmit}
          onCancel={goBack}
        />
      </Container>

      <Modal size="sm" isVisible={!!tempProfile}>
        <SubTitle>{getLabel('editProfile.confirmEditionTitle')}</SubTitle>
        <VerificationCodeForm
          email={initialValues?.email!}
          onResend={() => handleEditFormSubmit(tempProfile!)}
          onSubmit={handleConfirmationSubmit}
          onCancel={() => setTempProfile(null)}
        />
      </Modal>

      <SuccessModal isVisible={editProfile.isSuccess}>
        <SubTitle>{getLabel('editProfile.successModal.title')}</SubTitle>
        <Button
          label={getLabel('general.continue')}
          variant="primary"
          onClick={goBack}
          wAuto={true}
        />
      </SuccessModal>
    </>
  );
};
