import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useCallback, useEffect, useRef } from 'react';

import {
  Button,
  InputText,
  TextArea,
  PhoneNumber,
  CropImageModal,
} from 'components';
import { useLabels } from 'config';
import { RegistrationStatus } from 'shared';
import { FormButtonActions, FormRow } from 'components/Forms/Common.style';
import { useEditProfileFormValidationSchema } from './EditProfileForm.validation';
import { EditProfileFormValues } from './EditProfileForm.dto';
import { FormValidationException } from 'common/exceptions';
import { DevelopersPortal } from 'http-clients';

export type EditProfileFormProps = {
  initialValues: EditProfileFormValues;
  errors?: unknown;
  onSubmit(values: EditProfileFormValues): Promise<unknown>;
  onCancel(): void;
};

export const EditProfileForm: React.FC<EditProfileFormProps> = ({
  initialValues,
  errors,
  onSubmit,
  onCancel,
}) => {
  const { getLabel } = useLabels();
  const validationSchema = useEditProfileFormValidationSchema();
  const formikRef = useRef<FormikProps<EditProfileFormValues>>(null);

  const handleErrors = useCallback(
    (error: unknown, formikHelpers: FormikHelpers<EditProfileFormValues>) => {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      const errors: Record<string, string> = {};

      if (error instanceof FormValidationException) {
        Object.assign(errors, (error as FormValidationException).errors);
      } else if (error instanceof DevelopersPortal.ServerError) {
        if (error.isDeveloperNameMustBeUniqueError())
          Object.assign(errors, { name: error.message });

        if (error.isDeveloperPhoneMustBeUniqueError())
          Object.assign(errors, { phone: error.message });
      }

      if (Object.keys(errors).length > 0) {
        formikHelpers.setErrors(errors);
      } else {
        throw error;
      }
    },
    []
  );

  useEffect(() => {
    if (errors && formikRef?.current) {
      handleErrors(errors, formikRef.current);
    }
  }, [errors, handleErrors]);

  const handleSubmit = useCallback(
    async (
      values: EditProfileFormValues,
      formikHelpers: FormikHelpers<EditProfileFormValues>
    ) => {
      try {
        formikHelpers.setSubmitting(true);

        await onSubmit(values);
      } catch (error) {
        handleErrors(error, formikHelpers);
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [handleErrors, onSubmit]
  );

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      isInitialValid={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting, setFieldValue }) => (
        <Form>
          <FormRow size={50}>
            <InputText
              name="name"
              placeholder={`${getLabel('editProfile.formLabels.name')} *`}
            />
          </FormRow>
          <FormRow size={50}>
            <PhoneNumber
              name="phone"
              placeholder={`${getLabel('editProfile.formLabels.phone')} *`}
            />
          </FormRow>
          <FormRow size={50} mb={1}>
            <InputText
              name="uplandUsername"
              placeholder={getLabel('editProfile.formLabels.uplandUsername')}
              disabled={
                initialValues?.status === RegistrationStatus.ACTIVE ||
                initialValues?.status ===
                  RegistrationStatus.UPLAND_ACCOUNT_CONFIRMED
              }
            />
          </FormRow>
          <FormRow mb={2}>
            <CropImageModal
              name="image"
              label={`${getLabel('editProfile.profilePicture')} *`}
              onUpdate={() => setFieldValue('imageUpdated', true)}
            />
          </FormRow>
          <FormRow mb={1}>
            <TextArea
              name="bio"
              placeholder={getLabel('editProfile.formLabels.bio')}
            />
          </FormRow>
          <FormRow size={50} mb={1}>
            <InputText
              name="profileUrl"
              type="url"
              placeholder={getLabel('editProfile.formLabels.profileUrl')}
            />
          </FormRow>
          <FormRow size={50} mb={1}>
            <InputText
              name="email"
              type="email"
              placeholder={`${getLabel('editProfile.formLabels.email')} *`}
              disabled={true}
            />
          </FormRow>
          <FormButtonActions>
            <Button
              disabled={!isValid}
              loading={isSubmitting}
              type="submit"
              label={getLabel('buttons.continue')}
            />

            <Button
              variant="secondary"
              type="button"
              label={getLabel('buttons.cancel')}
              onClick={onCancel}
            />
          </FormButtonActions>
        </Form>
      )}
    </Formik>
  );
};
