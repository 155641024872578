import styled from 'styled-components';

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
  max-width: 600px;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Description = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  line-height: 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    max-width: 100%;
    width: auto;
  }
`;

export const ContactUsContainer = styled.div`
  font-size: 0.7rem;
  line-height: 1rem;
  margin-top: 1rem;
`;

export const ContactUsLink = styled.a`
  color: ${props => props.theme.typography.fontColor.default};
`;
