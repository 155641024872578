import styled from 'styled-components';

export const Title = styled.label`
  display: block;
  margin-bottom: 1rem;

  span {
    font-weight: ${props => props.theme.typography.fontWeight.regular};
  }
`;

export const CheckboxItem = styled.label`
  cursor: pointer;
`;
