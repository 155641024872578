import { useCallback } from 'react';
import { useMutation } from 'react-query';

import {
  Button,
  Modal,
  Paragraph,
  SubTitle,
  SuccessModal,
  ValidEmailForm,
  ValidEmailFormOnSubmitProps,
} from 'components';
import { useLabels } from 'config';
import { developersPortalClient } from 'http-clients';

export type ResendRegistrationConfirmationModalProps = {
  isVisible: boolean;
  onCancel: () => void;
};

export const ResendRegistrationConfirmationModal: React.FC<
  ResendRegistrationConfirmationModalProps
> = ({ isVisible, onCancel }) => {
  const { getLabel } = useLabels();
  const resendConfirmation = useMutation(
    developersPortalClient.resendRegistrationConfirmation
  );

  const handleSubmit = useCallback(
    async ({ values }: ValidEmailFormOnSubmitProps) => {
      resendConfirmation.mutateAsync({
        email: values.email!,
      });
    },
    [resendConfirmation]
  );

  const onOk = useCallback(() => {
    onCancel();
    resendConfirmation.reset();
  }, [onCancel, resendConfirmation]);

  if (resendConfirmation.isSuccess) {
    return (
      <SuccessModal isVisible={isVisible}>
        <SubTitle>
          {getLabel('resendRegistrationConfirmation.success.title')}
        </SubTitle>
        <Paragraph>
          {getLabel('resendRegistrationConfirmation.success.content')}
        </Paragraph>

        <Button
          label={getLabel('general.continue')}
          variant="primary"
          onClick={onOk}
          wAuto={true}
        />
      </SuccessModal>
    );
  }

  return (
    <Modal size="sm" isVisible={isVisible}>
      <ValidEmailForm
        initialValues={{}}
        onSubmit={handleSubmit}
        onCancel={onOk}
      />
    </Modal>
  );
};
