import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  padding: 3rem 1rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    max-width: 600px;
    margin: 0 auto;
  }
`;

export const Oops = styled.div`
  font-size: 4rem;
  font-weight: ${props => props.theme.typography.fontWeight.extraBold};
  margin-bottom: 3rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    font-size: 6rem;
  }
`;

export const OopsParagraph = styled.p`
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
`;
