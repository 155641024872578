import { ReactNode, useMemo } from 'react';
import { useLabels } from 'config';
import { RadioGroupField } from '../RadioGroupField';

export type BooleanFieldProps = {
  name: string;
  label: ReactNode;
  helpText?: ReactNode;
};

export const BooleanField: React.FC<BooleanFieldProps> = ({
  name,
  label,
  helpText,
}) => {
  const { getLabel } = useLabels();

  const options = useMemo(
    () => [
      {
        label: getLabel('booleanField.yes'),
        value: true,
      },
      {
        label: getLabel('booleanField.no'),
        value: false,
      },
    ],
    [getLabel]
  );

  return (
    <RadioGroupField
      name={name}
      label={label}
      helpText={helpText}
      options={options}
    />
  );
};
